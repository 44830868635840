import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { FormError } from './formError';
import { FormikPropsSlice, getFormikInputBindProps } from './formikUtils';
import { Omit } from 'utility-types';
import {
  ShIntlMessageDefinition,
  ShTranslatable,
  useShTranslate,
} from '@shoootin/translations';

type BaseInputTextareaProps = InputHTMLAttributes<HTMLTextAreaElement>;

type AppInputTextareaProps = BaseInputTextareaProps & {
  placeholderMessage?: ShIntlMessageDefinition;
  modifier?: 'large';
  required?: boolean;
  error?: string | null;
};

// Deprecated in favor of ShInputTextarea
const AppInputTextarea = ({
  placeholderMessage,
  modifier,
  required = false,
  error,
  ...otherTextareaProps
}: AppInputTextareaProps) => {
  const translate = useShTranslate();
  const placeholder = placeholderMessage
    ? translate(placeholderMessage)
    : otherTextareaProps.placeholder;
  return (
    <div className="field">
      <div className={classnames(' field-control ', modifier)}>
        <textarea
          className="field-textarea"
          aria-label={placeholder || otherTextareaProps.name}
          {...otherTextareaProps}
          placeholder={placeholder}
        />
        {placeholderMessage && (
          <ShTranslatable id={placeholderMessage.id} context="placeholder" />
        )}
        {required && <span className="field-control__icon">*</span>}
        {error && <FormError error={error} />}
      </div>
    </div>
  );
};

export const AppInputTextareaFormik = <FieldName extends string>({
  name,
  form,
  ...otherTextareaProps
}: {
  name: FieldName;
  form: FormikPropsSlice<FieldName>;
} & Omit<AppInputTextareaProps, 'name' | 'value' | 'error' | 'form'>) => {
  return (
    <AppInputTextarea
      {...otherTextareaProps}
      {...getFormikInputBindProps(form, name, otherTextareaProps)}
    />
  );
};
