import { AppGatsbyImageFluid } from '../primitives/appImage';
import { graphql, useStaticQuery } from 'gatsby';
import { getFluidImage } from '../appGatsbyImageUtils';
import { useMemo } from 'react';
import { AppVideo } from '../appConstants';
import { mapValues } from 'lodash';
import { VideoTestimonialImagesQuery } from '../appGraphQLTypes';
import {
  VideoTestimonialConstantData,
  VideoTestimonialData,
  VideoTestimonialName,
} from '../appConstantsVideos';

type UseVideoPreviewImages = {
  [key in VideoTestimonialName]: AppGatsbyImageFluid;
};

export const useVideoTestimonialPreviewImages = (): UseVideoPreviewImages => {
  const data = useStaticQuery<VideoTestimonialImagesQuery>(graphql`
    fragment VideoTestimonialImage on File {
      id
      name
      childImageSharp {
        fluid(maxWidth: 700, quality: 100, srcSetBreakpoints: [400, 550, 800]) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    query VideoTestimonialImages {
      lantower: file(relativePath: { eq: "video-testimonials-lantower.jpg" }) {
        ...VideoTestimonialImage
      }
      ay: file(relativePath: { eq: "video-testimonials-ay.jpg" }) {
        ...VideoTestimonialImage
      }
      knighvest: file(
        relativePath: { eq: "video-testimonials-knighvest.jpg" }
      ) {
        ...VideoTestimonialImage
      }
    }
  `);

  return useMemo(() => {
    return mapValues(data, (file, key) =>
      getFluidImage(file as any, `${key} video preview image not found`),
    );
  }, [data]);
};

type UseVideoTestimonals = {
  [key in VideoTestimonialName]: AppVideo & VideoTestimonialConstantData;
};

const useVideoTestimonials = (): UseVideoTestimonals => {
  const previewImages = useVideoTestimonialPreviewImages();
  return useMemo(() => {
    return mapValues(previewImages, (preview, key) => {
      return {
        videoImg: preview,
        ...VideoTestimonialData[key as VideoTestimonialName],
      };
    });
  }, [previewImages]);
};

export default useVideoTestimonials;
