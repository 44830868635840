import React, { ComponentType, ReactNode, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import { Transition, animated } from 'react-spring';
import styled from '@emotion/styled';
import useOnClickOutside from 'use-onclickoutside';
import { useLastDefinedValueProvider } from '../hooks/useLast';

// copied from https://github.com/FezVrasta/react-popper/blob/master/demo/styles.js#L100
export const Arrow: ComponentType<any> = styled('div')`
  position: absolute;
  width: 3em;
  height: 3em;
  &[data-placement*='bottom'] {
    top: 0;
    left: 0;
    margin-top: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 0 1.5em 1em 1.5em;
      border-color: transparent transparent #ffffff transparent;
    }
  }
  &[data-placement*='top'] {
    bottom: 0;
    left: 0;
    margin-bottom: -0.9em;
    width: 3em;
    height: 1em;
    &::before {
      border-width: 1em 1.5em 0 1.5em;
      border-color: #ffffff transparent transparent transparent;
    }
  }
  &[data-placement*='right'] {
    left: 0;
    margin-left: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1.5em 1em 1.5em 0;
      border-color: transparent #ffffff transparent transparent;
    }
  }
  &[data-placement*='left'] {
    right: 0;
    margin-right: -0.9em;
    height: 3em;
    width: 1em;
    &::before {
      border-width: 1.5em 0 1.5em 1em;
      border-color: transparent transparent transparent #ffffff;
    }
  }
  &::before {
    content: '';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const DefaultPopperTransition = ({
  native = true,
  visible,
  children,
}: {
  native?: boolean;
  visible: boolean;
  children: ({
    transitionStyle,
  }: {
    transitionStyle: { top: number; opacity: number };
  }) => ReactNode;
}) => (
  <Transition
    native={native}
    items={visible}
    from={{ opacity: 0, top: -20 }}
    enter={{ opacity: 1, top: 0 }}
    leave={{ opacity: 0, top: -20 }}
  >
    {(transitionStyle: any, item) => {
      return item && children({ transitionStyle });
    }}
  </Transition>
);

// Generic code to implement a dropdown (animated bottom popper with same width as parent)
// popperjs for position + react-spring for animation
// inspired by https://github.com/FezVrasta/react-popper/blob/master/demo/index.js
type AppPopperProps = {
  opened: boolean;
  target: (refHandler: (ref: HTMLElement | null) => void) => ReactNode;
  content: () => ReactNode;
  // Optional props
  hideIfOutOMainLayoutBody?: boolean;
  arrow?: boolean;
  computeWidth?: (
    ref: React.MutableRefObject<HTMLElement | null>,
  ) => number | undefined;
};
const AppPopper = ({
  opened,
  target,
  content,
  // Optional props
  computeWidth,
  arrow = false,
  hideIfOutOMainLayoutBody = true,
}: AppPopperProps) => {
  const elementRef = useRef<HTMLElement | null>(null);
  const lastContent = useLastDefinedValueProvider(content); // For unmount animation
  return (
    <Manager>
      <Reference>
        {({ ref: popperRef }) => {
          return target((element: HTMLElement | null) => {
            elementRef.current = element;
            popperRef(element);
          });
        }}
      </Reference>
      <DefaultPopperTransition visible={opened}>
        {({ transitionStyle }) =>
          createPortal(
            <Popper
              placement="bottom"
              modifiers={{
                flip: { enabled: false },
                arrow: { enabled: false },
                ...(hideIfOutOMainLayoutBody && {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'viewport',
                    padding: {
                      // top padding because of the sticky header
                      top: 100,
                      bottom: 0,
                      right: 0,
                      left: 0,
                    },
                    escapeWithReference: true,
                  },
                  hide: { enabled: true },
                }),
              }}
            >
              {({ placement, ref, style, arrowProps, outOfBoundaries }) => {
                return (
                  <animated.div
                    ref={ref}
                    style={
                      {
                        zIndex: 999999,
                        margin: arrow ? '1em' : '0em',
                        pointerEvents: opened ? 'auto' : 'none',
                        width: computeWidth
                          ? computeWidth(elementRef)
                          : undefined,
                        ...style,
                        ...transitionStyle,
                        ...(outOfBoundaries
                          ? {
                              opacity: 0,
                              transition: 'opacity 200ms ease-in-out', // TODO how to animate this with react-spring too?
                            }
                          : undefined),
                      } // We expect portals to always be on top. Unfortunately layout is using z-index so adding portals to body is not enough
                    }
                    data-placement={placement}
                  >
                    {lastContent()}
                    {arrow && (
                      <Arrow
                        ref={arrowProps.ref}
                        style={arrowProps.style}
                        data-placement={placement}
                      />
                    )}
                  </animated.div>
                );
              }}
            </Popper>,
            document.body,
          )
        }
      </DefaultPopperTransition>
    </Manager>
  );
};

export const AppDropdownPopper = (props: AppPopperProps) => {
  return (
    <AppPopper
      arrow={false}
      computeWidth={(elementRef) => {
        // The width if the dropdown should match the width of the dropdown target
        return elementRef.current
          ? elementRef.current.getBoundingClientRect().width
          : undefined;
      }}
      {...props}
    />
  );
};

export const AppTooltipPopper = (props: AppPopperProps) => (
  <AppPopper arrow={true} {...props} />
);

// See https://github.com/Andarist/use-onclickoutside/issues/1#issuecomment-492634497
export const useOnOutsidePopperClick = (callback: () => void) => {
  const buttonRef = useRef<HTMLElement | null>(null);
  const popperRef = useRef<HTMLElement | null>(null);
  useOnClickOutside(buttonRef, (event) => {
    const isPopperClick =
      popperRef.current && popperRef.current.contains(event.target as Node);

    if (!isPopperClick) {
      callback();
    }
  });
  return { buttonRef, popperRef };
};
