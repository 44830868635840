import { useEffect, useRef } from 'react';

// See https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval<T extends () => void>(callback: T, delay: number) {
  const savedCallback = useRef<T>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
