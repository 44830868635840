import { ShApiClient } from '../ShApiClient';
export type ShIntercomLang = 'fr' | 'en' | 'es';
export type ShIntercomLangs = ['en', 'fr', 'es'];

export type ShIntercomArticle = {
  type: string;
  id: string;
  title: string;
  description: string;
  body: string;
  parent_id: string;
  url: string;
  state: string;
  workspace_id: string;
  translated_content: ShIntercomArticleContents;
};

export type ShIntercomArticleContents = { type: string } & Record<
  ShIntercomLang,
  ShIntercomArticleContent
>;

export type ShIntercomArticleContent = {
  id: string;
  title: string;
  type: string;
  description: string;
  body: string;
};

export type ShIntercomSection = {
  type: string;
  id: string;
  workspace_id: string;
  name: string;
  description: string;
  created_at: number;
  updated_at: number;
  url: string;
  icon: string;
  order: number;
  default_locale: string;
  collection_id: string;
  parent_id: string;
  translated_content: ShIntercomSectionContents;
  articles: ShIntercomArticle[];
};

export type ShIntercomSectionContents = {
  type?: string;
} & Record<ShIntercomLang, ShIntercomSectionContent>;

export type ShIntercomSectionContent = {
  type: string;
  name: string;
  description: string;
};

export type ShIntercomCollection = {
  type: string;
  id: string;
  workspace_id: string;
  name: string;
  description: string;
  created_at: number;
  updated_at: number;
  url: string;
  icon: string;
  order: number;
  default_locale: string;
  translated_content: ShIntercomCollectionContents;
  sections: ShIntercomSection[];
  articles: ShIntercomArticle[];
};

export type ShIntercomCollectionContents = {
  type: string;
} & Record<ShIntercomLang, ShIntercomCollectionContent>;

export type ShIntercomCollectionContent = {
  type: string;
  name: string;
  description: string;
};

export type ShIntercomArticleDTO = {
  id: string;
  title: string;
  description: string;
  body: string;
};

export type ShIntercomArticleGetResult = {
  article: ShIntercomArticleDTO;
};
export type ShIntercomArticlesGetResult = {
  articles: ShIntercomArticleDTO[];
};

export const ShIntercomArticlesAPI = {
  getArticle: (id: number | string, lang: string) =>
    ShApiClient.call<ShIntercomArticleGetResult>({
      method: 'GET',
      url: `/front/intercom/article/${id}/${lang}`,
    }),
  getArticles: () =>
    ShApiClient.call<ShIntercomArticlesGetResult>({
      method: 'GET',
      url: `/front/intercom/articles`,
    }),

  getCollections: () =>
    ShApiClient.call<{ collections: ShIntercomCollection[] }>({
      method: 'GET',
      url: `/front/intercom/collections`,
    }),
};
