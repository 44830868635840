import React from 'react';

const ProfileSvg = (props: any) => (
  <svg
    className="video-step"
    height={20}
    width={20}
    viewBox="0 0 1024 1024"
    fill="none"
    {...props}
  >
    <path d="M748.598 524.396c50.137-55.013 80.843-128.496 80.843-209.151 0-0.176 0-0.351 0-0.527v0.028c0.004-0.588 0.006-1.283 0.006-1.978 0-175.317-142.123-317.44-317.44-317.44s-317.44 142.123-317.44 317.44c0 81.446 30.673 155.728 81.097 211.925l-0.261-0.296c-164.652 86.176-275.14 255.704-275.402 451.063v3.809c0.894 24.894 21.291 44.736 46.321 44.736 0.199 0 0.398-0.001 0.597-0.004h930.193c0.008 0 0.018 0 0.028 0 25.327 0 45.964-20.081 46.858-45.19l0.002-0.082v-3.234c-0.263-195.395-110.75-364.923-272.609-449.765l-2.794-1.334zM512 93.238c121.702 1.219 219.891 100.161 219.891 222.036 0 122.633-99.414 222.046-222.046 222.046-122.629 0-222.040-99.408-222.046-222.035v-0.001c0.612-123.062 100.514-222.586 223.661-222.586 0.191 0 0.381 0 0.571 0.001h-0.030zM95.394 931.84c17.471-156.122 119.196-284.823 258.057-340.7l2.794-0.993c44.874 25.447 98.563 40.445 155.756 40.445s110.882-14.998 157.351-41.274l-1.595 0.829c141.581 56.969 243.258 185.617 260.676 339.792l0.174 1.9z" />
    />
  </svg>
);

export default ProfileSvg;
