import { ShBackendPaths } from '@shoootin/config';
import { ShApiClient } from '@shoootin/api';
import { UserType } from './appTypes';

type AppNodeEnv = 'development' | 'production';

type AppEnv = 'development' | 'staging' | 'production';

const AppEnvironmentVariables = {
  NODE_ENV: process.env.NODE_ENV as AppNodeEnv,
  GATSBY_ACTIVE_ENV: process.env.GATSBY_ACTIVE_ENV as AppEnv | undefined,
  BACKEND_URL: process.env.BACKEND_URL as string,
  FRONTEND_URL: process.env.FRONTEND_URL as string,
};
console.debug('AppEnvironmentVariables in Front', AppEnvironmentVariables);

export const AppEnv: AppEnv =
  AppEnvironmentVariables.GATSBY_ACTIVE_ENV || AppEnvironmentVariables.NODE_ENV;

export const AppBackendUrl = process.env.BACKEND_URL as string;

export const AppAPIUrl = `${AppBackendUrl}/api`;

export const isDev = AppEnv === 'development';
export const isStaging = AppEnv === 'staging';
export const isProd = AppEnv === 'production';

export const toBackendUrl = (path: string) => `${AppBackendUrl}${path}`;

export const translateUrl = (id: string) =>
  toBackendUrl(`/admin/translations2/edit/${id}`);

export const clientHomeUrl = () => {
  return toBackendUrl(ShBackendPaths.client.home);
};
export const photographerHomeUrl = () =>
  toBackendUrl(ShBackendPaths.photographer.home);
export const prospectPhotographerHomeUrl = () =>
  toBackendUrl(ShBackendPaths.prospectPhotographer.home);

export const adminShootingUrl = (id: string) =>
  toBackendUrl(`/admin/shootings/view/${id}`);

const userTypeHomeUrlFnMap: { [key in UserType]: () => string } = {
  CLIENT_USER: clientHomeUrl,
  PHOTOGRAPHER: photographerHomeUrl,
  PROSPECT_PHOTOGRAPHER: prospectPhotographerHomeUrl,
};
export const userTypeHomeUrl = (userType: UserType) =>
  userTypeHomeUrlFnMap[userType]();

// is this the appropriate place to setup ShApiClient?
ShApiClient.setBaseURL(AppAPIUrl);
ShApiClient.setDevMode(isDev);
