import React from 'react';

const HamburgerSvg = (props: any) => (
  <svg
    className="hamburger"
    height={30}
    width={30}
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <line x1="0" y1="5.5" x2="30" y2="5.5" />
    <line x1="0" y1="15.5" x2="30" y2="15.5" />
    <line x1="0" y1="25.5" x2="30" y2="25.5" />
  </svg>
);

export default HamburgerSvg;
