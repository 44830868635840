// @ts-ignore
import InViewport from 'in-viewport';

//@ts-ignore
import { enhanceHeader } from 'helpers/helpers';
export const isInViewport = (element: HTMLElement): boolean =>
  InViewport(element);

// See https://stackoverflow.com/a/36499256/82609
export const scrollToCenter = (element: HTMLElement) => {
  const elementRect = element.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 2;
  window.scrollTo({
    top: middle,
    behavior: 'smooth',
  });
};

export const scrollElementToTop = (element: HTMLElement, offset: number) => {
  const elementRect = element.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;
  console.log(elementRect.top, window.pageYOffset);
  const top = absoluteElementTop + offset;
  scrollToTop(top);
};

export const scrollToTop = (top: number = 0) => {
  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};

export const scrollToId = (element: string) => {
  const elHeader = document && document.querySelector('#header');
  if (elHeader) {
    const enhancedHeader = enhanceHeader(elHeader);
    const target = document.querySelector(element);
    if (target && target.getClientRects()[0]) {
      const offset =
        target.getClientRects()[0].top +
        window.pageYOffset -
        enhancedHeader.height +
        enhancedHeader.offset;
      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }
};
