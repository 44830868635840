import Axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { createEvents } from 'micro-typed-events';
import {
  delayPromise,
  freezeDevObject,
  stringifyQueryString,
} from '@shoootin/utils';
import { sample } from 'lodash';
import { ShLocale } from '@shoootin/config';
import { ShApiUtils } from './ShApiUtils';

const createApiClient = () => {
  type Headers = { [key: string]: string | undefined | null };

  let isDev = false; //TODO remove on prod

  const AxiosInstance = Axios.create({
    paramsSerializer: (params) => stringifyQueryString(params),
    withCredentials: true,
  });

  const setBaseURL = (baseURL: string) => {
    AxiosInstance.defaults.baseURL = baseURL;
  };

  const setDevMode = (enabled: boolean) => {
    isDev = enabled;
    setTimeout(isDev ? 500000 : 20000);
  };

  const setTimeout = (timeout: number) => {
    AxiosInstance.defaults.timeout = timeout;
  };

  const setHeaders = (headers: Headers) => {
    AxiosInstance.defaults.headers = headers;
  };
  const updateHeaders = (updater: (headers: Headers) => Headers) => {
    setHeaders(updater(AxiosInstance.defaults.headers));
  };
  const setHeader = (name: string, value: string | undefined | null) => {
    updateHeaders((headers) => ({ ...headers, [name]: value }));
  };

  const setLocale = (locale: ShLocale) => setHeader('X-AppLocale', locale);

  const setAuthToken = (token: string | undefined) =>
    setHeader('Authorization', token);

  const events = {
    onUnauthorizedError: createEvents<Error>(),
  };

  AxiosInstance.interceptors.request.use(
    (config) => {
      __DEV__ &&
        console.debug('%cREQ', 'color: orange; font-weight: bold', config.url, {
          data: config.data,
          config,
        });
      return config;
    },
    (error: AxiosError) => {
      __DEV__ &&
        console.debug(
          '%cKO',
          'color: red; font-weight: bold',
          error.config.url,
          error.config,
        );
      console.error(error);
      return Promise.reject(error);
    },
  );

  AxiosInstance.interceptors.response.use(
    (response) => {
      __DEV__ &&
        console.debug(
          '%cOK',
          'color: lime; font-weight: bold',
          response.config.url,
          response.data,
        );
      return response;
    },
    (error: AxiosError) => {
      __DEV__ &&
        console.debug(
          '%cKO',
          'color: red; font-weight: bold',
          error.config.url,
          error.response && error.response.status,
          error.response && error.response.data
            ? error.response.data
            : error.response,
        );
      if (ShApiUtils.isApiUnauthorizedError(error)) {
        __DEV__ && console.debug('api unauthorized error');
        try {
          events.onUnauthorizedError.emit(error);
        } catch (e) {
          console.error(e);
        }
      } else {
        console.error(error);
      }
      return Promise.reject(error);
    },
  );

  const call = async <Data extends {} | void = void>(
    config: AxiosRequestConfig,
  ): Promise<Data> => {
    const response = await AxiosInstance.request(config);

    // this is used by ApiFront authenticate to set Authorisation token in checkout.
    if (response.headers['set-authorization']) {
      setAuthToken(response.headers['set-authorization']);
    }

    if (isDev) {
      await delayPromise(sample([1000, 2000, 2000])!);
      return freezeDevObject(response.data) as Data;
    }

    return response.data as Data;
  };

  return {
    events,
    call,
    setDevMode,
    setTimeout,
    setBaseURL,
    setLocale,
    setAuthToken,
    setHeaders,
    updateHeaders,
    setHeader,
  };
};

export const ShApiClient = createApiClient();

export const isAppApiError = (e: Error): e is AxiosError => {
  // @ts-ignore
  return !!e.isAxiosError;
};

export const extractApiFormErrors = (e: Error): Record<string, any> => {
  if (isAppApiError(e)) {
    return e.response?.data ?? {};
  } else {
    return {};
  }
};
