import {
  ShService,
  ShShootingClientState,
  ShShootingPhotoEditingState,
  ShShootingPhotographerState,
  ShShootingReviewState,
  ShShootingVideastState,
  ShShootingVideoEditingState,
} from '@shoootin/config';

export const ShColors = {
  white: '#FFFFFF',
  whiteD: '#F5F5F5',
  black: '#000000',
  blackL: '#1C1C1C',
  blackLL: '#333333',
  base: '#5472FF',
  baseL: '#A3AEFF',
  photo: '#F054FF',
  photoD: '#c35dff',
  photoDD: '#9f4ecf',
  video: '#FF3380',
  videoD: '#d343a1',
  videoDD: '#af2c81',
  drone: '#FFC75B',
  droneD: '#e0af5c',
  droneDD: '#be8a34',
  matterport: '#00f5b6',
  matterportD: '#18cfcb',
  matterportDD: '#18b0b6',
  pattern: '#E2E2E2',
  disabled: '#DDDDDD',
  placeholder: '#C2C2C2',
  disabledText: '#B1B1B1',
  link: '#C2C2C2',
  linkInvert: '#909090',
  error: '#e33b3b',
  transparent: 'transparent',
  inherit: 'inherit',
  // gold: '#FFD700',
} as const;

export type ShColor = keyof typeof ShColors;

export const ShColorList = Object.keys(ShColors) as ShColor[];

export type ShServiceColorPalette = {
  base: ShColor;
  dark1: ShColor;
  dark2: ShColor;
};

// Each shoootin service has its own color palette
export const ShServiceColorPalettes: {
  [key in ShService]: ShServiceColorPalette;
} = {
  photo: { base: 'photo', dark1: 'photoD', dark2: 'photoDD' },
  video: { base: 'video', dark1: 'videoD', dark2: 'videoDD' },
  drone: { base: 'drone', dark1: 'droneD', dark2: 'droneDD' },
  matterport: {
    base: 'matterport',
    dark1: 'matterportD',
    dark2: 'matterportDD',
  },
  floorplan: {
    base: 'baseL',
    dark1: 'baseL',
    dark2: 'baseL',
  },
  custom: {
    base: 'base',
    dark1: 'base',
    dark2: 'base',
  },
};

type ShStateColorsType = {
  client: { [key in ShShootingClientState]: string };
  photographer: { [key in ShShootingPhotographerState]: string };
  videast: { [key in ShShootingVideastState]: string };
  postprodPhoto: { [key in ShShootingPhotoEditingState]: string };
  postprodVideo: { [key in ShShootingVideoEditingState]: string };
  review: { [key in ShShootingReviewState]: string };
};

export const ShStateColors: ShStateColorsType = {
  client: {
    waitingConfirmation: '#002d40',
    waitingPhotographerConfirmation: '#333030',
    confirmed: '#ff6b6b',
    ready: '#8eb7b6',
    cancelled: '#5bc0de',
    old: 'black',
  },
  photographer: {
    waitingConfirmation: '#1e8190',
    confirmed: '#ff6b6b',
    finished: '#aeccca',
    uploadFinished: '#78a1a4',
    ready: '#8eb7b6',
  },
  videast: {
    waiting: '#ff6b6b',
    loading: '#aeccca',
    loaded: '#8eb7b6',
  },
  postprodPhoto: {
    waitingConfirmation: '#002d40',
    confirmed: '#ff6b6b',
    uploadFinished: '#8eb7b6',
    reedit: '#5bc0de',
  },
  postprodVideo: {
    waitingConfirmation: '#002d40',
    confirmed: '#ff6b6b',
    stuck: '#e33b3b',
    changes: '#cab641',
    loaded: '#8eb7b6',
  },
  review: {
    waitingConfirmation: '#002d40',
    started: '#ff6b6b',
    finished: '#8eb7b6',
    noState: 'transparent',
  },
};

export const ShLogColors = {
  error: '#ff6b6b',
  user: '#8eb7b6',
  photo: '#6fa3a2',
  admin: '#0087c0',
  consultant: '#005173',
  log: '#5bc0de',
  postprod: '#002d40',
  training: '#1e8190',
};
