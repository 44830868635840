import { CurrentUser } from './appTypes';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';

import AppGlobalEvents from './appGlobalEvents';
import { AppEnv, isDev } from 'appEnv';
import { ShCurrency } from '@shoootin/config';
import { SelectedOffers } from './page/order/context/orderPageContextState';
import { OrderSummaryComputed } from './page/order/utils/orderPageUtils';

const SentryDSN = 'https://ddaed4be99144397acfac5676c56ccab@sentry.io/1495356';
const SentryEnabled = !isDev;
export const initAnalytics = () => {
  // didn't use the gatsby plugin because it does very few things:
  // see https://github.com/octalmage/gatsby-plugin-sentry/blob/master/gatsby-browser.js
  Sentry.init({
    dsn: SentryDSN,
    enabled: SentryEnabled,
    environment: AppEnv,
  });
  // console.debug('initAnalytics');
};

// GA is only available in production build
// See https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-ssr.js#L21
// @ts-ignore
const getGoogleAnalytics = () => window.gtag;

AppGlobalEvents.pathnameChange.subscribe((pathname) => {
  // gatsby plugin already handles GA

  Sentry.setExtras({ pathname });
  const sentryBreadcrumb: Sentry.Breadcrumb = {
    category: 'page-view',
    level: Sentry.Severity.Info,
    message: `Page view: ${pathname}`,
    data: {
      pathname,
    },
  };
  Sentry.addBreadcrumb(sentryBreadcrumb);
  console.debug('sentryBreadcrumb', sentryBreadcrumb);
});

export const setAnalyticsUser = (user: CurrentUser) => {
  if (getGoogleAnalytics()) {
    getGoogleAnalytics()('set', { user_id: user.id });
    console.debug('Google analytics logged in', user.id);
  }
  Sentry.configureScope((scope) => {
    const sentryUser = {
      id: user.id,
      userType: user.type,
      username: user.firstName,
    };
    scope.setUser(sentryUser);
    console.debug('Sentry user logged in', sentryUser);
  });
};

export const unsetAnalyticsUser = () => {
  if (getGoogleAnalytics()) {
    // Not sure, documentation is unclear
    getGoogleAnalytics()('set', { userId: null });
  }
  Sentry.configureScope((scope) => {
    scope.setUser(null);
    console.debug('Sentry user logged out');
  });
};

export const reportError = (error: Error | string) => {
  // TODO give possibility to pass additional scope as param
  console.error('reportError', error);
  Sentry.captureException(error);
};

// Avoid reporting twice the same missing translation key
const alreadyReportedMissingTranslations: string[] = [];

export const reportMissingTranslation = (translationKey: string) => {
  if (alreadyReportedMissingTranslations.includes(translationKey)) {
    return;
  }

  // this seems the way to do now...
  // see https://github.com/getsentry/sentry-javascript/issues/1802#issuecomment-448517258
  Sentry.withScope((scope) => {
    scope.addBreadcrumb({
      message: `Missing translation = ${translationKey}`,
      level: Severity.Error,
    });
    scope.setFingerprint(['missing_translation']);
    scope.setTag('missing_translation', 'true');
    scope.setLevel(Sentry.Severity.Warning);
    scope.setExtra('translationKey', translationKey);
    Sentry.captureException('Missing translation');
  });

  console.warn('missing translation reported => ', translationKey);
  alreadyReportedMissingTranslations.push(translationKey);
};

const getItems = (orderSummary: OrderSummaryComputed) => {
  const items = [];
  if (orderSummary.offers.photo) {
    items.push({
      item_id: orderSummary.offers.photo.id,
      item_name: orderSummary.offers.photo.name,
      item_category: 'Photo',
      price: orderSummary.photoTotalPrice / 100.0,
    });
  }
  if (orderSummary.offers.video) {
    items.push({
      item_id: orderSummary.offers.video.id,
      item_name: orderSummary.offers.video.name,
      item_category: 'Video',
      price: orderSummary.videoTotalPrice / 100.0,
    });
  }
  if (orderSummary.offers.scan) {
    items.push({
      item_id: orderSummary.offers.scan.id,
      item_name: orderSummary.offers.scan.name,
      item_category: 'Matterport',
      price: orderSummary.scanTotalPrice / 100.0,
    });
  }
  if (orderSummary.offers.floorPlan) {
    items.push({
      item_id: orderSummary.offers.floorPlan.id,
      item_name: orderSummary.offers.floorPlan.name,
      item_category: 'Floor Plan',
      price: orderSummary.floorPlanTotalPrice / 100.0,
    });
  }
  if (orderSummary.offers.custom) {
    items.push({
      item_id: orderSummary.offers.custom.id,
      item_name: orderSummary.offers.custom.name,
      item_category: 'Custom Offer',
      price: orderSummary.totalPrice / 100.0,
    });
  }

  return items;
};

export const gtagEventPurchase = (
  orderSummary: OrderSummaryComputed,
  currency: ShCurrency,
  coupon?: string,
  // eslint-disable-next-line camelcase
  transaction_id?: string,
) => {
  try {
    if (getGoogleAnalytics()) {
      const value = orderSummary.totalPrice / 100.0;
      const items = getItems(orderSummary);

      getGoogleAnalytics()('event', 'purchase', {
        send_to: 'AW-871891302/bLDsCOLUktYBEOaC4J8D',
        value,
        currency,
        coupon,
        transaction_id,
        items,
        debug_mode: isDev,
      });
      // getGoogleAnalytics()('event', 'conversion', {
      //   send_to: 'AW-871891302/bLDsCOLUktYBEOaC4J8D',
      //   value,
      //   currency,
      //   coupon,
      //   transaction_id,
      // });
    }
  } catch (e) {
    console.error('gtaEventBeginCheckout', e);
  }
};

export const gtaEventBeginCheckout = (
  orderSummary: OrderSummaryComputed,
  currency: ShCurrency,
) => {
  try {
    if (getGoogleAnalytics()) {
      const value = orderSummary.totalPrice / 100.0;
      const items = getItems(orderSummary);

      getGoogleAnalytics()('event', 'begin_checkout', {
        value,
        currency,
        items,
      });
    }
  } catch (e) {
    console.error('gtaEventBeginCheckout', e);
  }
};
