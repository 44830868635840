import React, { InputHTMLAttributes, ReactNode } from 'react';
import '@emotion/core';
import classnames from 'classnames';
import { FormError } from './formError';
import { FormikPropsSlice, getFormikInputBindProps } from './formikUtils';
import { Omit } from 'utility-types';
import {
  ShIntlMessageDefinition,
  ShTranslatable,
  useShTranslate,
} from '@shoootin/translations';

type BaseInputTextProps = InputHTMLAttributes<HTMLInputElement>;

export type AppInputTextProps = {
  className?: string;
  error?: string | null;
  modifier?: string;
  icon?: ReactNode;
  required?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  fieldControlChildren?: ReactNode;
  placeholderMessage?: ShIntlMessageDefinition;
} & BaseInputTextProps;

export const AppInputText = ({
  className,
  error,
  modifier,
  icon,
  required = false,
  inputRef,
  fieldControlChildren,
  placeholderMessage,
  ...inputTextProps
}: AppInputTextProps) => {
  const translate = useShTranslate();
  const placeholder = placeholderMessage
    ? translate(placeholderMessage)
    : inputTextProps.placeholder;
  return (
    <div className={classnames('field', className)}>
      <div
        className={classnames('field-control ', modifier, inputTextProps.type)}
      >
        <input
          ref={inputRef}
          className="field-input"
          type="text"
          aria-label={placeholder || inputTextProps.name}
          {...inputTextProps}
          placeholder={placeholder}
        />
        {placeholderMessage && (
          <ShTranslatable
            id={placeholderMessage.id}
            context="placeholder translation"
          />
        )}
        {icon && <span className="field-control__icon">{icon}</span>}
        {required && <span className="field-control__icon">{'\u25CF'}</span>}
        {error && <FormError error={error} value={inputTextProps.value} />}
        {fieldControlChildren}
      </div>
    </div>
  );
};

export type AppTextInputFormikProps<FieldName extends string> = {
  name: FieldName;
  form: FormikPropsSlice<FieldName>;
} & Omit<AppInputTextProps, 'name' | 'value' | 'error' | 'form'>;

export const AppInputTextFormik = <FieldName extends string>({
  name,
  form,
  ...otherInputProps
}: AppTextInputFormikProps<FieldName>) => {
  return (
    <AppInputText
      {...otherInputProps}
      {...getFormikInputBindProps(form, name, otherInputProps)}
    />
  );
};
