import {
  SharedAppLocalePrefixes,
  // @ts-ignore
} from '../shared/SharedAppLocaleConstants';
import { ShLocale } from '@shoootin/config';

// Clean way to expose shared constants to gatsby app at runtime
export const AppLocalePrefixes: {
  [key in ShLocale]: string;
} = SharedAppLocalePrefixes;
