// for conveniency we allow to pass single element or array
export type Modifier<T extends string> = T | undefined | (T | undefined)[];

export const modifierContains = <T extends string>(
  elementOrArray: Modifier<T> | undefined,
  t: T,
): boolean => {
  return elementOrArray instanceof Array
    ? elementOrArray.includes(t)
    : elementOrArray === t;
};
