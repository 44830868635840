import { ShApiClient } from '../ShApiClient';
import { ShLocale } from '@shoootin/config';
import { ShTranslatableLocale } from '@shoootin/config';

export const ShTranslationAiApi = {
  translate: (
    message: string,
    locale: ShTranslatableLocale,
    situation: string,
  ) =>
    ShApiClient.call<{ translation: string }>({
      url: `/admin/translations/ai/translate`,
      method: 'POST',
      timeout: 500000,
      data: {
        message,
        locale,
        situation,
      },
    }),
};
