import { ShApiClient } from '../ShApiClient';

export type ShSlackChannelDTO = {
  id: string;
  name: string;
};

export const ShAdminSlackApi = {
  getChannels: () =>
    ShApiClient.call<{
      channels: ShSlackChannelDTO;
    }>({
      method: 'GET',
      url: `/admin/slack/getChannels`,
    }),
};
