export const isBrowser = (): boolean => typeof window !== 'undefined';
export const isServer = () => !isBrowser();

// A little helper to create variables that can only exist on the browser
// This is mostly to avoid making gatsby SSR fail
export const createForBrowser = <T>(supplier: () => T): T => {
  if (isBrowser()) {
    return supplier();
  }
  // A bit unsafe but we don't care much, most of the code will execute on the browser...
  return undefined as any;
};
