import { ShCountry, ShLocale } from './ConfigurationGenerated';

export const ShGoogleMapsKey = 'AIzaSyAfa6KkSB-9yTWRCfXKPiQ1iK0sCrhf_6g';

// Available Shoootin service types
// Used in front
export const ShServices = [
  'photo',
  'video',
  'drone',
  'matterport',
  'floorplan',
  'custom',
] as const;
export type ShService = typeof ShServices[number];

//Used in client shootingPage to differentiate the services for each offerCategory
export const ShOfferServices = [
  'photo',
  'video',
  'matterport',
  'floorPlan',
] as const;
export type ShOfferService = typeof ShOfferServices[number];

export const ShBackendPaths = {
  client: {
    home: '/client',
  },
  prospectPhotographer: {
    home: '/training',
  },
  photographer: {
    home: '/photo',
  },
} as const;

//TODO have good url
export const ShBackendAssets = {
  countryFlag: (country: ShCountry) =>
    `https://app.shoootin.com/assets/images/flags/${country}.png`,
};

export const ShGoogleMapsApiKey = 'AIzaSyD1BDJeQbQPPf2M721Zagu3YaQvx-efebs' as const;

export const ShOfferTypes = [
  'photo',
  'video',
  'scan',
  'floorPlan',
  'custom',
] as const;

export const ShJoinSpecialties = [
  'portrait',
  'wedding',
  'architecture',
  'culinary',
  'immo',
  'sport',
] as const;
export type ShJoinSpecialty = typeof ShJoinSpecialties[number];

export const ShDefaultLocale: ShLocale = 'fr_FR';

export const ShTranslationTargets = ['CLIENT', 'FRONT'] as const;
export type ShTranslationTarget = typeof ShTranslationTargets[number];

// For pagination
export const ShPageSizes = [10, 20, 50, 200, 500] as const;
export type ShPageSize = typeof ShPageSizes[number];
export const ShPageSizeDefault: ShPageSize = 20;

// Provide hand-made mapping, because moment locale names / exports are annoying...
// See https://github.com/moment/moment/issues/3624#issuecomment-510413974
export const ShLocaleToMomentLocale: Record<ShLocale, string> = {
  fr_FR: 'fr',
  fr_BE: 'fr',
  de_DE: 'de',
  en_GB: 'en-gb',
  es_ES: 'es',
  en_US: 'en',
  it_IT: 'it',
  pt_PT: 'pt',
  el_GR: 'el',
  nl_NL: 'nl',
  fr_LU: 'fr',
  en_MT: 'en',
};

export type ShCreditCardBrand =
  | 'American Express'
  | 'amex'
  | 'Diners Club'
  | 'Discover'
  | 'JCB'
  | 'MasterCard'
  | 'mastercard'
  | 'UnionPay'
  | 'Visa'
  | 'visa'
  | 'Unknown'; //todo TOimprove

export const BadgeGooglePlay = {
  label: 'Download app on Google Play',
  href: 'https://play.google.com/store/apps/details?id=com.shoootin.mobile',
};
export const BadgeAppStore = {
  label: 'Download app on App Store',
  href: 'https://apps.apple.com/app/shoootin/id1408602189',
};

export const ShClientUserRolesWithoutOwner = [
  'ADMIN',
  'CONSULTANT',
  'ASSISTANT',
] as const;
export type ShClientUserRoleWithoutOwner = typeof ShClientUserRolesWithoutOwner[number];
export const ShClientUserRoleDefault: ShClientUserRoleWithoutOwner =
  'CONSULTANT';
