import {
  ShApiClient,
  ShApiUtils,
  ShMatterportClientDTO,
  ShOfferDTO,
  ShShootingOrderAvailabilities,
  ShShootingSlotAdminDetailsDTO,
} from '@shoootin/api';
import { AuthData, CurrentUser, UserType } from './appTypes';
import {
  ConfirmInterviewPayload,
  ConfirmShootingResult,
  ContactFormValues,
  FindZoneByCoordinatesPayload,
  FindZonePayload,
  GroupedMatterport,
  InterviewConfirmedDTO,
  InterviewSlotsPayload,
  MatterportData,
  OrderAdminClientUserDTO,
  PhotographerApplicationDTO,
  ShootingOrderDTO,
  ShootingRestoreDTO,
  ValidateAddressDTO,
  ValidateAddressResult,
  ValidateOffersDroneDTO,
  ValidateOffersDTO,
  ValidateOrderDTO,
  ValidateOrderResult,
  Zone,
} from './appAPITypes';

import { NewPasswordValues } from './page/login/components/newPasswordForm';
import { Moment } from 'moment';

import { PrestationName } from './appConstants';
import {
  ShCountry,
  ShCurrency,
  ShLanguageLocale,
  ShLocale,
  ShShootingType,
} from '@shoootin/config';
import { OrderPortraitDTO } from './page/order/steps/order/specials/portrait/stepOrderPortraitDTO';

export const authenticate = (
  params: AuthData,
  userType: UserType | undefined,
) =>
  ShApiClient.call<{ user: CurrentUser }>({
    url: `/front/authenticate`,
    params: {
      ...params,
      ...(userType && { userType }),
    },
  });

export const getCurrentUser = () =>
  ShApiClient.call<CurrentUser>({
    url: `/front/currentUser`,
  });

export const getCurrentUserOption = (): Promise<CurrentUser | undefined> =>
  getCurrentUser().catch((e) => {
    if (ShApiUtils.isApiUnauthorizedError(e)) {
      return undefined;
    } else {
      throw e;
    }
  });

export const updateCurrentUserLocale = (locale: ShLocale) =>
  ShApiClient.call<CurrentUser>({
    method: 'POST',
    url: `/front/updateCurrentUserLocale`,
    data: {
      locale,
    },
  });

export const getCurrentUserIntercomData = () =>
  ShApiClient.call<{ intercomSettings: Intercom_.IntercomSettings }>({
    url: `/front/currentUserIntercomData`,
  });

export const logout = () =>
  ShApiClient.call({
    url: `/front/logout`,
  });

export const resetPassword = (email: string) =>
  ShApiClient.call({
    method: 'POST',
    url: `/front/resetPassword`,
    data: { email },
  });

export const changePassword = (params: NewPasswordValues) =>
  ShApiClient.call<{ user: CurrentUser }>({
    method: 'POST',
    url: `/front/changePassword`,
    data: params,
  });

export const getMatterport = (params: MatterportData) =>
  ShApiClient.call<ShMatterportClientDTO>({
    url: `/front/matterport/unique`,
    params,
  });

export const getMatterports = (params: MatterportData) =>
  ShApiClient.call<GroupedMatterport>({
    url: `/front/matterport/grouped`,
    params,
  });

export type SharedData = {
  id: string;
};

export type SharedPhotoResultExpired = {
  expired: true;
};
export type SharedPhotoResult = {
  expired: false;
  photos: string[];
  shared: {
    expiration: Date;
    sharedBy: Date;
    title: string;
  };
};

export const getShared = (params: SharedData) =>
  ShApiClient.call<SharedPhotoResult | SharedPhotoResultExpired>({
    url: `/front/shared/get`,
    params,
  });

export type SharedDownloadResult = {
  downloadLink: string;
};
export const downloadShared = (params: SharedData) =>
  ShApiClient.call<SharedDownloadResult>({
    url: `/front/shared/download`,
    params,
  });

export const submitContactForm = (params: ContactFormValues) =>
  ShApiClient.call({
    method: 'POST',
    url: `/front/contact/submit`,
    data: params,
  });

export const professionOffers = (
  countryCode: ShCountry,
  typeOfShooting: ShShootingType,
) =>
  ShApiClient.call<{ offers: ShOfferDTO[]; currency: ShCurrency }>({
    method: 'GET',
    url: `/front/profession/offers`,
    params: { countryCode, typeOfShooting },
  });

export const professionOffersByService = (
  countryCode: ShCountry,
  service: PrestationName,
) =>
  ShApiClient.call<{
    offers: Record<ShShootingType, number>;
    currency: ShCurrency;
  }>({
    method: 'GET',
    url: `/front/profession/offersByService`,
    params: { countryCode, service },
  });

/** *******************
 Prospect Photographer Application
 ******************** */

export const submitPhotographerApplication = (
  params: PhotographerApplicationDTO,
) =>
  ShApiClient.call({
    method: 'POST',
    url: `/front/photographerRecruitment/submit`,
    data: params,
  });

export const editPhotographerApplication = (id: string) =>
  ShApiClient.call<PhotographerApplicationDTO>({
    url: `/front/photographerRecruitment/edit/${id}`,
  });

export const findZone = (payload: FindZonePayload) =>
  ShApiClient.call<Zone[]>({
    method: 'POST',
    url: `/front/photographerRecruitment/findZone`,
    data: payload,
  });

export const findZoneByCoordinates = (payload: FindZoneByCoordinatesPayload) =>
  ShApiClient.call<Zone>({
    method: 'POST',
    url: `/front/photographerRecruitment/findZoneByCoordinates`,
    data: payload,
  });

export const getPhotographerAndInitInterviewSlots = (photographerId: string) =>
  ShApiClient.call<InterviewSlotsPayload>({
    method: 'POST',
    url: `/front/interview`,
    data: {
      photographerId,
    },
  });

export const getInterviewSlots = (params: {
  photographerId: string;
  date: string;
  preferredLanguage: ShLanguageLocale;
}) =>
  ShApiClient.call<InterviewSlotsPayload>({
    method: 'POST',
    url: `/front/interview/slots`,
    data: params,
  });
export const confirmInterviewSlot = (params: ConfirmInterviewPayload) =>
  ShApiClient.call<InterviewConfirmedDTO>({
    method: 'POST',
    url: `/front/interview/confirm`,
    data: params,
  });
export const cancelInterview = (photographerId: string) =>
  ShApiClient.call<InterviewSlotsPayload>({
    method: 'POST',
    url: `/front/interview/cancel`,
    data: {
      photographerId,
    },
  });

const toBackendOrder = <T extends Partial<ShootingOrderDTO>>(
  payload: T & { start: Moment },
): T & { start: string } => ({
  ...payload,
  start: payload.start.toISOString(),
});

export const OrderAPI = {
  searchAdminClientUsers: (text: string) =>
    ShApiClient.call<{ clientUsers: OrderAdminClientUserDTO[] }>({
      method: 'GET',
      url: `/front/shooting/order/searchAdminClientUsers`,
      params: { text },
    }),

  validateAddress: (order: ValidateAddressDTO) =>
    ShApiClient.call<ValidateAddressResult>({
      method: 'POST',
      url: `/front/shooting/order/validateAddress`,
      data: order,
    }),

  validateOffers: (order: ValidateOffersDTO) =>
    ShApiClient.call<ShShootingOrderAvailabilities>({
      method: 'POST',
      url: `/front/shooting/order/validateOffers`,
      data: toBackendOrder(order),
    }),

  getAdminSlotDetails: (order: ValidateOrderDTO) =>
    ShApiClient.call<ShShootingSlotAdminDetailsDTO>({
      method: 'POST',
      url: `/front/shooting/order/adminSlotDetails`,
      data: toBackendOrder(order),
    }),

  validateOrder: (order: ValidateOrderDTO) =>
    ShApiClient.call<ValidateOrderResult>({
      method: 'POST',
      url: `/front/shooting/order/validateOrder`,
      data: toBackendOrder(order),
    }),

  confirmShootingNewCB: (
    payload: ShootingOrderDTO,
    paymentMethodId?: string,
    paymentIntentId?: string,
    saveCreditCard?: boolean,
  ) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingNewCB`,
      data: {
        ...toBackendOrder(payload),
        paymentMethodId,
        paymentIntentId,
        saveCreditCard,
      },
    }),
  confirmShootingExistingCB: (
    payload: ShootingOrderDTO,
    paymentMethodId?: string,
    paymentIntentId?: string,
  ) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingExistingCB`,
      data: {
        ...toBackendOrder(payload),
        paymentMethodId,
        paymentIntentId,
      },
    }),
  confirmShootingUnlimited: (payload: ShootingOrderDTO) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingUnlimited`,
      data: toBackendOrder(payload),
    }),
  confirmShootingCredit: (payload: ShootingOrderDTO) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingCredit`,
      data: toBackendOrder(payload),
    }),
  confirmShootingFree: (payload: ShootingOrderDTO) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingFree`,
      data: toBackendOrder(payload),
    }),
  confirmShootingOrder: (payload: ShootingOrderDTO) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingOrder`,
      data: toBackendOrder(payload),
    }),

  confirmShootingInvoice: (payload: ShootingOrderDTO) =>
    ShApiClient.call<ConfirmShootingResult>({
      method: 'POST',
      url: `/front/shooting/order/confirmShootingInvoice`,
      data: toBackendOrder(payload),
    }),

  restoreShooting: (shootingId: string) =>
    ShApiClient.call<ShootingRestoreDTO>({
      method: 'GET',
      url: `/front/shooting/order/restore/${shootingId}`,
    }),

  restoreShootingAdmin: (shootingId: string) =>
    ShApiClient.call<ShootingRestoreDTO>({
      method: 'GET',
      url: `/front/shooting/order/restoreAdmin/${shootingId}`,
    }),

  adminModeAllowed: () =>
    ShApiClient.call<{ allowed: boolean }>({
      method: 'GET',
      url: `/front/shooting/order/adminModeAllowed`,
    }),

  validatePortrait: (data: OrderPortraitDTO) =>
    ShApiClient.call<{}>({
      method: 'POST',
      url: `/front/shooting/order/validatePortrait`,
      data,
    }),

  validateDrone: (order: ValidateOffersDroneDTO) =>
    ShApiClient.call<any>({
      // TODO any
      method: 'POST',
      url: `/front/shooting/order/validateDrone`,
      data: toBackendOrder(order),
    }),
};
