import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import { AppContextValue, AppPageContext } from '../appTypes';

import {
  cleanQueryString,
  parseQueryString,
  SimpleQueryStringObject,
  stringifyQueryString,
  removePrefix,
} from '@shoootin/utils';

// See https://github.com/reach/router/issues/74#issuecomment-397085896
// @ts-ignore
import { match } from '@reach/router/lib/utils';

import AppGlobalEvents from '../appGlobalEvents';
import { isNil } from 'lodash';
import { AppLocalePrefixes } from '../appConstantsShared';
import { navigate } from 'gatsby';
import { SelectedAppLocaleStorage } from '../appStorage';
import useGetter from '../hooks/useGetter';
import { ShLocale, ShTimeZone } from '@shoootin/config';

// See https://github.com/gatsbyjs/gatsby/issues/13965
export const extractPageMatchParams = (props: any): any | null => {
  if (props.pageContext.matchPath) {
    const result = match(props.pageContext.matchPath, props.location.pathname);
    if (result && result.params) {
      return result.params;
    }
  }
  // Empty make it easier to use in pages without having to check for null/undefined
  return {};
};

const AppContext = React.createContext<AppContextValue<AppPageContext> | null>(
  null,
);

export const AppContextProvider = (props: any) => {
  const queryString = useMemo(() => {
    const queryString = parseQueryString(
      removePrefix(props.location.search, '?'),
    );
    return queryString;
  }, [props.location.search]);

  const pageParams = useMemo(() => {
    return extractPageMatchParams(props);
  }, [props]);

  const appContext: AppContextValue<AppPageContext> = useMemo(() => {
    const ctx = { ...props, queryString, pageParams };
    console.debug('appContext', ctx);
    return ctx;
  }, [props, queryString]);

  useEffect(() => {
    AppGlobalEvents.pathnameChange.emit(props.location.pathname);
  }, [props.location.pathname]);

  return (
    <AppContext.Provider value={appContext}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = (): AppContextValue<AppPageContext> => {
  const value = useContext(AppContext);
  if (value === null) {
    throw new Error('AppContext is not provided');
  }
  return value;
};

export const useLocation = () => useAppContext().location;

type QueryStringAPI<T extends {}> = {
  merge: (t: Partial<T>) => void;
  replace: (t: T) => void;
};
export const useQueryString = <
  T extends SimpleQueryStringObject = SimpleQueryStringObject
>(): [T, QueryStringAPI<T>] => {
  const untrimmedQueryString = useAppContext().queryString as T;
  const queryString = useMemo(() => cleanQueryString(untrimmedQueryString), [
    untrimmedQueryString,
  ]) as T;
  const pathname = useLocation().pathname;
  const getQueryString = useGetter(queryString);
  const getPathname = useGetter(pathname);
  const api: QueryStringAPI<T> = useMemo(() => {
    const replace = (t: T) => {
      const queryString = stringifyQueryString(cleanQueryString(t));
      const to = getPathname() + (queryString ? `?${queryString}` : '');
      navigate(to);
    };
    const merge = (t: Partial<T>) => replace({ ...getQueryString(), ...t });
    return {
      replace,
      merge,
      getQueryString,
    };
  }, [getQueryString, getPathname]);
  return [queryString, api];
};

export const useIsTranslateMode = () => {
  const [queryString] = useQueryString();
  return !isNil(queryString.translate);
};

// See https://github.com/gatsbyjs/gatsby/issues/13965
export const usePageParams = () => useAppContext().pageParams;

// TODO ? do we want this here?
export const useAppTimezone = (): ShTimeZone =>
  Intl.DateTimeFormat().resolvedOptions().timeZone as ShTimeZone; //useFrontContext().pageContext.timeZone;

export const getLocalePrefix = (locale: ShLocale) => {
  return AppLocalePrefixes[locale];
};

// Permit to transform a pathname like /en_us/slug to /es-es/slug
// Will do nothing
export const getPathnameForLocaleUpdate = (
  pathname: string,
  currentLocale: ShLocale,
  newLocale: ShLocale,
): string => {
  if (currentLocale === newLocale) {
    return pathname;
  }
  // Don't forget to update frontLocaleRedirectionScript accordingly
  const currentPrefix = getLocalePrefix(currentLocale);
  const unprefixedPathname = removePrefix(pathname, currentPrefix);
  const newPrefix = getLocalePrefix(newLocale);
  const newPathname = `${newPrefix}${unprefixedPathname}`;
  return newPathname;
};

// Returns a callback that takes a locale and
// return the path for the current page in the provided locale
// Note it will only change the pathname for front pages
// because other pages do not have localized urls
export const useAppPathnameLocalizer = () => {
  const {
    pageContext,
    location: { pathname },
  } = useAppContext();
  return useCallback(
    (locale: ShLocale) => {
      const currentLocale = pageContext.locale;
      return getPathnameForLocaleUpdate(pathname, currentLocale, locale);
    },
    [pathname, pageContext],
  );
};

// return a function that may redirect the app to a different url after a local change
export const usePathnameRedirectForLocale = () => {
  const {
    pageContext,
    location: { pathname },
  } = useAppContext();
  const currentPagePathnameLocalizer = useAppPathnameLocalizer();
  return useCallback(
    (newLocale: ShLocale) => {
      const currentLocale = pageContext.locale;
      if (newLocale !== currentLocale) {
        const newPathname = currentPagePathnameLocalizer(newLocale);
        console.debug('usePathnameRedirectForLocale', newLocale, newPathname);
        navigate(newPathname);
        SelectedAppLocaleStorage.set(newLocale); // TODO seems to be inappropriate place to do this
      }
    },
    [pathname, pageContext],
  );
};
