import { useRef } from 'react';

// Mostly useful for unmount animations
export const useLastDefinedValue = <T>(value: T | undefined): T | undefined => {
  const lastDefinedValue = useRef<T>();
  if (value) {
    lastDefinedValue.current = value;
  }
  return lastDefinedValue.current;
};

// Mostly useful for unmount animations
export const useLastDefinedValueProvider = <T>(
  valueProvider: () => T | undefined,
): (() => T | undefined) => {
  const lastDefinedValue = useRef<T>();
  return () => {
    const value = valueProvider();
    if (value) {
      lastDefinedValue.current = value;
    }
    return lastDefinedValue.current;
  };
};

// Mostly useful for unmount animations
export const useLastNonEmptyArray = <T>(array: T[]): T[] => {
  const lastNonEmptyArray = useRef<T[]>(array);
  if (array.length > 0) {
    lastNonEmptyArray.current = array;
  }
  return lastNonEmptyArray.current;
};
