import React from 'react';
import Helmet from 'react-helmet';
import { FrontPageContext } from '../appTypes';
import { SelectedAppLocaleStorage } from '../appStorage';
import { isServer } from '../utils/gatsbyUtils';
import { AppLocalePrefixes } from '../appConstantsShared';
import { ShLocales } from '@shoootin/config';

// We create an inline tag in head, using Helmet, because
// we want this script to run asap
// (even before first contentful paint / JS / React hydratation...)
// we don't want user to see a page and then be redirected,
// we want to redirect earlier
// IMPORTANT: write this untranspiled JS in a legacy IE11 compatible way!
// Note this may execute before any polyfill has been loaded, so take care
const createScriptContent = (props: FrontPageContext): string =>
  // needed to avoid unwanted reformatting
  // prettier-ignore
  `
(function() {
  var frontPageContext = ${JSON.stringify(props)};
  var localeList = ${JSON.stringify(ShLocales)};
  var localePrefixes = ${JSON.stringify(AppLocalePrefixes)};
  var persistedLocale = JSON.parse(localStorage.getItem("${SelectedAppLocaleStorage.key}"));
  
  // console.log("frontPageContext",frontPageContext);
  // console.log("localeList",localeList);
  // console.log("localePrefixes",localePrefixes);
  // console.log("persistedLocale",persistedLocale);
  
  function removePrefix(str,prefix) {
    var hasPrefix = str.indexOf(prefix) === 0;
    return hasPrefix ? str.substr(prefix.length) : str;
  }
  
  function redirectToLocale(newLocale) {
    var unprefixedPathname = removePrefix(window.location.pathname,frontPageContext.localePrefix);
    var prefixedPathname = localePrefixes[newLocale] + unprefixedPathname;
    // console.log("redirect",newLocale,prefixedPathname);
    // preserve potential querystring
    window.location.href = prefixedPathname + window.location.search;
  }
  
  function detectBestLocale() {
    var languages = (navigator.languages || [navigator.userLanguage]);
    // console.log("languages",languages);
    var bestLocale = languages.map(function(language) {
      return language.replace("-","_");
    }).filter(function(locale) {
      return localeList.indexOf(locale) >= 0
    })[0];
    // console.log("bestLocale",bestLocale); 
    return bestLocale; 
  }
  
  // We redirect to user's last locale choice
  if ( persistedLocale ) {
    if (persistedLocale !== frontPageContext.locale) {
      redirectToLocale(persistedLocale)
    }
  }
  // If it's a new user, we redirect to the best match locale
  else {
    // see https://stackoverflow.com/a/20084661/82609
    var isBot = /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
    // console.log("isBot",isBot);
    if ( !isBot ) {
      var bestLocale = detectBestLocale();
      if ( bestLocale && bestLocale !== frontPageContext.locale ) {
        redirectToLocale(bestLocale);
      }
    }
  }
})();  
`;

const FrontLocaleRedirectionScript = (props: FrontPageContext) => {
  // This should only apply for SSR generated pages
  // We don't want this script to kick-in again in case of a Gatsby navigate()
  if (!isServer()) {
    return null;
  }
  return (
    <Helmet
      script={[
        // See https://github.com/nfl/react-helmet/issues/448#issuecomment-553864277
        {
          type: 'text/javascript',
          innerHTML: createScriptContent(props),
        },
      ]}
    />
  );
};

export default FrontLocaleRedirectionScript;
