import { FrontKeys, ShIntlMessageDefinition } from '@shoootin/translations';
import { ProfessionShootingType } from './appConstants';

export const getFrontMessage = (
  key: FrontKeys,
  defaultMessage?: string,
): ShIntlMessageDefinition => {
  return { id: key, defaultMessage: defaultMessage ?? key };
};

// For dynamic keys, TS safety won't work
export const getFrontMessageUnsafe = (
  key: string,
  defaultMessage?: string,
): ShIntlMessageDefinition => getFrontMessage(key as FrontKeys, defaultMessage);

export const getProfessionMessage = (
  shootingType: ProfessionShootingType,
  // Those are predefined message types provided by the backend
  messageType:
    | 'short'
    | 'title'
    | 'shortDescription'
    | 'fullDescription'
    | 'photoDescription'
    | 'photoOffers'
    | 'videoDescription'
    | 'videoOffers'
    | 'scanDescription'
    | 'scanOffers',
) => {
  return getFrontMessageUnsafe(
    `enums_typesOfShooting_${messageType}_${shootingType}`,
  );
};
