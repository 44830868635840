import { LinkDataInternal } from '../appTypes';
import { getFrontMessage } from '../appMessageDefinitions';
import {
  FrontCommonMessages,
  FrontNavigationMessages,
} from '../appCommonTranslations';
import { AppInternalPaths } from '../primitives/appLink';
import { ShLocale } from '@shoootin/config';
import { ShIntlMessageDefinition } from '@shoootin/translations';

export type MenuSection = {
  title: ShIntlMessageDefinition;
  links: LinkDataInternal[];
};
export const MenuSectionPrestation: MenuSection = {
  title: FrontNavigationMessages.prestations,
  links: [
    {
      label: FrontCommonMessages.photo,
      href: AppInternalPaths.service('photo'),
    },
    {
      label: FrontCommonMessages.video,
      href: AppInternalPaths.service('video'),
    },
    {
      label: FrontCommonMessages.scan,
      href: AppInternalPaths.service('matterport'),
    },
    {
      label: FrontCommonMessages.drone,
      href: AppInternalPaths.service('drone'),
    },
  ],
};
export const MenuSectionMetier: MenuSection = {
  title: FrontNavigationMessages.activities,
  links: [
    {
      label: getFrontMessage('enums_typesOfShooting_residentialRealEstate'),
      href: AppInternalPaths.profession('residentialRealEstate'), //AppInternalPaths.metier('residential'),
    },
    {
      label: getFrontMessage('enums_typesOfShooting_commercialRealEstate'),
      href: AppInternalPaths.profession('commercialRealEstate'),
    },
    {
      label: getFrontMessage('enums_typesOfShooting_architect'),
      href: AppInternalPaths.profession('architect'),
    },
    {
      label: getFrontMessage('enums_typesOfShooting_hotel'),
      href: AppInternalPaths.profession('hotel'),
    },
    {
      label: getFrontMessage('enums_typesOfShooting_restaurant'),
      href: AppInternalPaths.profession('restaurant'),
    },
    // {
    //   label: getFrontMessage("enums_typesOfShooting_construction"),
    //   href: AppInternalPaths.metier('construction'),
    // },
    // {
    //   label: getFrontMessage("enums_typesOfShooting_event"),
    //   href: AppInternalPaths.metier('event'),
    // },
    // { label: 'Portrait', href: '/metier' }, //TODO
    {
      label: getFrontMessage('enums_typesOfShooting_retail'),
      href: AppInternalPaths.profession('retail'),
    },
  ],
};

export const MenuSectionAbout = (locale: ShLocale): MenuSection => ({
  title: FrontNavigationMessages.about,
  links:
    locale === 'en_US'
      ? [
          {
            label: FrontNavigationMessages.aboutUs,
            href: AppInternalPaths.about,
          },
          { label: FrontNavigationMessages.cgu, href: AppInternalPaths.cgu },
          {
            label: getFrontMessage('client_accountPage_menuHelp'),
            href: 'https://help.shoootin.com/en',
          },
          {
            label: FrontNavigationMessages.joinShoootin,
            href: 'https://angel.co/company/shoootin',
          },
        ]
      : [
          {
            label: FrontNavigationMessages.aboutUs,
            href: AppInternalPaths.about,
          },
          {
            label: FrontNavigationMessages.legal,
            href: AppInternalPaths.legal,
          },
          { label: FrontNavigationMessages.cgu, href: AppInternalPaths.cgu },
          //TODO reactive links when reactivating page
          // { label: FrontNavigationMessages.faq, href: AppInternalPaths.faq },
          {
            label: getFrontMessage('client_accountPage_menuHelp'),
            href: 'https://help.shoootin.com/fr',
          },
          {
            label: FrontNavigationMessages.joinShoootin,
            href: 'https://shoootin.welcomekit.co/',
          },
        ],
});

export const isInternalLink = (link: LinkDataInternal) => {
  return !link.href.includes('https://');
};

export const Menu = (locale: ShLocale): MenuSection[] => [
  MenuSectionPrestation,
  MenuSectionMetier,
  MenuSectionAbout(locale),
];
