// Parallax
import { isDev } from 'appEnv';

export default observer => el => {
  observer.observe(el);
  el.ratio = el.getAttribute('data-parallax') * 1000;

  const updateOffset = () => {
    el.offset = window.pageYOffset + el.getBoundingClientRect().top;
  };

  updateOffset();

  if (isDev) {
    const onLoad = () => {
      updateOffset();
      window.removeEventListener('load', onLoad);
    };
    window.addEventListener('load', onLoad);
  }

  return el;
};
