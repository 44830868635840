import { defineMessages } from 'react-intl';

const prefix = 'front_about';

export const FrontAboutMessages = defineMessages({
  pageTitle: {
    id: `${prefix}_pageTitle`,
    defaultMessage: 'About us',
  },
  pageSectionStoryTitle: {
    id: `${prefix}_pageSectionStoryTitle`,
    defaultMessage: 'Une histoire d’experts',
  },
  pageSectionStoryDescription: {
    id: `${prefix}_pageSectionStoryDescription`,
    defaultMessage:
      "Fondé par Jeremy Teper, Shoootin voit le jour en Novembre 2015. Passionné de photographie et de nouvelles technologies, il s’entoure rapidement d'une équipe de spécialistes en photographie, en web et en communication pour fournir un service de qualité, mélant agilité et innovation.",
  },
  pageSectionStoryQuote: {
    id: `${prefix}_pageSectionStoryQuote`,
    defaultMessage:
      'L’idée de Shoootin est simple, rendre accessible la photographie d’intérieur à tous les professionnels ou particuliers qui en auraient besoin.',
  },
  pageSectionStoryAuthor: {
    id: `${prefix}_pageSectionStoryAuthor`,
    defaultMessage: 'Jeremy Teper,',
  },
  pageSectionStoryAuthorRole: {
    id: `${prefix}_pageSectionStoryAuthorRole`,
    defaultMessage: 'Fondateur de Shoootin',
  },

  pageSectionEngagementsTitle: {
    id: `${prefix}_pageSectionEngagementsTitle`,
    defaultMessage: 'Nos engagements',
  },

  pageSectionEngagementsPanels_1_title: {
    id: `${prefix}_pageSectionEngagementsPanels_1_title`,
    defaultMessage: 'Qualité',
  },
  pageSectionEngagementsPanels_1_p1: {
    id: `${prefix}_pageSectionEngagementsPanels_1_p1`,
    defaultMessage:
      'Shoootin est aujourd’hui reconnu par ses clients pour la ' +
      'qualité de ses photos grâce aux photographes professionnels, ' +
      'sélectionnés pour leur aisance technique et la qualité de leur ' +
      'travail.',
  },
  pageSectionEngagementsPanels_1_p2: {
    id: `${prefix}_pageSectionEngagementsPanels_1_p2`,
    defaultMessage:
      'Toutes les photos sont retouchées et validées par une équipe ' +
      'spécialisée en interne assurant ainsi une cohérence et une ' +
      'homogénéité d’un shooting à l’autre, pour un rendu final de ' +
      'qualité supérieure à ce qui est proposé habituellement sur le ' +
      'marché.',
  },
  pageSectionEngagementsPanels_2_title: {
    id: `${prefix}_pageSectionEngagementsPanels_2_title`,
    defaultMessage: 'Agilité',
  },
  pageSectionEngagementsPanels_2_p1: {
    id: `${prefix}_pageSectionEngagementsPanels_2_p1`,
    defaultMessage: 'Agilité p1',
  },
  pageSectionEngagementsPanels_2_p2: {
    id: `${prefix}_pageSectionEngagementsPanels_2_p2`,
    defaultMessage: 'Agilité p2',
  },
  pageSectionEngagementsPanels_3_title: {
    id: `${prefix}_pageSectionEngagementsPanels_3_title`,
    defaultMessage: 'Innovation',
  },
  pageSectionEngagementsPanels_3_p1: {
    id: `${prefix}_pageSectionEngagementsPanels_3_p1`,
    defaultMessage: 'Innovation p1',
  },
  pageSectionEngagementsPanels_3_p2: {
    id: `${prefix}_pageSectionEngagementsPanels_3_p2`,
    defaultMessage: 'Innovation p2',
  },

  pageSectionPhotographersTitle: {
    id: `${prefix}_pageSectionPhotographersTitle`,
    defaultMessage: 'Nos photographes',
  },
  pageSectionPhotographersDescription1: {
    id: `${prefix}_pageSectionPhotographersDescription1`,
    defaultMessage:
      'Grâce aux missions régulières que nous adressons à nos photographes, une relation de confiance s’établit naturellement avec les clients. Libre dans leurs choix, les photographes sélectionnent les zones où ils souhaitent se déplacer et les horaires auxquels ils désirent travailler.',
  },
  pageSectionPhotographersDescription2: {
    id: `${prefix}_pageSectionPhotographersDescription2`,
    defaultMessage: 'Un magnifique complément de revenu !',
  },

  pageSectionPhotographersCtaTitle: {
    id: `${prefix}_pageSectionPhotographersCtaTitle`,
    defaultMessage: 'Vous êtes photographe ?',
  },
  pageSectionPhotographersCtaDescription: {
    id: `${prefix}_pageSectionPhotographersCtaDescription`,
    defaultMessage:
      'Shoootin, c’est une flotte de photographes, des retoucheurs experts et une équipe de passionnés.',
  },
  pageSectionPhotographersCtaBtn: {
    id: `${prefix}_pageSectionPhotographersCtaBtn`,
    defaultMessage: 'Rejoignez nous',
  },

  pageSectionClientsTitle: {
    id: `${prefix}_pageSectionClientsTitle`,
    defaultMessage: 'Nos clients prennent la parole',
  },
  pageSectionTrustTitle: {
    id: `${prefix}_pageSectionTrustTitle`,
    defaultMessage: 'Ils nous font confiance',
  },
  pageSectionAssociationTitle: {
    id: `${prefix}_pageSectionAssociationTitle`,
    defaultMessage: 'Shoootin soutient les associations',
  },

  pageSectionAssociationDescription1: {
    id: `${prefix}_pageSectionAssociationDescription1`,
    defaultMessage:
      'Shoootin accompagne les associations qui œuvrent en faveur du lien social et de la protection de l’environnement. Nos valeurs nous poussent toujours plus à s’engager pour le bien commun.',
  },
  pageSectionAssociationDescription2: {
    id: `${prefix}_pageSectionAssociationDescription2`,
    defaultMessage:
      'Lutter contre les disparités est aujourd’hui fondamental, c’est pourquoi nous entreprenons des partenariats avec plusieurs associations tel que UNICEF, Croix-Rouge frainçaise, WWF, Greenpeace…',
  },
  testimony1Quote: {
    id: `${prefix}_testimony1Quote`,
    defaultMessage:
      'Nous avons apprécié la réactivité et rapidité d’exécution à chaque étape de notre collaboration. Leur plateforme est intuitive et simple à utiliser et le travail réalisé est de très grande qualité.',
  },
  testimony1Position: {
    id: `${prefix}_testimony1Position`,
    defaultMessage: 'Directrice Adjointe du Belleval Hotel',
  },
  testimony2Quote: {
    id: `${prefix}_testimony2Quote`,
    defaultMessage:
      'Shoootin à tout de suite répondu à nos attentes en nous envoyant des photographes irréprochables et en nous proposant des services innovants qui améliorent notre agence.',
  },
  testimony2Position: {
    id: `${prefix}_testimony2Position`,
    defaultMessage: 'Président de Kretz & Partners',
  },

  testimony3Quote: {
    id: `${prefix}_testimony3Quote`,
    defaultMessage:
      'De nombreux photographes professionnels, travaillant aux côtés d’une équipe très flexible et réactive.' +
      'Un immense sens du service pour leurs clients qui permet un rendu au plus près des attentes de nos clients internes.',
  },
  testimony3Position: {
    id: `${prefix}_testimony3Position`,
    defaultMessage:
      'Associate – Responsable Marketing France - Cushman & Wakefield',
  },
});
