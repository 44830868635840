import React, { useState } from 'react';
import {
  FormikConfig,
  FormikHelpers,
  FormikProps,
  useFormik,
  useFormikContext,
} from 'formik';
import { get } from 'lodash';
import { mergeHandlers } from '../../utils/mergeHandlers';
import { Omit } from 'utility-types';
import { ShApiUtils } from '@shoootin/api';
import { useIsMountedFn } from '../../hooks/useIsMounted';
// The typing for a formik form slice, which is a slice of the original form,
// containing only the desired field
// TODO fix this after https://github.com/jaredpalmer/formik/issues/1461
// TODO not all formik fields should be of type string!
export type FormikPropsSlice<FieldName extends string> = FormikProps<any>;
// export type FormikPropsSlice<FieldName extends string> = FormikProps<{ [key in Key]: string }>

export const isFormikTouched = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  name: FieldName,
): boolean => {
  return !!get(form.touched, name);
};

// TODO
export const getFormikValue = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  name: FieldName,
): any => {
  return get(form.values, name);
};

// Extract an error string from formik
// As formik also supports error arrays, and we don't use it
// This method will cast to single string error instead, to simplify typing
export const getFormikError = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  name: FieldName,
): string | undefined => {
  return get(form.errors, name) as string | undefined;
};

// Only display error if field is touched
export const getTouchedFormikError = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  name: FieldName,
): string | undefined => {
  if (isFormikTouched(form, name)) {
    return getFormikError(form, name);
  }
};

export type FormikInputBindProps = {
  name: string;
  value: any; // TODO how can this be typed?
  error: string | undefined;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
};

// return the most common props to wire an input to formik
// you may filter/enrich those props depending on the usecase
export const getFormikInputBindProps = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  name: FieldName,
  handlers: {
    onChange?: (e: React.ChangeEvent<any>) => void;
    onBlur?: (e: React.FocusEvent<any>) => void;
  } = {},
): FormikInputBindProps => {
  return {
    name: name,
    value: getFormikValue(form, name),
    error: getTouchedFormikError(form, name),
    onChange: mergeHandlers([form.handleChange, handlers.onChange]),
    onBlur: mergeHandlers([form.handleBlur, handlers.onBlur]),
  };
};

export const logFormikField = <FieldName extends string>(
  form: FormikPropsSlice<FieldName>,
  fieldName: FieldName,
) => {
  const value = get(form.values, fieldName);
  const error = get(form.errors, fieldName);
  const touched = get(form.touched, fieldName);
  console.debug(
    `[field=${fieldName}] [touched=${touched}] [error=${error}] [value=${value}]`,
  );
};

// This is useful to trigger scrollIntoView of form errors when submitCount is increased
// But it requires adding the FormikProvider as parent, otherwise it will always return fallback/0
export const useSubmitCount = (fallback = 0): number => {
  const formikContext = useFormikContext();
  return formikContext ? formikContext.submitCount : fallback;
};

type AppFormikConfig<Values extends object, Result = any> = Omit<
  FormikConfig<Values>,
  'onSubmit'
> & {
  submitAppForm: (values: Values) => Promise<Result>;
  extractFormErrors?: (responseData: any) => any;
};

// Enrich formik with additional missing features (like submitSuccess / global error / shared onSubmit logic)
// See also https://github.com/jaredpalmer/formik/issues/711#issuecomment-492186201
export const useAppFormik = <Values extends object, Result extends any = void>(
  config: AppFormikConfig<Values, Result>,
) => {
  const isMountedFn = useIsMountedFn();
  const { submitAppForm, ...otherFormikConfig } = config;
  const [globalError, setGlobalError] = useState<string | null>(null);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);

  const onSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    console.debug('onSubmit', values);
    actions.setSubmitting(true);
    setGlobalError(null);
    setSubmitSuccess(false);
    try {
      const result: Result = await submitAppForm(values);
      console.debug('form submitted successfully', { values, result });
      if (!isMountedFn()) return;
      form.resetForm();
      setSubmitSuccess(true);
    } catch (e) {
      if (!isMountedFn()) return;

      if (ShApiUtils.isApiResponseDataError(e)) {
        const responseData = e.response.data;
        const formErrors = config.extractFormErrors
          ? config.extractFormErrors(responseData)
          : responseData;
        console.debug('formik formErrors', formErrors);
        form.setErrors(formErrors);
        const globalError = formErrors.general || responseData.general;
        if (globalError) {
          setGlobalError(globalError);
        }
      } else {
        console.error('error', e);
        setGlobalError('Erreur technique: ' + e.message); // TODO how to handle those errors?
      }
    } finally {
      if (!isMountedFn()) return;
      actions.setSubmitting(false);
    }
  };

  const form = useFormik<Values>({
    ...otherFormikConfig,
    onSubmit,
  });

  return { form, globalError, isSubmitSuccess };
};
