import deepFreeze from 'deep-freeze';

// We want to freeze some objects to ensure they are not mutated
// freezing is expensive so we only do it in dev
export const freezeDevObject = <T extends object>(obj: T): T => {
  if (process.env.NODE_ENV === 'development') {
    if (obj === null || typeof obj === 'undefined') {
      return obj;
    }
    deepFreeze(obj);
  }
  return obj;
};
