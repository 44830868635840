import React from 'react';
import { H4 } from '../primitives/appTitle';

import LogoSvg from '../components/svg/logoSvg';
import InstagramSvg from '../components/svg/icon/social/instagramSvg';
import FacebookSvg from '../components/svg/icon/social/facebookSvg';
import TwitterSvg from '../components/svg/icon/social/twitterSvg';
import LinkedInSvg from '../components/svg/icon/social/linkedInSvg';
import Badges from '../components/badges';
import PatternSvg from '../components/svg/patternSvg';
import { LinkDataInternal } from '../appTypes';

import {
  AppInternalLink,
  AppInternalPath,
  AppInternalPaths,
} from '../primitives/appLink';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import {
  isInternalLink,
  MenuSectionAbout,
  MenuSectionMetier,
  MenuSectionPrestation,
} from './navigationStructure';
import { FrontMegaMenuMessages } from './megamenuTranslations';
import { FrontCommonMessages } from '../appCommonTranslations';
import {
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  twitterUrl,
} from '../appConstants';
import { ShCol, ShRow } from '@shoootin/components-web';

const FooterLinkList = ({ links }: { links: LinkDataInternal[] }) => (
  <ul>
    {links.map((link, i) => (
      <li key={i}>
        {isInternalLink(link) ? (
          <AppInternalLink
            className="link invert"
            to={link.href as AppInternalPath}
          >
            <ShText message={link.label as ShIntlMessageDefinition} />
          </AppInternalLink>
        ) : (
          <a className="link invert" href={link.href} target={'_blank'}>
            <ShText message={link.label as ShIntlMessageDefinition} />
          </a>
        )}
      </li>
    ))}
  </ul>
);

const Footer = () => {
  const locale = useShIntlLocale();
  return (
    <div className="footer section">
      <div className="container">
        <ShRow>
          <ShCol xs={60} sm={35} css={{ marginTop: '4rem' }}>
            <div className="footer__menu">
              <H4>
                <ShText message={MenuSectionMetier.title} />
              </H4>
              <FooterLinkList links={MenuSectionMetier.links} />
            </div>
            <div className="footer__menu line">
              <H4>
                <ShText message={MenuSectionPrestation.title} />
              </H4>
              <FooterLinkList links={MenuSectionPrestation.links} />
            </div>
            <div className="footer__menu">
              <H4>
                <ShText message={MenuSectionAbout(locale).title} />
              </H4>
              <FooterLinkList links={MenuSectionAbout(locale).links} />
            </div>
          </ShCol>
          <ShCol xs={60} sm={{ span: 20, offset: 5 }} className="footer__infos">
            <LogoSvg />
            <p className="invert">
              <ShText message={FrontCommonMessages.footerDescription} />{' '}
              <PatternSvg />
            </p>
            <ul className="footer__socials">
              <li>
                <a
                  target="_blank"
                  href={instagramUrl}
                  rel="noopener"
                  aria-label="Instagram"
                >
                  <InstagramSvg />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={facebookUrl}
                  rel="noopener"
                  aria-label="Facebook"
                >
                  <FacebookSvg />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={twitterUrl}
                  rel="noopener"
                  aria-label="Twitter"
                >
                  <TwitterSvg />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={linkedInUrl}
                  rel="noopener"
                  aria-label="LinkedIn"
                >
                  <LinkedInSvg />
                </a>
              </li>
            </ul>
            <H4>
              <ShText message={FrontMegaMenuMessages.photographer} />
            </H4>
            <AppInternalLink
              to={AppInternalPaths.photographer}
              className="btn large"
            >
              <ShText message={FrontMegaMenuMessages.joinUs} />
            </AppInternalLink>
          </ShCol>
        </ShRow>
        <Badges />
      </div>
    </div>
  );
};

export default Footer;
