import React, { useContext, useLayoutEffect } from 'react';
import { AppContextValue, FrontPageContext } from '../appTypes';
import { ShApiClient } from '@shoootin/api';
import { CurrentUserProvider } from '../state/currentUserState';
import { AppIntlProvider } from 'appIntlProvider';
import AppErrorBoundary from '../appErrorBoundary';
import { FormErrorSubmitCountProvider } from '../primitives/input/formError';
import { GoogleMapsAPIProvider } from '../primitives/maps/appMapLoader';
import { useAppContext } from './appContext';
import FrontLocaleRedirectionScript from './frontLocaleRedirectionScript';
import { ShCountry, ShLocaleConfigs } from '@shoootin/config';

// For now does not provide much more than app context, casted
type FrontContextValue = AppContextValue<FrontPageContext>;

const FrontContext = React.createContext<FrontContextValue | null>(null);

// There's a distinction between AppContext and FrontContext
// because we planned to have multiple contexts like ClientContext, PhotographerContext...
// Finally we are building other apps instead.
export const FrontContextProvider = (props: any) => {
  const frontContext = useAppContext() as FrontContextValue;
  const locale = frontContext.pageContext.locale;

  // Using useLayoutEffect instead of useEffect, because we want the locale to be set asap
  // With useEffect it's set a bit later and mount requests might fire before it gets set
  useLayoutEffect(() => {
    ShApiClient.setLocale(locale);
  }, [locale]);

  return (
    <>
      <FrontLocaleRedirectionScript {...frontContext.pageContext} />
      <FrontContext.Provider value={frontContext}>
        <FormErrorSubmitCountProvider>
          <AppIntlProvider locale={locale}>
            <CurrentUserProvider>
              <GoogleMapsAPIProvider>
                <AppErrorBoundary>{props.children}</AppErrorBoundary>
              </GoogleMapsAPIProvider>
            </CurrentUserProvider>
          </AppIntlProvider>
        </FormErrorSubmitCountProvider>
      </FrontContext.Provider>
    </>
  );
};

export const useFrontContext = (): FrontContextValue => {
  const value = useContext(FrontContext);
  if (value === null) {
    throw new Error('FrontContext is not provided');
  }
  return value;
};

const useFrontLocale = () => useFrontContext().pageContext.locale;

export const useFrontCountry = (): ShCountry => {
  const locale = useFrontLocale();
  return ShLocaleConfigs[locale].country;
};
