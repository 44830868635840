import React, { ReactNode } from 'react';
import classnames from 'classnames';

export const BaseH = ({
  size,
  modifier,
  className,
  children,
}: { size: number } & HProps) =>
  React.createElement(
    `h${size}`,
    { className: classnames('title', modifier, className) },
    children,
  );

type HProps = {
  modifier?: 'invert' | 'center' | 'hero-title';
  className?: string;
  children?: ReactNode;
};

export const H1 = (props: HProps) => <BaseH size={1} {...props} />;
export const H2 = (props: HProps) => <BaseH size={2} {...props} />;
export const H3 = (props: HProps) => <BaseH size={3} {...props} />;
export const H4 = (props: HProps) => <BaseH size={4} {...props} />;
export const H5 = (props: HProps) => <BaseH size={5} {...props} />;
