import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import { useAsyncCallback } from 'react-async-hook';
import { isNil, noop } from 'lodash';
import { Modifier, modifierContains } from '../utils/modifierUtils';
import { AppSpinnerOverlay } from './appOverlay';
import { ShColors } from '@shoootin/design-tokens';

// TODO unfinished impl
export const AppButton = ({
  loading,
  className,
  children,
  modifier,
  disabled = false,
  onClick,
}: {
  loading?: boolean;
  className?: string;
  children: ReactNode;
  modifier?: Modifier<
    'large' | 'nav' | 'light' | 'float' | 'active' | 'hour' | 'admin'
  >;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  const asyncOnClick = useAsyncCallback(onClick || noop, {
    onError: (error) => {
      console.error(
        'Async error propagated back to AppButton. Maybe you should handle this async error in a try/catch before it reaches AppButton',
        children,
        error,
      );
    },
  });

  // By default the "loading" props can override the behavior of asyncOnClick
  const finalLoading = isNil(loading) ? asyncOnClick.loading : loading;

  return (
    <button
      className={classnames('btn', { disabled }, modifier, className)}
      onClick={asyncOnClick.execute}
      disabled={disabled || finalLoading}
      css={{ position: 'relative', overflow: 'hidden' }}
    >
      {children}
      <AppSpinnerOverlay
        visible={finalLoading}
        color="white"
        size={modifierContains(modifier, 'large') ? 's' : 'xs'}
        css={{
          backgroundColor: ShColors.base,
        }}
      />
    </button>
  );
};
