import { defineMessages } from 'react-intl';

const frontCommonPrefix = 'front_common';

export const FrontCommonMessages = defineMessages({
  photo: {
    id: `${frontCommonPrefix}_photo`,
    defaultMessage: 'Photo',
  },
  video: {
    id: `${frontCommonPrefix}_video`,
    defaultMessage: 'Vidéo',
  },
  scan: {
    id: `${frontCommonPrefix}_scan`,
    defaultMessage: 'Matterport',
  },
  floorPlan: {
    id: `${frontCommonPrefix}_floorPlan`,
    defaultMessage: 'Floor plan',
  },

  floorPlanUnvailable: {
    id: `${frontCommonPrefix}_floorPlanUnvailable`,
    defaultMessage:
      'Please select Photos or Video offer to activate Floor plan',
  },
  matterport: {
    id: `${frontCommonPrefix}_matterport`,
    defaultMessage: 'Matterport',
  },
  drone: {
    id: `${frontCommonPrefix}_drone`,
    defaultMessage: 'Drone',
  },
  photoDesc: {
    id: `${frontCommonPrefix}_photoDesc`,
    defaultMessage:
      "A chaque métier son shooting photo. Vous pouvez découvrir les différentes offres qui s'adaptent à vos besoins.",
  },
  videoDesc: {
    id: `${frontCommonPrefix}_videoDesc`,
    defaultMessage:
      'Mettez en avant votre entreprise grâce à une vidéo de vos locaux ou des interviews de vos collaborateurs.',
  },
  scanDesc: {
    id: `${frontCommonPrefix}_scanDesc`,
    defaultMessage:
      'Commandez votre prestation Matterport, pour découvrir une visite réellement immersive.',
  },
  droneDesc: {
    id: `${frontCommonPrefix}_droneDesc`,
    defaultMessage:
      'Commandez votre prestation drone, pour donner un nouveau point de vue aérien à vos locaux, et les mettre en avant.',
  },

  nPhotos: {
    id: `${frontCommonPrefix}_nPhoto`,
    defaultMessage: '{n} photos',
  },
  timeZone: {
    id: `${frontCommonPrefix}_timezone`,
    defaultMessage: 'Time zone',
  },
  yourPreferredLanguage: {
    id: `${frontCommonPrefix}_yourPreferredLanguage`,
    defaultMessage: 'Your Preferred Language',
  },
  gallery: {
    id: `${frontCommonPrefix}_gallery`,
    defaultMessage: 'Galerie',
  },
  seeGallery: {
    id: `${frontCommonPrefix}_seeGallery`,
    defaultMessage: 'Voir la galerie',
  },
  galleryP1: {
    id: `${frontCommonPrefix}_galleryP1`,
    defaultMessage: 'Vous avez un besoin spécifique ?',
  },
  galleryP2: {
    id: `${frontCommonPrefix}_galleryP2`,
    defaultMessage: 'Nous nous adaptons pour chaque client.',
  },
  galleryCta: {
    id: `${frontCommonPrefix}_galleryCta`,
    defaultMessage: 'Commander',
  },
  usecase: {
    id: `${frontCommonPrefix}_usecase`,
    defaultMessage: 'Etude de cas',
  },
  prestationOthers: {
    id: `${frontCommonPrefix}_prestationOthers`,
    defaultMessage: 'Nos autres prestations',
  },
  prestationSeeOther: {
    id: `${frontCommonPrefix}_prestationSeeOther`,
    defaultMessage: 'Voir la prestation',
  },
  footerDescription: {
    id: `${frontCommonPrefix}_footerDescription`,
    defaultMessage:
      'Shoootin vous propose la plus belle qualité de photographie d’intérieur pour seulement 75€. Commander votre photographe professionnel en quelques clics puis télécharger en ligne vos photos dans les 24h.',
  },

  seeProfession: {
    id: `${frontCommonPrefix}_seeProfession`,
    defaultMessage: 'Voir le métier',
  },
  priceFrom: {
    id: `${frontCommonPrefix}_priceFrom`,
    defaultMessage: 'A partir de {price}',
  },
  timeFrom: {
    id: `${frontCommonPrefix}_timeFrom`,
    defaultMessage: 'A partir de {time}',
  },

  matterportSurfaceBetween: {
    id: `${frontCommonPrefix}_matterportSurfaceBetween`,
    defaultMessage: 'Pour toute surface comprise entre {low} et {high}.',
  },

  seeUsecase: {
    id: `${frontCommonPrefix}_seeUsecase`,
    defaultMessage: 'Voir l’étude de cas',
  },
  professionSeeMore: {
    id: `${frontCommonPrefix}_professionSeeMore`,
    defaultMessage: 'En savoir plus',
  },
  professionTrustUs: {
    id: `${frontCommonPrefix}_professionTrustUs`,
    defaultMessage: 'Ils nous font confiance',
  },
  professionAdapt: {
    id: `${frontCommonPrefix}_professionAdapt`,
    defaultMessage: 'Nos offres s’adaptent {br} aussi à ces activités',
  },
  professionPhotoSectionTitle: {
    id: `${frontCommonPrefix}_professionPhotoSectionTitle`,
    defaultMessage: 'Les reportages photos',
  },
  professionVideoSectionTitle: {
    id: `${frontCommonPrefix}_professionVideoSectionTitle`,
    defaultMessage: 'Les vidéos',
  },
  professionScanSectionTitle: {
    id: `${frontCommonPrefix}_professionScanSectionTitle`,
    defaultMessage: 'Le Matterport',
  },
  professionNav1: {
    id: `${frontCommonPrefix}_professionNav1`,
    defaultMessage: 'Intro',
  },
  professionNav2: {
    id: `${frontCommonPrefix}_professionNav2`,
    defaultMessage: 'Les offres',
  },
  professionNav3: {
    id: `${frontCommonPrefix}_professionNav3`,
    defaultMessage: 'Etude de cas',
  },
  multiMatterport: {
    id: `${frontCommonPrefix}_multiMatterport`,
    defaultMessage:
      'Vous pouvez accéder à ces 2 visites immersives en cliquant sur {icon}',
  },
  rgpd: {
    id: `${frontCommonPrefix}_rgpd`,
    defaultMessage: 'This website uses cookies to enhance the user experience.',
  },
  rgpdAccept: {
    id: `${frontCommonPrefix}_rgpdAccept`,
    defaultMessage: 'I understand',
  },
});

export const FrontNavigationMessages = defineMessages({
  prestations: {
    id: `${frontCommonPrefix}_prestations`,
    defaultMessage: 'Nos prestations',
  },
  activities: {
    id: `${frontCommonPrefix}_activities`,
    defaultMessage: 'Votre activité',
  },
  about: {
    id: `${frontCommonPrefix}_about`,
    defaultMessage: 'A propos',
  },
  aboutUs: {
    id: `${frontCommonPrefix}_aboutUs`,
    defaultMessage: 'Shoootin en détail',
  },
  legal: {
    id: `${frontCommonPrefix}_legal`,
    defaultMessage: 'Mentions légales',
  },
  faq: {
    id: `${frontCommonPrefix}_faq`,
    defaultMessage: 'FAQ',
  },
  cgu: {
    id: `${frontCommonPrefix}_cgu`,
    defaultMessage: 'CGU',
  },
  followUs: {
    id: `${frontCommonPrefix}_followUs`,
    defaultMessage: 'Suivez - nous',
  },
  myAccount: {
    id: `${frontCommonPrefix}_myAccount`,
    defaultMessage: 'My account',
  },
  joinShoootin: {
    id: `${frontCommonPrefix}_joinShoootin`,
    defaultMessage: 'Join the team',
  },
  // help: {
  //   id: `${frontCommonPrefix}_help`,
  //   defaultMessage: 'Help',
  // },
});
