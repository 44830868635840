import React from 'react';

const VideoPlaySvg = (props: any) => (
  <svg
    className="video-play"
    height={53}
    width={53}
    viewBox="0 0 53 53"
    {...props}
  >
    <path className="video-play-arrow" d="M21,15.5v23L37.3,27L21,15.5z" />
    <path
      className="video-play-border"
      d="M26.5,0.7c14.2,0,25.8,11.5,25.8,25.8c0,14.2-11.5,25.7-25.8,25.7c-14.2,0-25.7-11.5-25.7-25.7C0.8,12.3,12.3,0.7,26.5,0.7z"
      fill="none"
    />
  </svg>
);

export default VideoPlaySvg;
