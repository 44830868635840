import { useEffect, useRef } from 'react';

const useOnScroll = (listener: (event: Event) => void) => {
  const listenerRef = useRef(listener);
  useEffect(() => {
    listenerRef.current = listener;
  }, [listener]);

  useEffect(() => {
    const stableListener = (event: Event) => listenerRef.current(event);
    window.addEventListener('scroll', stableListener);
    return () => {
      window.removeEventListener('scroll', stableListener);
    };
  }, []);
};

export default useOnScroll;
