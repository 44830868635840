import React from 'react';
import { MainLayout } from '../layout/mainLayout';
import { H1, H5 } from '../primitives/appTitle';
import classnames from 'classnames';
import { ShColors } from '@shoootin/design-tokens';

export const PageErrorContent = ({
  message = 'Technical error',
}: {
  message?: string;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '70vh',
      }}
    >
      <H1>
        <div
          css={{
            backgroundImage: `linear-gradient(135deg, ${ShColors.base} 0%, ${ShColors.black} 100%)`,
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            fontWeight: 400,
            fontSize: 135,
            marginBottom: 40,
          }}
        >
          500
        </div>
      </H1>
      <div>
        <H5>{message}</H5>
      </div>
    </div>
  );
};

const PageError = () => (
  <MainLayout
    className={classnames('page-form  light')}
    showContact={false}
    pageName="error"
  >
    <PageErrorContent />
  </MainLayout>
);

export default PageError;
