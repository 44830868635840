import { BadgeAppStore, BadgeGooglePlay } from '@shoootin/config';
import React from 'react';
import {
  ShBadgeAppStoreSvg,
  ShBadgeGooglePlaySvgEn,
} from '@shoootin/components-web';

const Badges = () => (
  <div className="badges">
    <a
      href={BadgeGooglePlay.href}
      target="_blank"
      rel="noopener"
      title={BadgeGooglePlay.label}
    >
      <ShBadgeGooglePlaySvgEn />
    </a>
    <a
      href={BadgeAppStore.href}
      target="_blank"
      rel="noopener"
      title={BadgeAppStore.label}
    >
      <ShBadgeAppStoreSvg height={64} />
    </a>
  </div>
);

export default Badges;
