import { graphql, useStaticQuery } from 'gatsby';
import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { AppGatsbyImageFluid } from 'primitives/appImage';
import { ClientLogoName } from 'appConstants';

// TODO not sure it's nice to have such an interface
const useClientLogoImages = (
  logos: ClientLogoName[],
): AppGatsbyImageFluid[] => {
  const data: any = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: ASC }
        filter: { relativePath: { glob: "logo/logo-*" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(
                maxWidth: 300
                srcSetBreakpoints: [100, 200, 300, 400, 600]
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const byFilenames: any = keyBy(
      data.allFile.edges.map((edge: any) => edge.node),
      file => file.name,
    );
    return logos.map(logo => {
      const fileName = `logo-${logo}`;
      const file = byFilenames[fileName];
      if (!file) {
        console.debug({ byFilenames });
        throw new Error('file not found for fileName=' + fileName);
      }
      return file.childImageSharp.fluid;
    });
  }, [logos, data]);
};

export default useClientLogoImages;
