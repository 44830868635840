import React from 'react';

const PatternSvg = ({ rainbow, ...props }: { rainbow: boolean } & any) => (
  <svg
    className="pattern"
    width={150}
    height={100}
    viewBox="0 0 150 100"
    {...props}
  >
    <defs>
      <linearGradient id="pattern-rainbow" x1="0%" y1="50%" x2="100%" y2="50%">
        <stop
          offset={0}
          style={{
            stopColor: '#5472FF',
          }}
        />
        <stop
          offset={1}
          style={{
            stopColor: '#F054FF',
          }}
        />
      </linearGradient>
    </defs>
    <path
      fill={rainbow ? 'url(#pattern-rainbow)' : undefined}
      d="M8.21,18.44l-8.2,0.13v-0.08C0.048,8.255,8.365-0.017,18.6,0v8.16C12.902,8.153,8.264,12.742,8.21,18.44v0.08 M8.21,59.16l-8.2,0.13v-0.08C0.048,48.975,8.365,40.703,18.6,40.72v8.16C12.902,48.873,8.264,53.462,8.21,59.16v0.08 M8.21,99.88l-8.2,0.13v-0.08c0.037-10.237,8.353-18.513,18.59-18.5v8.16C12.898,89.584,8.259,94.178,8.21,99.88v0.08 M52.01,18.439l-8.2,0.13v-0.08c0.036-10.232,8.348-18.503,18.58-18.49v8.16C56.694,8.153,52.059,12.743,52.01,18.439v0.08 M52.01,59.159l-8.2,0.13v-0.08c0.036-10.232,8.348-18.503,18.58-18.49v8.16C56.694,48.873,52.059,53.463,52.01,59.159v0.08 M52.01,99.879l-8.2,0.13v-0.08c0.036-10.234,8.346-18.508,18.58-18.5v8.16C56.69,89.583,52.054,94.179,52.01,99.879v0.08 M95.81,18.439l-8.2,0.13v-0.08c0.033-10.233,8.347-18.507,18.58-18.49v8.16C100.492,8.149,95.856,12.741,95.81,18.439v0.08 M95.81,59.158l-8.2,0.13v-0.08c0.033-10.233,8.347-18.507,18.58-18.49v8.16C100.492,48.869,95.856,53.461,95.81,59.158v0.08 M95.81,99.878l-8.2,0.13v-0.08c0.032-10.235,8.345-18.512,18.58-18.5v8.16C100.489,89.58,95.851,94.177,95.81,99.878v0.08 M139.6,18.438l-8.19,0.13v-0.08c0.033-10.233,8.347-18.507,18.58-18.49v8.16C144.292,8.151,139.654,12.74,139.6,18.438l0.01,0.08 M139.6,59.158l-8.19,0.13v-0.08c0.033-10.233,8.347-18.507,18.58-18.49v8.16C144.292,48.871,139.654,53.459,139.6,59.158l0.01,0.08 M139.6,99.877l-8.19,0.13v-0.08c0.032-10.235,8.345-18.512,18.58-18.5v8.16C144.288,89.582,139.65,94.176,139.6,99.877l0.01,0.08"
    />
  </svg>
);

export default PatternSvg;
