import {
  addOrRemoveClass,
  createComponents,
  device,
  toArray,
} from 'helpers/helpers';
import Parallax from './parallax';
import { getAppIntersectionObserver } from 'appIntersectionObserver';

export function initLegacyGlobalJS() {
  console.debug('initLegacyGlobalJS');

  const viewportHeight = window.innerHeight;

  const parallaxItems = toArray(
    createComponents('[data-parallax]', Parallax(getAppIntersectionObserver())),
  );

  const localHeader = document.querySelector('#header');

  const setScrollEvent = () => {
    const pageOffset = window.pageYOffset;

    // sticky header
    const isHeaderFixed = pageOffset > 0;
    const isLocked = document.body.classList.contains('lock');
    if (localHeader && !isLocked) {
      addOrRemoveClass(localHeader, isHeaderFixed, 'fixed');
    }

    const canParallax = !device().isMobile;
    if (canParallax) {
      parallaxItems.forEach((parallaxItem) =>
        updateParallaxItem(parallaxItem, pageOffset, viewportHeight),
      );
    }
  };

  window.addEventListener('scroll', setScrollEvent);

  return () => {
    // do cleanup
    window.removeEventListener('scroll', setScrollEvent);
  };
}

const updateParallaxItem = (parallaxItem, pageOffset, viewportHeight) => {
  if (parallaxItem.inBounds) {
    /*
    // TODO what was the purpose of this?
    const deltaDiff =
      $fixedCart &&
      fixedCartHeight &&
      $fixedCart.classList.contains('opened')
        ? fixedCartHeight * 4
        : 0;
        */
    const deltaDiff = 0;
    const deltaInViewport =
      pageOffset - deltaDiff + viewportHeight - parallaxItem.offset;
    const parallaxProgress =
      deltaInViewport / (viewportHeight + parallaxItem.offsetHeight);

    // We allow to provide a custom parallax offset with data attribute for fine-tunning
    const dataParallaxOffsetString = parallaxItem.dataset.parallaxOffset;
    const dataParallaxOffsetNumber =
      dataParallaxOffsetString && !isNaN(parseFloat(dataParallaxOffsetString))
        ? parseFloat(dataParallaxOffsetString)
        : 0;

    const parallaxOffset =
      parallaxItem.ratio * (parallaxProgress * -0.5) + dataParallaxOffsetNumber;
    parallaxItem.style.transform = `translateY(${parallaxOffset}px)`;
  }
};
