import { defineMessages } from 'react-intl';

const prefix = 'front_home';

export const FrontHomeMessages = defineMessages({
  // begin hero

  heroOrder: {
    id: `${prefix}_heroOrder`,
    defaultMessage: 'Commander',
  },
  heroPhoto: {
    id: `${prefix}_heroPhoto`,
    defaultMessage: 'votre reportage photo',
  },
  heroVideo: {
    id: `${prefix}_heroVideo`,
    defaultMessage: 'votre reportage video',
  },
  heroMatterport: {
    id: `${prefix}_heroMatterport`,
    defaultMessage: 'votre reportage matterport',
  },
  heroDrone: {
    id: `${prefix}_heroDrone`,
    defaultMessage: 'votre reportage drone',
  },

  heroPhotoPrice: {
    id: `${prefix}_heroPhotoPrice`,
    defaultMessage: 'à partir de 75€',
  },
  heroVideoPrice: {
    id: `${prefix}_heroVideoPrice`,
    defaultMessage: 'à partir de 100€',
  },
  heroMatterportPrice: {
    id: `${prefix}_heroMatterportPrice`,
    defaultMessage: 'à partir de 125€',
  },
  heroDronePrice: {
    id: `${prefix}_heroDronePrice`,
    defaultMessage: 'à partir de 150€',
  },

  heroP1: {
    id: `${prefix}_heroP1`,
    defaultMessage:
      'Nous vous assurons une qualité incomparable et une livraison en moins de 24h.',
  },

  heroMore: {
    id: `${prefix}_heroMore`,
    defaultMessage: 'En savoir plus',
  },

  // end hero

  // begin highlights
  highlightsTitle: {
    id: `${prefix}_highlightsTitle`,
    defaultMessage: 'La qualité partout et pour tous',
  },

  highlightsDescription: {
    id: `${prefix}_highlightsDescription`,
    defaultMessage:
      'Shoootin, c’est une flotte de photographes, des retoucheurs experts et une équipe de passionnés. Tous, nous partageons les mêmes valeurs.',
  },
  highlightsMore: {
    id: `${prefix}_highlightsMore`,
    defaultMessage: 'En savoir plus',
  },

  highlightsPanels_1_title: {
    id: `${prefix}_highlightsPanels_1_title`,
    defaultMessage: 'Qualité',
  },
  highlightsPanels_1_p1: {
    id: `${prefix}_highlightsPanels_1_p1`,
    defaultMessage:
      'Nos photographes professionnels vous assurent la meilleure qualité photographique du marché.',
  },
  highlightsPanels_2_title: {
    id: `${prefix}_highlightsPanels_2_title`,
    defaultMessage: 'Agilité',
  },
  highlightsPanels_2_p1: {
    id: `${prefix}_highlightsPanels_2_p1`,
    defaultMessage:
      'Quelque soit votre secteur d’activité, Shoootin s’adapte à vos demandes pour vous garantir un service personnalisé.',
  },
  highlightsPanels_3_title: {
    id: `${prefix}_highlightsPanels_3_title`,
    defaultMessage: 'Innovation',
  },
  highlightsPanels_3_p1: {
    id: `${prefix}_highlightsPanels_3_p1`,
    defaultMessage:
      'Shoootin mise sur la recherche et le développement pour vous garantir une expérience unique.',
  },
  // end highlights

  // begin profession
  professionTitle: {
    id: `${prefix}_professionTitle`,
    defaultMessage: 'L’exigence pour\ntous les secteurs d’activité',
  },
  // end profession

  // begin zones
  zonesTitle: {
    id: `${prefix}_zonesTitle`,
    defaultMessage: 'Nos zones d’interventions',
  },
  zonesDescription: {
    id: `${prefix}_zonesDescription`,
    defaultMessage:
      'Nous mettons tout en œuvre pour vous satisfaire que vous soyez à la mer, à la montagne ou dans le désert.',
  },
  zonesMore: {
    id: `${prefix}_zonesMore`,
    defaultMessage: 'En savoir plus',
  },
  // end zones

  // begin howItWorks
  howItWorksTitle: {
    id: `${prefix}_howItWorksTitle`,
    defaultMessage: 'Comment ça marche',
  },
  howItWorksDescription: {
    id: `${prefix}_howItWorksDescription`,
    defaultMessage:
      'Commander des clichés, une vidéo ou encore un plan drone n’a jamais été aussi simple avec Shoootin.',
  },

  howItWorksPanel1Title: {
    id: `${prefix}_howItWorksPanel1Title`,
    defaultMessage: '1. Déplacement',
  },
  howItWorksPanel1Description: {
    id: `${prefix}_howItWorksPanel1Description`,
    defaultMessage:
      'Nos photographes réalisent les meilleurs prises de vue en fonction du forfait sélectionné.',
  },
  howItWorksPanel2Title: {
    id: `${prefix}_howItWorksPanel2Title`,
    defaultMessage: '2. Prises de vue',
  },
  howItWorksPanel2Description: {
    id: `${prefix}_howItWorksPanel2Description`,
    defaultMessage:
      'Nos photographes réalisent les meilleurs prises de vue en fonction du forfait sélectionné.',
  },
  howItWorksPanel3Title: {
    id: `${prefix}_howItWorksPanel3Title`,
    defaultMessage: '3. Post-prod interne',
  },
  howItWorksPanel3Description: {
    id: `${prefix}_howItWorksPanel3Description`,
    defaultMessage:
      'Nos photographes réalisent les meilleurs prises de vue en fonction du forfait sélectionné.',
  },
  howItWorksPanel4Title: {
    id: `${prefix}_howItWorksPanel4Title`,
    defaultMessage: '4. Livraison rapide',
  },
  howItWorksPanel4Description: {
    id: `${prefix}_howItWorksPanel4Description`,
    defaultMessage:
      'Nos photographes réalisent les meilleurs prises de vue en fonction du forfait sélectionné.',
  },
  // end howItWorks

  etAussi: {
    id: `${prefix}_etAussi`,
    defaultMessage: 'Et aussi',
  },
  trustTitle: {
    id: `${prefix}_trustTitle`,
    defaultMessage: 'Trusted by over 5000 companies',
  },
});
