import { ShApiClient } from '../ShApiClient';
import {
  ShVideoComment,
  ShVideoCommentForm,
  ShVideoCommentReplyForm,
} from './videoReviewDTO';
import { ShVideoReviewUser } from '@shoootin/config';
import { ShVideoClientDTO } from '../shootings/shootingAPI';

export type ShVideoCommentResult = {
  comments: ShVideoComment[];
};

export const ShAdminVideoReviewApi: ShVideoReviewApi = {
  getVideo: (id: String) =>
    ShApiClient.call<{ video: ShVideoClientDTO }>({
      method: 'GET',
      url: `/admin/videoReview/getVideo/${id}`,
    }),
  getVideoComments: (videoId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'GET',
      url: `/admin/videoReview/getVideoComments/${videoId}`,
    }),
  addComment: (videoId: string, payload: ShVideoCommentForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/admin/videoReview/addComment/${videoId}`,
      data: payload,
    }),

  toggleComplete: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/admin/videoReview/toggleComplete/${videoId}/${id}`,
    }),

  toggleInternal: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/admin/videoReview/toggleInternal/${videoId}/${id}`,
    }),

  deleteComment: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/admin/videoReview/deleteComment/${videoId}/${id}`,
    }),
  addReply: (videoId: string, id: string, payload: ShVideoCommentReplyForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/admin/videoReview/addReply/${videoId}/${id}`,
      data: payload,
    }),
  deleteReply: (videoId: string, id: string, replyId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/admin/videoReview/deleteReply/${videoId}/${id}/${replyId}`,
    }),
  stuckVideo: (shootingId: string, message: string) =>
    ShApiClient.call({
      method: 'POST',
      url: `/admin/videos/stuckVideo/${shootingId}`,
      data: {
        message,
      },
    }),
  changesVideo: (shootingId: string, message: string) =>
    ShApiClient.call({
      method: 'POST',
      url: `/admin/videos/changesVideo/${shootingId}`,
      data: {
        message,
      },
    }),
};

export const ShFrontVideoReviewApi: ShVideoReviewApi = {
  getVideo: (id: String) =>
    ShApiClient.call<{ video: ShVideoClientDTO }>({
      method: 'GET',
      url: `/TODO/${id}`,
    }),
  getVideoComments: (videoId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'GET',
      url: `/videoReview/getVideoComments/${videoId}`,
    }),
  addComment: (videoId: string, payload: ShVideoCommentForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/videoReview/addComment/${videoId}`,
      data: payload,
    }),
  deleteComment: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/videoReview/deleteComment/${videoId}/${id}`,
    }),
  addReply: (videoId: string, id: string, payload: ShVideoCommentReplyForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/videoReview/addReply/${videoId}/${id}`,
      data: payload,
    }),
  deleteReply: (videoId: string, id: string, replyId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/videoReview/deleteReply/${videoId}/${id}/${replyId}`,
    }),
};

export const ShPostprodVideoReviewApi: ShVideoReviewApi = {
  getVideo: (id: String) =>
    ShApiClient.call<{ video: ShVideoClientDTO }>({
      method: 'GET',
      url: `/postprod/videoReview/getVideo/${id}`,
    }),
  getVideoComments: (videoId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'GET',
      url: `/postprod/videoReview/getVideoComments/${videoId}`,
    }),
  addComment: (videoId: string, payload: ShVideoCommentForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/postprod/videoReview/addComment/${videoId}`,
      data: payload,
    }),

  toggleComplete: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/postprod/videoReview/toggleComplete/${videoId}/${id}`,
    }),

  toggleInternal: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/postprod/videoReview/toggleInternal/${videoId}/${id}`,
    }),

  deleteComment: (videoId: string, id: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/postprod/videoReview/deleteComment/${videoId}/${id}`,
    }),
  addReply: (videoId: string, id: string, payload: ShVideoCommentReplyForm) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'POST',
      url: `/postprod/videoReview/addReply/${videoId}/${id}`,
      data: payload,
    }),
  deleteReply: (videoId: string, id: string, replyId: string) =>
    ShApiClient.call<ShVideoCommentResult>({
      method: 'DELETE',
      url: `/postprod/videoReview/deleteReply/${videoId}/${id}/${replyId}`,
    }),
  //this is duplicated in apps/app-js/src/postprod/postprodAPI.tsx
  stuckVideo: (shootingId: string, message: string) =>
    ShApiClient.call({
      method: 'POST',
      url: `/postprod/shootings/stuckVideo/${shootingId}`,
      data: {
        message,
      },
    }),
};

export type ShVideoReviewApi = {
  getVideo: (videoId: string) => Promise<{ video: ShVideoClientDTO }>;
  getVideoComments: (videoId: string) => Promise<ShVideoCommentResult>;
  addComment: (
    videoId: string,
    payload: ShVideoCommentForm,
  ) => Promise<ShVideoCommentResult>;
  toggleComplete?: (
    videoId: string,
    id: string,
  ) => Promise<ShVideoCommentResult>;
  toggleInternal?: (
    videoId: string,
    id: string,
  ) => Promise<ShVideoCommentResult>;
  deleteComment: (videoId: string, id: string) => Promise<ShVideoCommentResult>;
  addReply: (
    videoId: string,
    id: string,
    payload: ShVideoCommentReplyForm,
  ) => Promise<ShVideoCommentResult>;
  deleteReply: (
    videoId: string,
    id: string,
    replyId: string,
  ) => Promise<ShVideoCommentResult>;
  stuckVideo?: (shootingId: string, message: string) => Promise<void>;
  changesVideo?: (shootingId: string, message: string) => Promise<void>;
};

export type ShPartialVideoReviewUser = 'shoootin' | 'client' | 'editor';
//from ShVideoReviewUser but Exclude seems to not work when compiling...

export const ShVideoReviewApiByUserType: Record<
  ShPartialVideoReviewUser,
  ShVideoReviewApi
> = {
  shoootin: ShAdminVideoReviewApi,
  client: ShFrontVideoReviewApi,
  editor: ShPostprodVideoReviewApi,
};
