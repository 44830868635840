import { AxiosError } from 'axios';
import { Required } from 'utility-types';
import { isNil } from 'lodash';
import { ShPageSize } from '@shoootin/config';

// Alias to axios error, we might change request fwk someday?
export type ShApiError = AxiosError;

export type ShApiResponseError = Required<
  ShApiError,
  'code' | 'request' | 'response'
>;

export const ShApiUtils = {
  isApiError: (e: Error): e is ShApiError => {
    // @ts-ignore
    return !!e.isAxiosError;
  },

  isApiResponseError: (error: Error): error is ShApiResponseError => {
    return ShApiUtils.isApiError(error) && !isNil(error.response);
  },

  isApiResponseDataError: (error: Error): error is ShApiResponseError => {
    return ShApiUtils.isApiResponseError(error) && !isNil(error.response.data);
  },

  getApiFormErrors: <T extends Record<string, any> = any>(e: Error): T => {
    if (ShApiUtils.isApiResponseDataError(e)) {
      return e.response.data ?? ({} as T);
    } else {
      return {} as T;
    }
  },

  isApiUnauthorizedError: (error: Error) => {
    return (
      ShApiUtils.isApiResponseError(error) && error.response.status === 401
    );
  },
};

export type ShPaginationPayload<T extends string | undefined> = {
  skip?: number;
  limit?: ShPageSize;
  orderBy?: T;
};
