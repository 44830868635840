import React from 'react';
import useIntersectionObserverInitializer from '../hooks/useIntersectionObserverInitializer';
import GatsbyImage, { GatsbyImageProps, FluidObject } from 'gatsby-image';
import { ImageSharpFluid } from 'appGraphQLTypes';
import classnames from 'classnames';

// For performance we really want to avoid loading images in the page to early
// frontend pages have a lot of images so we add a lazy loading technique to images
const AppImage = ({
  lazy = false,
  src,
  ...props
}: React.ComponentProps<'img'> & { lazy: boolean }) => {
  const initializer = useIntersectionObserverInitializer({
    rootMargin: '1000px', // Should rather be quite big
    callback: () => {
      if (lazy) {
        console.debug('lazy image => load started', src);
      }
    },
  });

  const lazyProps =
    lazy && !initializer.isInitialized
      ? { 'data-src': src, src: undefined }
      : { src };

  return (
    <img
      {...props}
      {...lazyProps}
      ref={initializer.ref}
      className={classnames(
        props.className,
        // Convenient for debugging image lazy loading
        lazy
          ? initializer.isInitialized
            ? 'initialized'
            : 'not initialized'
          : '',
      )}
    />
  );
};

export default AppImage;

// "fix" the gatsby FluidObject by removing the required aspectRatio prop from it
// because that aspect ratio makes generated TS defs incompatible with FluidObject...
// See https://github.com/gatsbyjs/gatsby/issues/17003
type GeneratedGatsbyImageFluid = Partial<ImageSharpFluid>;
export type AppGatsbyImageFluid = FluidObject | GeneratedGatsbyImageFluid;

export type AppGatsbyImageProps = Omit<GatsbyImageProps, 'fluid'> & {
  fluid?: AppGatsbyImageFluid | AppGatsbyImageFluid[];
};

export const AppGatsbyImage = ({ fluid, ...props }: AppGatsbyImageProps) => (
  <GatsbyImage {...props} fluid={fluid as any} />
);
