import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import { SelectOption } from '../../appTypes';
import { FormikPropsSlice, getFormikInputBindProps } from './formikUtils';
import { FormError } from './formError';
import { Omit } from 'utility-types';
import {
  ShIntlMessageDefinition,
  ShTranslatable,
  useShTranslate,
} from '@shoootin/translations';
import { AppLabel } from '../appLabel';
import { AppDropdownPopper, useOnOutsidePopperClick } from '../appPoppers';

export const AppInputSelectOptionWrapper = ({
  className,
  onSelect,
  selected = false,
  children,
}: {
  className?: string;
  onSelect: () => void;
  selected: boolean;
  children: ReactNode;
}) => (
  <li
    className={classnames(className, {
      selected,
    })}
    css={{ display: 'flex', flexDirection: 'row' }}
    // Because we might hide the dropdown on inpur blur, we need to catch the user click before the dropdown hides...
    onMouseDown={() => onSelect()}
  >
    {children}
  </li>
);

export const AppInputSelectOption = <Key extends string | number = string>({
  option,
  onOptionSelected,
  selected = false,
}: {
  option: SelectOption<Key>;
  onOptionSelected: (option: SelectOption<Key>) => void;
  selected: boolean;
}) => (
  <AppInputSelectOptionWrapper
    selected={selected}
    onSelect={() => onOptionSelected(option)}
  >
    {option.icon && (
      <div
        css={{
          marginLeft: 15,
          flex: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={option.icon} css={{ width: 25, objectFit: 'contain' }} />
      </div>
    )}
    <span css={{ flex: 1 }} className={'option-label'}>
      <AppLabel label={option.label} />
    </span>
  </AppInputSelectOptionWrapper>
);

export const AppInputSelectOptionListWrapper = ({
  innerRef,
  beforeList,
  children,
}: {
  innerRef?: React.RefObject<HTMLElement>;
  beforeList?: ReactNode;
  children: ReactNode;
}) => (
  <div className="field-select-options" ref={innerRef as any}>
    {beforeList}
    <ul>{children}</ul>
  </div>
);

type AppInputSelectOptionListProps<Key extends string | number = string> = {
  options: SelectOption<Key>[];
  onOptionSelected: (option: SelectOption<Key>) => void;
  selectedKey?: Key;
  innerRef?: React.RefObject<HTMLElement>; // InnerRef because forwardRef loose generic typing
  beforeList?: ReactNode;
};

export const AppInputSelectOptionList = <Key extends string | number = string>({
  options,
  onOptionSelected,
  selectedKey,
  innerRef,
  beforeList,
}: AppInputSelectOptionListProps<Key>) => (
  <AppInputSelectOptionListWrapper innerRef={innerRef} beforeList={beforeList}>
    {options.map((option) => (
      <AppInputSelectOption
        key={option.key}
        option={option}
        onOptionSelected={onOptionSelected}
        selected={option.key === selectedKey}
      />
    ))}
  </AppInputSelectOptionListWrapper>
);

type AppInputSelectProps<Key extends string | number = string> = {
  error?: string;
  modifier?: string;
  required?: boolean;
  options: SelectOption<Key>[];
  onOptionSelected: (option: SelectOption<Key>) => void;
  placeholder?: string;
  placeholderMessage?: ShIntlMessageDefinition;
  value?: Key;
};
export const AppInputSelect = <Key extends string | number = string>({
  error,
  modifier,
  required = false,
  options,
  placeholder,
  placeholderMessage,
  value,
  onOptionSelected,
}: AppInputSelectProps<Key>) => {
  const translate = useShTranslate();
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  const selectedOption = options.find((o) => o.key === value);

  const placeholderText = placeholderMessage
    ? translate(placeholderMessage)
    : placeholder;

  const displayedPlaceholder = opened
    ? placeholderText
    : selectedOption
    ? selectedOption.label
    : placeholderText;

  const { buttonRef, popperRef } = useOnOutsidePopperClick(() =>
    setOpened(false),
  );

  return (
    <div className="field">
      <div className={classnames('field-control ', modifier)}>
        <AppDropdownPopper
          opened={opened}
          target={(targetRefHandler) => (
            <div
              ref={(ref) => {
                targetRefHandler(ref);
                buttonRef.current = ref;
              }}
              className={classnames('field-select', { active: opened })}
              tabIndex={0}
            >
              <div
                className="field-select-placeholder"
                onClick={toggleOpen}
                css={{ display: 'flex', flexDirection: 'row' }}
              >
                {selectedOption && selectedOption.icon && (
                  <div
                    css={{
                      marginRight: 15,
                      flex: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={selectedOption.icon}
                      css={{ width: 20, objectFit: 'contain' }}
                    />
                  </div>
                )}
                <div css={{ flex: 1 }}>{displayedPlaceholder}</div>
                {/*//TODO Chevron that is on ::after background in _select.less*/}
              </div>
            </div>
          )}
          content={() => (
            <AppInputSelectOptionList<Key>
              innerRef={popperRef}
              options={options}
              onOptionSelected={(option) => {
                setOpened(false);
                onOptionSelected(option);
              }}
              selectedKey={value}
            />
          )}
        />
        {placeholderMessage && (
          <ShTranslatable id={placeholderMessage.id} context="placeholder" />
        )}
        {error && <FormError error={error} />}
      </div>
    </div>
  );
};

export const AppInputSelectFormik = <
  FieldName extends string,
  Key extends string | number = string
>({
  name,
  form,
  onOptionSelected,
  ...otherInputProps
}: {
  name: FieldName;
  form: FormikPropsSlice<FieldName>;
  onOptionSelected?: (option: SelectOption<Key>) => void; // This one is now optional!
} & Omit<AppInputSelectProps<Key>, 'value' | 'error' | 'onOptionSelected'>) => {
  return (
    <AppInputSelect
      {...otherInputProps}
      {...getFormikInputBindProps(form, name)}
      onOptionSelected={(option) => {
        form.setFieldValue(name, option.key);
        onOptionSelected && onOptionSelected(option);
      }}
    />
  );
};

export default AppInputSelect;
