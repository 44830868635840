import { ShLocale } from '@shoootin/config';
import { ShIntlMessages } from '@shoootin/translations';

// Using getters to avoid early require.
// We don't need to load all the translations in memory on startup
// TODO ideally we'd like to code split those translations but how :s
// See https://github.com/gatsbyjs/gatsby/issues/13806

const translationGetters: { [key in ShLocale]: () => ShIntlMessages } = {
  //fr_FR: () => require(`../../translations/downloaded/fr_FR.json`),
  fr_FR: () => require(`@shoootin/translations/translations/downloaded/FRONT/fr_FR.json`),
  fr_BE: () => require(`@shoootin/translations/translations/downloaded/FRONT/fr_BE.json`),
  es_ES: () => require(`@shoootin/translations/translations/downloaded/FRONT/es_ES.json`),
  de_DE: () => require(`@shoootin/translations/translations/downloaded/FRONT/de_DE.json`),
  en_GB: () => require(`@shoootin/translations/translations/downloaded/FRONT/en_GB.json`),
  en_US: () => require(`@shoootin/translations/translations/downloaded/FRONT/en_US.json`),
  it_IT: () => require(`@shoootin/translations/translations/downloaded/FRONT/it_IT.json`),
  el_GR: () => require(`@shoootin/translations/translations/downloaded/FRONT/el_GR.json`),
  pt_PT: () => require(`@shoootin/translations/translations/downloaded/FRONT/pt_PT.json`),
  nl_NL: () => require(`@shoootin/translations/translations/downloaded/FRONT/nl_NL.json`),
  fr_LU: () => require(`@shoootin/translations/translations/downloaded/FRONT/fr_LU.json`),
  en_MT: () => require(`@shoootin/translations/translations/downloaded/FRONT/en_MT.json`),
};

export const getTranslationMessages = (appLocale: ShLocale): ShIntlMessages =>
  translationGetters[appLocale]();
