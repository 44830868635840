import { graphql, useStaticQuery } from 'gatsby';

export type SiteMetadata = {
  siteUrl: string;
  title: string;
  description: string;
  author: string;
  image: string;
};

const useSiteMetadata = (): SiteMetadata => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            image
          }
        }
      }
    `,
  );
  return query.site.siteMetadata;
};

export default useSiteMetadata;
