import React from 'react';
import { AppSection } from '../primitives/appSection';
import { H2, H4 } from '../primitives/appTitle';
import LetterSvg from '../components/svg/icon/letterSvg';
import ChatSvg from '../components/svg/icon/chatSvg';
import * as yup from 'yup';
import { AppInputTextFormik } from '../primitives/input/appInputText';
import { AppInputTextareaFormik } from '../primitives/input/appInputTextarea';
import { FormError } from '../primitives/input/formError';
import { ContactFormValues } from '../appAPITypes';
import { submitContactForm } from '../appAPI';
import { getFrontMessage } from '../appMessageDefinitions';
import { ShText } from '@shoootin/translations';
import { useAppFormik } from '../primitives/input/formikUtils';
import { FrontContactMessages } from './contactTranslations';
import { AppButton } from '../primitives/appButton';
import { FormikProvider } from 'formik';
import { useIntercom } from '../utils/intercomUtils';
import AppRecaptcha from '../primitives/input/appRecaptcha';

const EmptyContactForm: ContactFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  mobilePhone: '',
  message: '',
  recaptcha: '',
};

const ContactFormValidation = yup.object().shape<ContactFormValues>({
  firstName: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_firstName_RequiredError').id),
  lastName: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_lastName_RequiredError').id),
  email: yup
    .string()
    .trim()
    .email(getFrontMessage('form_userInfos_email_InvalidError').id)
    .required(getFrontMessage('form_userInfos_email_RequiredError').id),
  mobilePhone: yup
    .string()
    .trim()
    .required(getFrontMessage('form_userInfos_mobilePhone_RequiredError').id),
  message: yup
    .string()
    .trim()
    .required(getFrontMessage('form_contact_message_RequiredError').id),
  recaptcha: yup
    .string()
    .trim()
    .required(getFrontMessage('form_contact_captcha_RequiredError').id),
});

const Contact = () => {
  const { form, isSubmitSuccess } = useAppFormik({
    initialValues: EmptyContactForm,
    validationSchema: ContactFormValidation,
    submitAppForm: submitContactForm,
  });
  const intercom = useIntercom();
  return (
    <FormikProvider value={form}>
      <AppSection
        className="contact"
        header={
          <>
            <H2>
              <ShText message={FrontContactMessages.sectionTitle} />
            </H2>
            <p>
              <ShText message={FrontContactMessages.sectionSubtitle} />
            </p>
            {isSubmitSuccess && (
              <p>
                <ShText message={FrontContactMessages.sectionMessageSent} />
              </p>
            )}
          </>
        }
      >
        <>
          {!isSubmitSuccess && (
            <div className="contact-form">
              <fieldset>
                <div className="contactRow">
                  <AppInputTextFormik
                    form={form}
                    name="firstName"
                    modifier="large"
                    placeholderMessage={getFrontMessage(
                      'form_userInfos_firstName_PlaceHolder',
                    )}
                    required
                  />
                  <AppInputTextFormik
                    form={form}
                    name="lastName"
                    modifier="large"
                    placeholderMessage={getFrontMessage(
                      'form_userInfos_lastName_PlaceHolder',
                    )}
                    required
                  />
                </div>
                <div className="contactRow">
                  <AppInputTextFormik
                    form={form}
                    name="email"
                    modifier="large"
                    placeholderMessage={getFrontMessage(
                      'form_userInfos_email_PlaceHolder',
                    )}
                    required
                  />
                  <AppInputTextFormik
                    form={form}
                    name="mobilePhone"
                    modifier="large"
                    placeholderMessage={getFrontMessage(
                      'form_userInfos_mobilePhone_PlaceHolder',
                    )}
                    required
                  />
                </div>
                <div className="contactRow">
                  <AppInputTextareaFormik
                    form={form}
                    name="message"
                    placeholderMessage={getFrontMessage(
                      'form_contact_message_PlaceHolder',
                    )}
                    required
                    modifier="large"
                  />
                </div>

                <div className="contactRow">
                  <AppRecaptcha
                    onVerify={(response: string) => {
                      form.setFieldValue('recaptcha', response);
                    }}
                  />
                  {form.touched.recaptcha && form.errors.recaptcha && (
                    <FormError error={form.errors.recaptcha} />
                  )}
                </div>

                <AppButton modifier="large" onClick={() => form.submitForm()}>
                  <ShText message={getFrontMessage('common_actions_submit')} />
                </AppButton>
              </fieldset>
            </div>
          )}

          <div className="contact-boxes">
            <div>
              <div className="contact__cards">
                <div className="contact__card">
                  <H4 modifier="invert">
                    <ShText message={FrontContactMessages.contactEmail} />
                  </H4>
                  <p className="invert">
                    Shoootin
                    <br />
                    <ShText message={FrontContactMessages.contactAddress1} />
                    <br />
                    <ShText message={FrontContactMessages.contactAddress2} />
                  </p>
                  <LetterSvg />
                </div>
                <div className="contact__card">
                  <H4 modifier="invert">
                    <ShText message={FrontContactMessages.chat} />
                  </H4>
                  <p className="invert">
                    <ShText message={FrontContactMessages.chatDescription} />
                  </p>
                  <a
                    className="link invert"
                    onClick={intercom.openWithEmptyMessage}
                  >
                    <ShText message={FrontContactMessages.chatNow} />
                  </a>
                  <ChatSvg />
                </div>
              </div>
            </div>
          </div>
        </>
      </AppSection>
    </FormikProvider>
  );
};

export default Contact;
