import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { MaybeCurrentUser, UserType } from '../appTypes';
import { getCurrentUser, updateCurrentUserLocale } from '../appAPI';
import { CurrentUserStorage, SelectedAppLocaleStorage } from '../appStorage';
import { ShApiClient, ShApiUtils } from '@shoootin/api';
import {
  restartIntercom,
  updateIntercomUserSettings,
} from 'utils/intercomUtils';
import { createForBrowser } from '../utils/gatsbyUtils';
import {
  reportError,
  setAnalyticsUser,
  unsetAnalyticsUser,
} from '../appAnalytics';
import { ShLocale } from '@shoootin/config';

const initialCurrentUser = createForBrowser(() => CurrentUserStorage.get());
console.debug('CurrentUserState.initialCurrentUser', initialCurrentUser);

type CurrentUserContextValue = {
  setCurrentUser: (user: MaybeCurrentUser) => void;
  currentUser: MaybeCurrentUser;
  initialFetchDone: boolean;
};
const CurrentUserContext = React.createContext<CurrentUserContextValue>(
  null as any,
);

// TODO not satisfied with this impl, too complex for what it does
export const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, doSetCurrentUser] = React.useState<MaybeCurrentUser>(
    initialCurrentUser,
  );

  // for order page, we need to wait until we know for sure
  const [initialFetchDone, setInitialFetchDone] = React.useState<boolean>(
    false,
  );

  const setCurrentUser = (user: MaybeCurrentUser) => {
    console.debug('CurrentUserState.setCurrentUser', user);
    doSetCurrentUser(user);
    if (user) {
      updateIntercomUserSettings(user);
      setAnalyticsUser(user);
      SelectedAppLocaleStorage.set(user.appLocale);
    } else {
      unsetAnalyticsUser();
    }
  };

  // If user is not authorized, he's automatically logged out
  useEffect(() => {
    return ShApiClient.events.onUnauthorizedError.subscribe(() => {
      if (currentUser) {
        restartIntercom();

        console.info(
          'User was logged-in but api response is unauthorized: the user will be logged out',
        );
        // This is expected during logout after the back->front redirection currently
        // TODO maybe emit a toast or something if this happens during the lifetime of the app and not during launch?
        // (this may happen if user is using multiple tabs and logs out from one of the tabs)
      }
      setCurrentUser(null);
      CurrentUserStorage.set(null);
    });
  }, []);

  // On mount/startup, if user is authenticated, we try to "refresh" current user data
  // (which also check the user is still authenticated)
  // Note we can't rely on localstorage because user could be authenticated while localstorage user is not set:
  // - Due to user session being kept and switch from legacy frontend to new gatsby frontend
  // - User might have been authenticated from another place than the gatsby front (ie, admin logging as a client user through the admin interface)
  useEffect(() => {
    getCurrentUser().then(
      freshCurrentUser => {
        setInitialFetchDone(true);
        setCurrentUser(freshCurrentUser);
        CurrentUserStorage.set(freshCurrentUser);
      },
      e => {
        setInitialFetchDone(true);
        if (ShApiUtils.isApiUnauthorizedError(e)) {
          // Expected, and already handled
        } else {
          console.info('could not fetch currentUser on app startup', e.message);
        }
      },
    );
  }, []);

  const value: CurrentUserContextValue = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      initialFetchDone,
    }),
    [currentUser, initialFetchDone],
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

// This static provider means that you pass a currentUser through context to a subtree,
// but this currentUser state is not managed internally by the app
// This is mostly useful for the order checkout for which authentication is different and using a header token!
export const CheckoutCurrentUserProvider = ({
  children,
  initialCurrentUser,
}: {
  initialCurrentUser: MaybeCurrentUser;
  children: ReactNode;
}) => {
  const [currentUser, setCurrentUser] = useState<MaybeCurrentUser>(
    () => initialCurrentUser,
  );
  const value: CurrentUserContextValue = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      initialFetchDone: true,
    }),
    [currentUser],
  );
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUserContext = () => {
  const context = React.useContext(CurrentUserContext);
  if (!context) {
    throw new Error(`useCurrentUser must be used within a CurrentUserProvider`);
  }
  return context;
};

// To know if the initial user check has been done.
// Returns true after request completion (even if failure)
export const useCurrentUserInitialFetchDone = () =>
  useCurrentUserContext().initialFetchDone;

export const useCurrentUserOfAnyType = () =>
  useCurrentUserContext().currentUser;
export const useCurrentUserOfType = (userType: UserType) => {
  const currentUser = useCurrentUserOfAnyType();
  if (currentUser && currentUser.type === userType) {
    return currentUser;
  }
};
// Most often we want to check weither current user is logged as a clientUser
export const useCurrentClientUser = () => useCurrentUserOfType('CLIENT_USER');

export const useSetCurrentUser = () => useCurrentUserContext().setCurrentUser;

export const useUpdateCurrentUserLocale = () => {
  const currentUser = useCurrentUserOfAnyType();
  const setCurrentUser = useSetCurrentUser();
  return useCallback(
    (appLocale: ShLocale) => {
      if (currentUser) {
        updateCurrentUserLocale(appLocale)
          .then(u => {
            setCurrentUser(u);
          })
          .catch(reportError);
      }
    },
    [currentUser],
  );
};
