/* eslint-disable no-undef */
import { memoize } from 'lodash';

export const device = memoize(() => {
  const width = Math.min(window.innerWidth, window.outerWidth); // When using chrome debugger mobile emulation, innerWidth can be wrong :s
  return {
    isMobile: width < 769,
    isTablet: width < 992,
    clickEvent:
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof DocumentTouch)
        ? 'touchstart'
        : 'click',
  };
});

export const enhanceHeader = (el) => {
  el.height = el.offsetHeight;
  el.offset = device().isMobile ? 0 : 26;
  return el;
};

export const classes = {
  add: (el, className) => el.classList.add(className),
  remove: (el, className) => el.classList.remove(className),
  toggle: (el, className) => el.classList.toggle(className),
};

export const toArray = (value) => Array.from(value);

export const createComponents = (selector, module) =>
  toArray(document.querySelectorAll(selector)).map(module);

const checkImage = (path) =>
  new Promise((resolve) => {
    const image = new Image();
    image.onload = () => resolve({ path, status: 'ok' });
    image.onerror = () => resolve({ path, status: 'error' });
    image.src = path;
  });

export const loadImages = (paths) => Promise.all(paths.map(checkImage));

const getClassAction = (willAdd) => (willAdd > 0 ? 'add' : 'remove');

export const addOrRemoveClass = (element, willAdd, className) =>
  element.classList[getClassAction(willAdd)](className);
