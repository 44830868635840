// Values not usable on RN, but still useful to share
export const ShFonts = {
  primary: 'Montserrat, Verdana, sans-serif',
  secondary: 'League Spartan, Century Gothic, AppleGothic, sans-serif',
};

export type ShFont = keyof typeof ShFonts;

export const ShFontWeights = {
  s: 300,
  m: 400,
  l: 600,
};
export type ShFontWeight = keyof typeof ShFontWeights;

// TODO expose ShFontSizes etc...
