import React from 'react';

const ChatSvg = (props: any) => (
  <svg className="chat" height="1em" width="1em" viewBox="0 0 60 60" {...props}>
    <defs>
      <linearGradient id="prefix__a" x1="-50%" y1="-50%" x2="150%" y2="150%">
        <stop offset={0.2} stopColor="#6363fc" />
        <stop offset={0.8} stopColor="#f569c2" />
      </linearGradient>
    </defs>
    <path
      stroke="url(#prefix__a)"
      d="M22.782 36.472c3.31 4.92 9.886 8.278 17.468 8.278 2.764 0 5.392-.449 7.781-1.253 2.748 2.068 6.749 3.378 11.219 3.378-3.237 0-6.056-2.014-7.57-5C56.709 39.066 60 34.464 60 29.25c0-7.268-6.383-13.35-14.983-15.026l.002-.001C42.511 6.613 33.827 1 23.5 1 11.212 1 1.25 8.947 1.25 18.75c0 5.838 3.534 11.017 8.987 14.252-.14 4.163-3.694 7.498-8.07 7.498h.042c6.066 0 11.206-2.204 13.052-5.262A27.183 27.183 0 0 0 23.5 36.5c12.288 0 22.25-7.947 22.25-17.75 0-1.565-.254-3.082-.73-4.527"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChatSvg;
