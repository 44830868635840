import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { AppButton } from '../primitives/appButton';
import LogoSvg from '../components/svg/logoSvg';
import HamburgerSvg from '../components/svg/icon/hamburgerSvg';
import { ShText, useShIntlLocale } from '@shoootin/translations';
import {
  AppInternalLink,
  AppInternalPath,
  AppInternalPaths,
} from '../primitives/appLink';
// @ts-ignore
import { enhanceHeader } from 'helpers/helpers';
import { CurrentUser } from '../appTypes';
import { getCountryFlag } from '../appConstants';
import classnames from 'classnames';
import {
  FrontCommonMessages,
  FrontNavigationMessages,
} from '../appCommonTranslations';
import {
  useCurrentUserOfAnyType,
  useUpdateCurrentUserLocale,
} from '../state/currentUserState';
import {
  AppTooltipPopper,
  useOnOutsidePopperClick,
} from '../primitives/appPoppers';
import { userTypeHomeUrl } from 'appEnv';
import ProfileSvg from '../components/svg/icon/profile';
import useOnScroll from '../hooks/useOnScroll';
import { getFrontMessage } from '../appMessageDefinitions';
import { usePathnameRedirectForLocale } from './appContext';
import { ShLocale, ShLocales, ShLocaleConfigs } from '@shoootin/config';
import classNames from 'classnames';

const MainNavigationLinkItem = ({
  to,
  children,
}: {
  to: AppInternalPath;
  children: ReactNode;
}) => (
  <li className="header-menu__item">
    <AppInternalLink to={to} className="header-menu__link link">
      {children}
    </AppInternalLink>
  </li>
);

const getLabel = (locale: ShLocale): string =>
  ShLocaleConfigs[locale].localisedCountryName;

const FlagImage = ({ locale }: { locale: ShLocale }) => (
  <img
    src={getCountryFlag(ShLocaleConfigs[locale].country)}
    alt={`Current locale: ${locale}`}
    css={{ width: 25, objectFit: 'contain' }}
  />
);

const LocaleOption = ({
  locale,
  active,
  onClick,
}: {
  locale: ShLocale;
  active: boolean;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className={classnames('dropdown__item', {
      active,
    })}
    css={{
      cursor: 'pointer',
      width: 180, // Necessary to avoid width changes on label :hover
      display: 'flex',
      flexDirection: 'row',
      paddingRight: 5,
      paddingLeft: 5,
    }}
  >
    <div
      css={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <FlagImage locale={locale} />
    </div>
    <div
      css={{
        flex: 1,
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        paddingLeft: 10,
      }}
    >
      {getLabel(locale)}
    </div>
  </div>
);

const LocaleDropdown = () => {
  const [opened, setOpened] = useState(false);
  const { buttonRef, popperRef } = useOnOutsidePopperClick(() =>
    setOpened(false),
  );
  const locale = useShIntlLocale();
  const pathnameRedirectForLocale = usePathnameRedirectForLocale();
  const updateCurrentUserLocale = useUpdateCurrentUserLocale();
  // Due to the header sticky animation + full body scrolling,
  // it's hard to ensure the popper remains in a good place on scroll
  // Closing it is the simplest solution for now
  useOnScroll(() => {
    opened && setOpened(false);
  });

  return (
    <>
      <AppTooltipPopper
        opened={opened}
        hideIfOutOMainLayoutBody={false}
        target={(targetRefHandler) => (
          <button
            ref={(ref) => {
              targetRefHandler(ref);
              buttonRef.current = ref;
            }}
            className="dropdown__toggle"
            onClick={() => setOpened(!opened)}
          >
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 20,
                }}
              >
                <FlagImage locale={locale} />
              </div>
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: 5,
                  marginBottom: -7,
                }}
              >
                {getLabel(locale)}
              </div>
            </div>
          </button>
        )}
        content={() => (
          <div
            className="dropdown__menu"
            ref={popperRef as React.Ref<HTMLDivElement>}
          >
            {ShLocales.map((locale) => (
              <LocaleOption
                key={locale}
                active={locale === locale}
                onClick={() => {
                  pathnameRedirectForLocale(locale);
                  updateCurrentUserLocale(locale);
                  setOpened(false);
                }}
                locale={locale}
              />
            ))}
          </div>
        )}
      />
    </>
  );
};

const ProfileLink = ({ currentUser }: { currentUser: CurrentUser }) => {
  const content = (
    <span>
      <span className="header-my-account">
        <ShText message={FrontNavigationMessages.myAccount} />
      </span>
      <ProfileSvg />
    </span>
  );

  if (currentUser.type === 'CLIENT_USER') {
    return (
      <a
        className="header-menu__link link"
        href={userTypeHomeUrl(currentUser.type)}
        rel="noopener"
      >
        <span>
          <span className="header-my-account">
            <ShText message={FrontNavigationMessages.myAccount} />
          </span>
          <ProfileSvg />
        </span>
      </a>
    );
    // TODO reenable link to frontDomain/client once ready
    /*
    return (
      <AppInternalLink
        className="header-menu__link link"
        rel="noopener"
        to={AppInternalPaths.clientHome}
      >
        {content}
      </AppInternalLink>
    );
    */
  } else {
    return (
      <a
        className="header-menu__link link"
        rel="noopener"
        href={userTypeHomeUrl(currentUser.type)}
      >
        {content}
      </a>
    );
  }
};

const Navigation = ({
  headerRef,
  megaMenuOpened,
  toggleMegMenuOpened,
}: {
  headerRef?: React.MutableRefObject<HTMLDivElement | null>;
  megaMenuOpened: boolean;
  toggleMegMenuOpened: () => void;
}) => {
  const currentUser = useCurrentUserOfAnyType();

  // const location = useLocation();

  const localeHeaderRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    enhanceHeader(localeHeaderRef.current);
  }, []);

  return (
    <div
      className={classNames('header', megaMenuOpened ? 'opened' : '')}
      id="header"
      ref={(ref) => {
        localeHeaderRef.current = ref;
        if (headerRef) headerRef.current = ref;
      }}
    >
      <div className="container">
        <nav className="header-nav">
          <button
            className="header-toggle"
            aria-label="Toggle menu"
            data-target="#megamenu"
            onClick={toggleMegMenuOpened}
          >
            <HamburgerSvg />
          </button>

          <AppInternalLink
            to={AppInternalPaths.home}
            className="header-brand"
            aria-label="Home"
          >
            <LogoSvg />
          </AppInternalLink>

          <ul className="header-menu">
            <MainNavigationLinkItem to={AppInternalPaths.service('photo')}>
              <ShText message={FrontCommonMessages.photo} />
            </MainNavigationLinkItem>
            <MainNavigationLinkItem to={AppInternalPaths.service('video')}>
              <ShText message={FrontCommonMessages.video} />
            </MainNavigationLinkItem>
            <MainNavigationLinkItem to={AppInternalPaths.service('drone')}>
              <ShText message={FrontCommonMessages.drone} />
            </MainNavigationLinkItem>
            <MainNavigationLinkItem to={AppInternalPaths.service('matterport')}>
              Matterport
            </MainNavigationLinkItem>
          </ul>

          <div className="header-cta">
            <AppInternalLink to={AppInternalPaths.order}>
              <AppButton modifier="nav">
                <ShText message={getFrontMessage('common_actions_order')} />
              </AppButton>
            </AppInternalLink>
          </div>

          <div className="header-actions">
            {currentUser ? (
              <>
                <ProfileLink currentUser={currentUser} />
                {/*<a*/}
                {/*href={userTypeHomeUrl(currentUser.type)}*/}
                {/*className="header-actions__profile"*/}
                {/*>*/}
                {/*<ProfileSvg/>*/}
                {/*</a>*/}
              </>
            ) : (
              <AppInternalLink
                className="header-menu__link link __header-actions__profile"
                to={AppInternalPaths.login}
              >
                <ProfileSvg />
              </AppInternalLink>
            )}

            <span className="header-actions__divider">|</span>

            <div className="header-actions__dropdown dropdown">
              <LocaleDropdown />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
