import { ShCountry, ShCountryConfigs } from '@shoootin/config';

const getGoogleMapsCountryRestrictions = (country: ShCountry) => [
  country,
  ...ShCountryConfigs[country].extraCountryRestrictions,
];

const getAutocompleteService = () => {
  if (google?.maps?.places?.AutocompleteService) {
    return new google.maps.places.AutocompleteService();
  } else {
    throw new Error(
      'Shoootin dev: using this component requires the app to load google maps JS SDK and the places autocomplete service. See for example https://developers.google.com/maps/documentation/javascript/places#loading_the_library',
    );
  }
};

const getPlaceService = () => {
  if (google?.maps?.places?.PlacesService) {
    return new google.maps.places.PlacesService(
      // We might not really conform to Google TOS here...
      // see https://stackoverflow.com/a/14345546/82609
      document.createElement('div'),
    );
  } else {
    throw new Error(
      'Shoootin dev: using this component requires the app to load google maps JS SDK and the places autocomplete service. See for example https://developers.google.com/maps/documentation/javascript/places#loading_the_library',
    );
  }
};

export const getGoogleMapsPredictions = async ({
  text,
  country,
}: {
  text: string;
  country: ShCountry;
}): Promise<google.maps.places.AutocompletePrediction[]> => {
  const service = getAutocompleteService();
  return new Promise((resolve, reject) => {
    service.getPlacePredictions(
      {
        input: text,
        types: ['geocode'],
        componentRestrictions: {
          country: getGoogleMapsCountryRestrictions(country),
        },

        /*
        ...(position && {
          location: new google.maps.LatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
          radius: 50000,
        }),
        */
      },
      (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(result);
        } else {
          console.error('google places service', { status, result });
          reject(
            new Error(
              `Google places autocomplete service returned status=${status} for text=${text}`,
            ),
          );
        }
      },
    );
  });
};

export const getGooglePlaceDetails = async (
  placeId: string,
): Promise<google.maps.places.PlaceResult> => {
  const service = getPlaceService();
  return new Promise((resolve, reject) => {
    service.getDetails({ placeId }, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(result);
      } else {
        console.error('getPlacePredictions', status);
        reject(new Error(`getPlaceDetails status=${status}`));
      }
    });
  });
};
