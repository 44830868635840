import React, { ReactNode, RefObject } from 'react';
import classnames from 'classnames';

export const AppSection = ({
  className,
  id,
  children,
  header,
  sectionRef,
}: {
  className?: string;
  id?: string;
  header?: ReactNode;
  children?: ReactNode;
  sectionRef?: RefObject<HTMLDivElement>;
}) => (
  <div ref={sectionRef} className={classnames('section', className)} id={id}>
    <div className="container">
      {header && <header className="section__header">{header}</header>}
      {children}
    </div>
  </div>
);

export default AppSection;
