import React from 'react';
import { Label } from '../appTypes';
import { isString } from 'lodash';

import { ShText } from '@shoootin/translations';

export const AppLabel = ({ label }: { label: Label }) => {
  if (isString(label)) {
    return <>{label}</>;
  } else {
    return <ShText message={label} />;
  }
};
