import {
  ShClientType,
  ShClientTypes,
  ShClientTypesConfigs,
  ShClientUserRole,
  ShCompanies,
  ShCompany,
  ShCompanyConfigs,
  ShCountries,
  ShCountry,
  ShCountryConfigs,
  ShCurrencies,
  ShCurrency,
  ShCustomOrder,
  ShCustomOrders,
  ShCustomOrdersConfig,
  ShLanguageLocale,
  ShLanguageLocaleConfigs,
  ShLanguageLocales,
  ShLocale,
  ShLocaleConfigs,
  ShLocales,
  ShOfferGroup,
  ShOfferGroups,
  ShTimeZone,
  ShTimeZones,
} from './ConfigurationGenerated';
import { ShClientUserRolesWithoutOwner } from './ConfigurationHardcoded';

export type ShTranslatableLocale = ShLanguageLocale | ShLocale;

export type Options<O> = {
  value: O;
  label?: string;
};

export const ShCountryOptions: Options<ShCountry>[] = ShCountries.map(
  (value) => ({
    value,
    label: ShCountryConfigs[value].label,
  }),
);

export const ShCurrencyOptions: Options<ShCurrency>[] = ShCurrencies.map(
  (value) => ({
    value,
    label: value as string, // TODO we should probably translate each currency?
  }),
);

export const ShCompanyOptions: Options<ShCompany>[] = ShCompanies.map(
  (value) => ({
    value,
    label: ShCompanyConfigs[value].companyName as string,
  }),
);

export const ShClientTypeOptions: Options<ShClientType>[] = ShClientTypes.map(
  (value) => ({
    value,
    label: ShClientTypesConfigs[value].value,
  }),
);

export const ShLocaleOptions: Options<ShLocale>[] = ShLocales.map((value) => ({
  value,
  label: ShLocaleConfigs[value].label,
}));

export const ShTimeZoneOptions: Options<ShTimeZone>[] = ShTimeZones.map(
  (value) => ({
    value,
    label: value,
  }),
);

export const ShClientUserRoleWithoutOwnerOptions: Options<
  ShClientUserRole
>[] = ShClientUserRolesWithoutOwner.map((value) => ({
  value,
  label: value,
}));

export const ShCountriesForPhotographer = ShCountries.filter(
  (country) => ShCountryConfigs[country].forPhotographer,
);
export const ShCountriesForOrder = ShCountries.filter(
  (country) => ShCountryConfigs[country].forOrder,
);

export const ShCountriesWithZones: ShCountry[] = ['GB', 'FR', 'BE', 'US', 'BE'];

export const ShLanguageLocalesOptions: Options<
  ShLanguageLocale
>[] = ShLanguageLocales.map((value) => ({
  value,
  label: ShLanguageLocaleConfigs[value].value,
}));

export const ShOfferGroupOptions: Options<ShOfferGroup>[] = ShOfferGroups.map(
  (value) => ({
    value,
    label: value as string, // TODO we should probably translate each currency?
  }),
);

export const ShClientCustomOrderOptions: Options<
  ShCustomOrder
>[] = ShCustomOrders.map((value) => ({
  value,
  label: ShCustomOrdersConfig[value].key,
}));
