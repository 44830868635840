import { graphql } from 'gatsby';
import { AppGatsbyImageFluid } from './primitives/appImage';

export const AppFluidHeroImageFragment = graphql`
  fragment AppFluidHeroImageFragment on File {
    id
    name
    childImageSharp {
      fluid(
        maxWidth: 1000 # gatsby will x2 anyway, and we want to limit to 2000px max
        quality: 85
        srcSetBreakpoints: [
          400
          500
          600
          800
          1000
          1200
          1400
          1600
          1800
          2000
        ]
      ) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;

type AppFluidImageFile = {
  id?: string;
  name?: string;
  childImageSharp?: any; // TODO better typing?
};
// Helper to extract fluid image from file object
export const getFluidImage = (
  file?: AppFluidImageFile,
  errorMessage?: string,
): AppGatsbyImageFluid => {
  if (file && file.childImageSharp && file.childImageSharp.fluid) {
    return file.childImageSharp.fluid!;
  } else {
    console.debug('file', file);
    throw new Error(
      `getFluidImage() failure. File[${
        file ? file.name : 'NotFound?]'
      } [errorMessage=${errorMessage}]`,
    );
  }
};

type AppFluidImageEdge = { node: AppFluidImageFile };
// Helper to extract all fluid images from a gatsby query data object
export const getFluidImages = <
  Key extends string,
  Data extends { [key in Key]: { edges: AppFluidImageEdge[] } }
>(
  data: Data,
  key: Key,
): AppGatsbyImageFluid[] => {
  const imageEdges: AppFluidImageEdge[] = data[key].edges;
  const fluidImages = imageEdges.map((e, i) =>
    getFluidImage(e.node, `getFluidImages for index ${i}`),
  );
  return fluidImages;
};

// Sometimes it's convenient to override the "sizes" attribute generated by Gatsby
// Particularly when we need to display the same image twice on the same page, we can use the same gatsby image's srcset,
// but just ensure the "sizes" attribute is appropriate for the usecase,
// otherwise gatsby sizes will use the maxWidth of the query (which may not be good for all usecases)
const maxWidthSizes = (maxWidth: number) =>
  `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;

export const forceImageMaxWidth = (
  image: AppGatsbyImageFluid,
  maxWidth: number,
): AppGatsbyImageFluid => ({
  ...image,
  sizes: maxWidthSizes(maxWidth),
  // TODO: we should probably filter the output srcset too!
  // gatsby-image generated dom can be quite verbose if there are many breakpoints
});
