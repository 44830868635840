const ShMobileOnly = `@media (max-width: ${992}px)`;
const ShDesktopsOnly = `@media (min-width: ${992}px)`;
const ShLargeDesktop = `@media (min-width: ${1200}px)`;

const ShMobileOnlyDynamic = () =>
  window.matchMedia(`(max-width: 992px)`).matches;
const ShDesktopsOnlyDynamic = () =>
  window.matchMedia(`(min-width: 992px)`).matches;
const ShLargeDesktopDynamic = () =>
  window.matchMedia(`(min-width: 1200px)`).matches;

export const ShMediaQueries = {
  ShMobileOnly,
  ShDesktopsOnly,
  ShLargeDesktop,
};

export const ShMediaQueriesDynamic = {
  ShMobileOnlyDynamic,
  ShDesktopsOnlyDynamic,
  ShLargeDesktopDynamic,
};

//TODO don't use this at it sucks
//maybe needed later
export const ShScreenSizes = {
  mobile: 375,
  tablet: 768,
  desktop: 1024,
  desktopXl: 1440,
  desktop4k: 2560,
} as const;

export type ShScreenSize = keyof typeof ShScreenSizes;

export const ShMediaQueriesDoNotUse = {
  mobile: {
    only: `@media only screen and (max-width: ${ShScreenSizes.mobile}px)`,
  },
  tablet: {
    smaller: `@media only screen and (max-width: ${ShScreenSizes.tablet}px)`,
    only: `@media only screen and (min-width: ${
      ShScreenSizes.mobile + 1
    }px) and (max-width: ${ShScreenSizes.tablet}px)`,
    bigger: `@media only screen and (min-width: ${ShScreenSizes.mobile + 1}px)`,
  },
  desktop: {
    smaller: `@media only screen and (max-width: ${ShScreenSizes.desktop}px)`,
    only: `@media only screen and (min-width: ${
      ShScreenSizes.tablet + 1
    }px) and (max-width: ${ShScreenSizes.desktop}px)`,
    bigger: `@media only screen and (min-width: ${ShScreenSizes.tablet + 1}px)`,
  },
  desktopXl: {
    smaller: `@media only screen and (max-width: ${ShScreenSizes.desktopXl}px)`,
    only: `@media only screen and (min-width: ${
      ShScreenSizes.desktop + 1
    }px) and (max-width: ${ShScreenSizes.desktopXl}px)`,
    bigger: `@media only screen and (min-width: ${
      ShScreenSizes.desktop + 1
    }px)`,
  },
  desktop4k: {
    only: `@media only screen and (min-width: ${
      ShScreenSizes.desktopXl + 1
    }px)`,
  },
} as const;
