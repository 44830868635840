import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import Navigation from './navigation';
import Contact from './contact';
import Footer from './footer';
import Megamenu from './megamenu';
import useComponentSize from '@rehooks/component-size';
import { ShGridProvider } from '@shoootin/components-web';

// @ts-ignore
import { initLegacyGlobalJS } from 'legacyJs/localFront';
import AppSEO, { AppSEOTranslateMode, SEOPageName } from '../appSEO';
import CookieConsent from 'react-cookie-consent';
import { ShText, useShTranslate } from '@shoootin/translations';
import { FrontCommonMessages } from '../appCommonTranslations';
import { isDev } from 'appEnv';

const HeaderSizeContext = React.createContext<{
  width: number | undefined;
  height: number | undefined;
}>({ width: 0, height: 0 });

export const useHeaderSize = () => useContext(HeaderSizeContext);
const debugRGPD = false;

export const MainLayout = ({
  pageName,
  className,
  children,
  showHeader = true,
  showFooter = true,
  showContact = true,
}: {
  pageName?: SEOPageName;
  className?: string;
  children?: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  showContact?: boolean;
}) => {
  // This effect should be here, because sometimes the main layout is unmounted/remounted,
  // and we need to ensure the effect is reset
  // TODO We should probably fix the unmount/remout of MainLayout, particularly if it's used in all pages
  // but it's not as easy because custom classname is provided differently to the layout for each page
  useEffect(initLegacyGlobalJS, []);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const headerSize = useComponentSize(headerRef);
  const translate = useShTranslate();

  let scrollLockRecord = 0;
  const isLocked = () => document.body.classList.contains('lock');
  const lockBody = () => {
    scrollLockRecord = window.pageYOffset;
    document.body.style.top = `-${scrollLockRecord}px`;
    document.body.classList.add('lock');
  };

  const unlockBody = () => {
    document.body.classList.remove('lock');
    // @ts-ignore
    document.body.style.top = null;
    window.scrollTo(0, scrollLockRecord);
  };

  const [megaMenuOpened, setMegaMenuOpened] = useState<boolean>(false);

  const toggleMegMenuOpened = () => {
    if (isLocked()) {
      unlockBody();
    } else {
      lockBody();
    }
    setMegaMenuOpened(!megaMenuOpened);
  };

  useEffect(() => {
    return () => {
      if (isLocked()) {
        unlockBody();
      }
    };
  }, [megaMenuOpened]);

  return (
    <ShGridProvider>
      <HeaderSizeContext.Provider value={headerSize}>
        {pageName && <AppSEO pageName={pageName} />}
        <div className={className}>
          {showHeader && (
            <>
              <Navigation
                headerRef={headerRef}
                megaMenuOpened={megaMenuOpened}
                toggleMegMenuOpened={toggleMegMenuOpened}
              />
              <Megamenu megaMenuOpened={megaMenuOpened} />
            </>
          )}
          <main className="main" id="main">
            {children}
          </main>
          {showContact && <Contact />}
          {showFooter && <Footer />}
          {pageName && <AppSEOTranslateMode pageName={pageName} />}
          <CookieConsent
            debug={debugRGPD && isDev}
            location="bottom"
            style={{ textAlign: 'center', background: 'black' }}
            buttonStyle={{
              backgroundColor: '#5472ff',
              color: 'white',
              marginRight: 100,
            }}
            buttonText={translate(FrontCommonMessages.rgpdAccept)}
          >
            <ShText message={FrontCommonMessages.rgpd} />
          </CookieConsent>
        </div>
      </HeaderSizeContext.Provider>
    </ShGridProvider>
  );
};
