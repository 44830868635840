import Bowser from 'bowser';
import { isBrowser, isServer } from './utils/gatsbyUtils';
import React, { useEffect, useState } from 'react';
import { ShModal } from '@shoootin/components-web';

type BrowserInfo = Bowser.Parser.BrowserDetails;

export const getUnsupportedBrowser = (): BrowserInfo | undefined => {
  if (isServer()) {
    throw new Error(
      'isValidBrowser should not be called during Gatsby SSR phase',
    );
  }
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo: BrowserInfo = browser.getBrowser();

  // See doc of https://github.com/lancedikson/bowser
  const isInvalid = browser.satisfies({
    // chrome: '>1', // for testing the modale
    'internet explorer': '<11',
  });
  console.debug('browserInfo', { browserInfo, isInvalid });

  if (isInvalid) {
    console.warn('invalid browser detected', browserInfo);
    return browserInfo;
  }
};

// Fallback system to tell the user to upgrade browser
// Because if render crash due to bad browser, the app might not even be able
// to show the AppBrowserSupportModal modal
// TODO what if React error boundary does not even work?
export const showUnsupportedBrowserAlertFallbackIfNeeded = () => {
  const unsupportedBrowser = getUnsupportedBrowser();
  if (unsupportedBrowser) {
    alert(
      `Your browser is not supported, please upgrade. ${unsupportedBrowser.name}/${unsupportedBrowser.version}`,
    );
  }
};

export const AppBrowserSupportModal = () => {
  const [unsupportedBrowser, setUnsupportedBrowser] = useState<BrowserInfo>();

  useEffect(() => {
    if (isBrowser()) {
      setUnsupportedBrowser(getUnsupportedBrowser());
    }
  }, []);

  if (!unsupportedBrowser) return null;

  return (
    <>
      <ShModal
        onClose={() => setUnsupportedBrowser(undefined)}
        title={<>Navigateur non supporté</>}
        content={
          <div>
            <div>
              Votre navigateur n'est pas supporté, nous vous conseillons de
              mettre à jour votre navigateur vers une version plus récente.
            </div>
            <div css={{ marginTop: 20 }}>
              <div>
                <strong>Nom:</strong>{' '}
                {unsupportedBrowser && unsupportedBrowser.name}
              </div>
              <div>
                <strong>Version:</strong>{' '}
                {unsupportedBrowser && unsupportedBrowser.version}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
