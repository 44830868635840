import React, { ReactNode, useEffect, useMemo } from 'react';
import { useState } from 'react';
import useGetter from './useGetter';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

type InitializerRef = ReturnType<typeof useInView>[0];

type Params = {
  callback?: () => void;
} & IntersectionOptions;

type Result = {
  marker: ReactNode;
  ref: InitializerRef;
  isInitialized: boolean;
};

// Permit to lazy init some js code if the marker becomes visible
const useIntersectionObserverInitializer = ({
  callback,
  ...props
}: Params = {}): Result => {
  const [ref, inView] = useInView(props);

  const [isInitialized, setInitialized] = useState<boolean>(false);
  const getCallback = useGetter(callback);

  useEffect(() => {
    if (inView && !isInitialized) {
      const cb = getCallback();
      cb && cb();
      setInitialized(true);
      console.debug('useIntersectionObserverInitializer initialized');
    }
  }, [inView]);

  const marker = useMemo(
    () => (
      <div
        ref={ref}
        className={
          // Useful for debugging
          isInitialized ? 'initialized' : 'not-initialized'
        }
      />
    ),
    [isInitialized],
  );

  return {
    // You should either:
    // - place the marker in the dom (as an observable threshold)
    // - assign the ref to an existing dom element that will be observed
    marker,
    ref,
    // boolean
    isInitialized,
  };
};

export default useIntersectionObserverInitializer;
