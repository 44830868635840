import { SelectOption } from './appTypes';
import { defineMessages } from 'react-intl';

import { SubType } from './utils/typescriptUtils';
import { SEOPageName } from './appSEO';
import { AppGatsbyImageFluid } from './primitives/appImage';
import { UsecaseData, UsecaseName } from './appConstantsUsecase';
import {
  ShCountriesForOrder,
  ShCountriesForPhotographer,
  ShCountry,
  ShCountryConfigs,
  ShCurrency,
  ShJoinSpecialties,
  ShLocale,
  ShLocaleConfigs,
  ShLocales,
  ShShootingType,
} from '@shoootin/config';
import { VideoName } from './appConstantsVideos';
import { ShIntlMessageDefinition } from '@shoootin/translations';

// Don't replace by ShBackendAssets
// if we do, images won't be loaded via gatsby
export const getCountryFlag = (country: ShCountry): string => {
  try {
    // eslint-disable-next-line import/no-dynamic-require
    return require(`images/flags/used/${country}.png`);
  } catch (e) {
    throw new Error(
      `${country} Country flag image not found at images/flags/used/${country}.png`,
    );
  }
};

// TODO replace megamenu with ShInputSelect
export const AppLocaleOptions: SelectOption<ShLocale>[] = ShLocales.map(
  (appLocale) => ({
    key: appLocale,
    label: ShLocaleConfigs[appLocale].localisedCountryName,
    icon: getCountryFlag(ShLocaleConfigs[appLocale].country),
  }),
);

export const getCountryRestrictions = (country: ShCountry) => [
  country,
  ...ShCountryConfigs[country].extraCountryRestrictions,
];

export const CountryOptionsForOrder: SelectOption<
  ShCountry
>[] = ShCountriesForOrder.map((countryCode) => ({
  key: countryCode,
  label: ShCountryConfigs[countryCode].label,
  icon: getCountryFlag(countryCode),
}));

export const CountryOptionsForPhotographer: SelectOption<
  ShCountry
>[] = ShCountriesForPhotographer.map((countryCode) => ({
  key: countryCode,
  label: ShCountryConfigs[countryCode].label,
  icon: getCountryFlag(countryCode),
}));

export const PrestationNames = [
  'photo',
  'video',
  'matterport',
  'drone',
] as const;
export type PrestationName = typeof PrestationNames[number];

export type JoinSpecialty = typeof ShJoinSpecialties[number];

const JoinSpecialtyPrefix = 'front_joinSpecialty';

export const JoinSpecialtyLabels: Record<
  JoinSpecialty,
  ShIntlMessageDefinition
> = defineMessages({
  portrait: {
    id: `${JoinSpecialtyPrefix}_portrait`,
    defaultMessage: 'Portrait',
  },
  wedding: {
    id: `${JoinSpecialtyPrefix}_wedding`,
    defaultMessage: 'Wedding',
  },
  architecture: {
    id: `${JoinSpecialtyPrefix}_architecture`,
    defaultMessage: 'Architecture',
  },
  culinary: {
    id: `${JoinSpecialtyPrefix}_culinary`,
    defaultMessage: 'Culinaire',
  },
  immo: {
    id: `${JoinSpecialtyPrefix}_immo`,
    defaultMessage: 'Immobilier',
  },
  sport: {
    id: `${JoinSpecialtyPrefix}_sport`,
    defaultMessage: 'Sport',
  },
});

export const Professions: ProfessionShootingType[] = [
  'residentialRealEstate',
  'commercialRealEstate',
  'architect',
  'hotel',
  'restaurant',
  'retail',
];

export type ProfessionGalleryImages = {
  [P in ProfessionShootingType]: any;
};

export const ProfessionImagesEmpty: ProfessionGalleryImages = {
  residentialRealEstate: {},
  commercialRealEstate: {},
  architect: {},
  hotel: {},
  restaurant: {},
  retail: {},
};

// Not all shooting types have a profession page (like construction/event/other, don't have dedicated front page)
export type ProfessionShootingType = SubType<
  ShShootingType,
  | 'residentialRealEstate'
  | 'commercialRealEstate'
  | 'architect'
  | 'hotel'
  | 'restaurant'
  | 'retail'
>;

export type ProfessionShootingTypeLocalized =
  | ProfessionShootingType
  | 'residentialRealEstate_US'
  | 'commercialRealEstate_US';

export type ClientLogoName =
  | 'accor'
  | 'balladins'
  | 'barnes'
  | 'belleval'
  | 'cbre'
  | 'cushman'
  | 'deskeo'
  | 'jll'
  | 'jod'
  | 'bi'
  | 'kretz'
  | 'mamiecocotte'
  | 'relais_chateau'
  | 's_d_architecture'
  | 'sothebys'
  | 'stephane_plaza'
  | 'transition_interior_design'
  | 'toll-brothers'
  | 'brookfield'
  | 'newmark'
  | 'avison-young'
  | 'thor-equities'
  | 'compass'
  | 'corcoran'
  | 'oxford-property-group'
  | 'bold-new-york'
  | 'century21metropolitan'
  | 'savills'
  | 'radisson'
  | 'bestwestern'
  | 'knightvest'
  | 'greystar';

// Some static data about usecases, that we need to access in many places
type ProfessionConstantLogos = { defaultLogos: ClientLogoName[] } & {
  [key in ShLocale]?: ClientLogoName[];
};
type ProfessionVideos = { defaultVideo: VideoName } & {
  [key in ShLocale]?: VideoName;
};

export const getVideoOfLocale = (
  profession: ProfessionShootingType,
  locale: ShLocale,
): VideoName => {
  return (
    ProfessionData[profession].video[locale] ||
    ProfessionData[profession].video.defaultVideo
  );
};

export type ProfessionConstantData = {
  pageName: SEOPageName;
  professionShootingType: ProfessionShootingType;
  mainUsecases: UsecaseName[];
  logos: ProfessionConstantLogos;
  otherProfessions: ProfessionShootingType[];
  scanId: string;
  video: ProfessionVideos;
};

export type ProfessionPrice = {
  profession: ProfessionShootingType;
  price?: {
    amount: number;
    currency: ShCurrency;
  };
};

export const getMainProfessionUsecaseOfLocale = (
  usecases: UsecaseName[],
  locale: ShLocale,
): UsecaseName => {
  return usecases.filter(
    (usecase) =>
      !UsecaseData[usecase].locales ||
      UsecaseData[usecase].locales!.includes(locale),
  )[0];
};

export const ProfessionData: {
  [key in ProfessionShootingType]: ProfessionConstantData;
} = {
  architect: {
    pageName: 'metier_architect',
    professionShootingType: 'architect',
    mainUsecases: ['transitionid', 'convini'],

    logos: {
      defaultLogos: ['jod', 's_d_architecture', 'transition_interior_design'],
    },
    otherProfessions: [
      'hotel',
      'restaurant',
      'commercialRealEstate',
      'residentialRealEstate',
    ],
    scanId: 'https://my.matterport.com/show/?m=iZaeggVMo3K&title=0',
    video: {
      defaultVideo: 'convini',
    },
  },
  commercialRealEstate: {
    pageName: 'metier_commercial',
    professionShootingType: 'commercialRealEstate',
    mainUsecases: ['deskeo'],

    logos: {
      defaultLogos: ['jll', 'cbre', 'cushman'],
      en_US: ['brookfield', 'cbre', 'newmark'],
    },
    otherProfessions: ['hotel', 'architect', 'retail', 'residentialRealEstate'],
    scanId: 'https://my.matterport.com/show/?m=mxoMgBiMAYb&title=0',
    video: {
      defaultVideo: 'deskeo',
      en_US: 'newmark',
    },
  },
  hotel: {
    pageName: 'metier_hotel',
    professionShootingType: 'hotel',
    mainUsecases: ['belleval'],

    logos: {
      defaultLogos: ['belleval', 'accor', 'relais_chateau'],
      en_US: ['accor', 'bestwestern', 'radisson'],
    },
    otherProfessions: [
      'architect',
      'commercialRealEstate',
      'restaurant',
      'residentialRealEstate',
    ],
    scanId: 'https://my.matterport.com/show/?m=bx7bdDuRyQA&title=0',
    video: {
      defaultVideo: 'belleval',
    },
  },
  residentialRealEstate: {
    pageName: 'metier_residential',
    professionShootingType: 'residentialRealEstate',
    mainUsecases: ['tollbrothers', 'kretz'],

    logos: {
      defaultLogos: ['barnes', 'sothebys', 'stephane_plaza'],
      en_US: ['compass', 'toll-brothers', 'sothebys'],
    },
    otherProfessions: [
      'hotel',
      'architect',
      'restaurant',
      'commercialRealEstate',
    ],
    scanId: 'https://my.matterport.com/show/?m=Udy7P6ytiW7&title=0',
    video: {
      defaultVideo: 'kretz',
    },
  },
  restaurant: {
    pageName: 'metier_restaurant',
    professionShootingType: 'restaurant',
    mainUsecases: ['mom', 'breathe'],

    logos: { defaultLogos: ['mamiecocotte', 'relais_chateau'] },
    otherProfessions: [
      'hotel',
      'architect',
      'residentialRealEstate',
      'commercialRealEstate',
    ],
    scanId: 'https://my.matterport.com/show/?m=pJKmNrfZy8B&title=0',
    video: {
      defaultVideo: 'mom',
    },
  },
  retail: {
    pageName: 'metier_retail',
    professionShootingType: 'retail',
    mainUsecases: ['bycharlot', 'belleval'],
    logos: { defaultLogos: [] },
    otherProfessions: [
      'hotel',
      'architect',
      'residentialRealEstate',
      'commercialRealEstate',
    ],
    scanId: 'https://my.matterport.com/show/?m=kvaSdMZoiP2&title=0',
    video: {
      defaultVideo: 'bycharlot',
    },
  },
};

export const twitterUrl = 'https://twitter.com/shoootin';
export const instagramUrl = 'https://www.instagram.com/shoootin_photographer/';
export const linkedInUrl = 'https://www.linkedin.com/company/shoootin';
export const facebookUrl = 'https://www.facebook.com/shoootin.photo/';

export type AppVideo = { videoImg: AppGatsbyImageFluid; videoUrl: string };
export type AppShootingProjectManager = {
  managerImg: AppGatsbyImageFluid;
  managerName: string;
};
