import { MaybeCurrentUser } from './appTypes';
import { isNil } from 'lodash';
import { ShLocale } from '@shoootin/config';

const debug = false;

// TODO for safety we should verify that the parsed object match the expected shape
// and maybe handle storage migration strategies etc...
// We must pay attention to storage breaking changes...
const readJson = <T>(key: string): T | null => {
  try {
    const str = window.localStorage.getItem(key);
    debug && console.debug('storage read', key, str);
    return str ? (JSON.parse(str) as T) : null;
  } catch (e) {
    console.warn('Unable to get storage key=' + key, e);
    try {
      window.localStorage.removeItem(key); // Erase the bad key
    } catch (e) {
      console.error(e);
    }
    return null;
  }
};

const writeJson = <T>(key: string, t: T) => {
  try {
    if (isNil(t)) {
      window.localStorage.removeItem(key);
      debug && console.debug('storage remove', key);
    } else {
      const value = JSON.stringify(t);
      window.localStorage.setItem(key, value);
      debug &&
        console.debug(
          'storage write',
          key,
          window.localStorage.getItem(key),
          JSON.parse(window.localStorage.getItem(key) || ''),
        );
    }
  } catch (e) {
    console.warn('Unable to write storage key=' + key, e);
  }
};

type Storage<T> = {
  key: string;
  get: () => T | null;
  set: (t: T) => void;
};

const createStorage = <T extends object | string | null | undefined>(
  key: string,
): Storage<T> => {
  return {
    key,
    get: () => readJson(key),
    set: (t: T) => writeJson(key, t),
  };
};

// We need to store current user because on reload we want to have some user data immedialely available
// to render correct layout: we can't wait for a "getCurrentUser()" request
// This is not a reliable way to ensure user is authenticated, use carefully
export const CurrentUserStorage = createStorage<MaybeCurrentUser>(
  'current-user',
);

// The locale the user has explicitly selected
// We'll generally redirect him automatically to this locale
// if he opens a page from another locale
export const SelectedAppLocaleStorage = createStorage<ShLocale>(
  'selected-app-locale',
);
