import React, { ReactNode, useEffect, useRef } from 'react';
import { memoize } from 'lodash';

export const ApplyMountEffect = ({
  children,
  index = 0,
  effect,
}: {
  children: (ref: React.Ref<any>) => any; // TODO typing!
  // children: ((ref: React.Ref<any>) => ReactNode | React.ReactElement);
  index?: number;
  effect: (el: any, index: number) => void;
}) => {
  const ref = useRef(null);
  useEffect(() => {
    effect(ref.current, index);
  }, []);
  return children(ref);
};

// Require scrollreveal lazily because it fails on Node/SSR/static Gatsby rendering
const getScrollReveal = memoize(() => require('scrollreveal').default());

export const cardRevealEffect = (
  el: any,
  index: number = 0,
  options?: scrollReveal.ScrollRevealObjectOptions,
) =>
  getScrollReveal().reveal(el, {
    origin: 'bottom',
    distance: '10px',
    duration: 600,
    delay: 100 * index,
    viewFactor: 0.25,
    ...options,
  });

export const blockRevealEffect = (
  el: any,
  index: number = 0,
  options?: scrollReveal.ScrollRevealObjectOptions,
) =>
  getScrollReveal().reveal(el, {
    origin: 'bottom',
    distance: '30px',
    duration: 1200,
    viewFactor: 0.25,
    ...options,
  });
