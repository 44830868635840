import { defineMessages } from 'react-intl';

const prefix = 'front_megamenu';

export const FrontMegaMenuMessages = defineMessages({
  cta: {
    id: `${prefix}_cta`,
    defaultMessage:
      'Les photographes Shoootin forment une équipe dynamique de professionnels sélectionnés sur le volet.',
  },
  joinUs: {
    id: `${prefix}_joinUs`,
    defaultMessage: 'Rejoignez nous',
  },
  photographer: {
    id: `${prefix}_photographer`,
    defaultMessage: 'Vous êtes photographe ?',
  },
  title: {
    id: `${prefix}_title`,
    defaultMessage: 'À propos de nous',
  },
  pLeft1: {
    id: `${prefix}_pLeft1`,
    defaultMessage: 'A propos de nous pLeft1',
  },
  pLeft2: {
    id: `${prefix}_pLeft2`,
    defaultMessage: 'A propos de nous pLeft2',
  },
  pRight1: {
    id: `${prefix}_pRight1`,
    defaultMessage: 'A propos de nous pRight1',
  },
  pRight2: {
    id: `${prefix}_pRight2`,
    defaultMessage: 'A propos de nous pRight2',
  },
  more: {
    id: `${prefix}_more`,
    defaultMessage: 'En savoir plus',
  },
});
