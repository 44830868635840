import { ClientLogoName } from '../appConstants';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import useClientLogoImages from '../queries/useClientLogoImages';
import { AppSection } from '../primitives/appSection';
import { H2 } from '../primitives/appTitle';
import { FrontAboutMessages } from '../page/about/aboutPageTranslations';
import { ApplyMountEffect, cardRevealEffect } from '../helpers/reveals';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../primitives/appImage';
import { forceImageMaxWidth } from '../appGatsbyImageUtils';
import React, { useState } from 'react';
import { FrontHomeMessages } from 'page/home/homePageTranslations';
import ReactPlayer from 'react-player';
import { ShColors, ShFonts, ShMediaQueries } from '@shoootin/design-tokens';
import useVideoTestimonials from '../queries/useVideoTestimonials';
import CoverVideo from './coverVideo';
import {
  VideoTestimonialConstantData,
  VideoTestimonialData,
  VideoTestimonialName,
} from '../appConstantsVideos';
import { ShModal } from '@shoootin/components-web';

const otherLogosForAboutUsPage: ClientLogoName[] = [
  'jll',
  'cbre',
  'cushman',
  'bi',
  'deskeo',
  'accor',
  'barnes',
  'sothebys',
  'belleval',
  'jod',
  'kretz',
  'mamiecocotte',
  'relais_chateau',
  's_d_architecture',
  'stephane_plaza',
  // 'transition_interior_design',
];

const usLogosForAboutUsPage: ClientLogoName[] = [
  'jll',
  'cbre',
  'cushman',
  'sothebys',
  'toll-brothers',
  'brookfield',
  'newmark',
  'avison-young',
  // 'thor-equities',
  'compass',
  'corcoran',
  'greystar',
  'bold-new-york',
  'century21metropolitan',
  'savills',
  'knightvest',
];
// - CBRE, JLL, Sotheby's, Barnes, Bouygues immo (j'ai pas le logo), Accor, Kretz (nouveau logo), Cushman, Relais et chateaux, Stéphane Plaza.
const otherLogosForHomePage: ClientLogoName[] = [
  'cbre',
  'jll',
  'sothebys',
  'barnes',
  'bi',
  'accor',
  'kretz',
  'cushman',
  'relais_chateau',
  'stephane_plaza',
];
// Logo dans cet ordre: CBRE, JLL, Sotheby's, Compass, Brookfield, Newmark, Toll Brothers, Corcoran, Cushman, Thor Equities.
const usLogosForHomePage: ClientLogoName[] = [
  'cbre',
  'jll',
  'sothebys',
  'compass',
  'brookfield',
  'newmark',
  'toll-brothers',
  'corcoran',
  'cushman',
  'avison-young',
  // 'avison-young',
  // 'oxford-property-group',
  // 'bold-new-york',
  // 'century21metropolitan',
  // 'savills',
];
const LogosSection = ({
  logoImages,
  withRevealEffect = true,
}: {
  logoImages: AppGatsbyImageFluid[];
  withRevealEffect?: boolean;
}) => {
  return (
    <ul className="about-references">
      {logoImages.map((image, i) =>
        withRevealEffect ? (
          <ApplyMountEffect key={i} index={i} effect={cardRevealEffect}>
            {(ref) => (
              <li key={i} ref={ref}>
                <AppGatsbyImage fluid={forceImageMaxWidth(image, 200)} />
              </li>
            )}
          </ApplyMountEffect>
        ) : (
          <li key={i}>
            <AppGatsbyImage fluid={forceImageMaxWidth(image, 200)} />
          </li>
        ),
      )}
    </ul>
  );
};

const VideoTestimonialModalContent = ({
  videoData,
}: {
  videoData: VideoTestimonialConstantData;
}) => {
  return (
    <div>
      <div
        css={{
          position: 'relative',
          width: '100%',
          paddingBottom: '56.25%',
          background: '#000',
          marginBottom: 15,
          marginTop: 15,
        }}
      >
        <div>
          <ReactPlayer
            url={videoData.videoUrl}
            playing={true}
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
            config={{
              youtube: {
                playerVars: {
                  showinfo: 0,
                  controls: 1,
                  modestbranding: 1,
                  rel: 0,
                  color: ShColors.base,
                },
              },
            }}
          />
        </div>
      </div>
      <div
        css={{
          color: ShColors.white,
          fontSize: 18,
          [ShMediaQueries.ShMobileOnly]: {
            fontSize: 14,
          },
        }}
      >
        <div css={{ fontStyle: ShFonts.secondary, marginBottom: 10 }}>
          <div>
            <strong>{videoData.clientName}</strong>
            <span> {videoData.clientRole}</span>
          </div>
        </div>
        <div>
          <strong>“{videoData.clientQuote}”</strong>
        </div>
      </div>
    </div>
  );
};

const VideoTestimonial = ({
  videoImg,
  data,
  onClick,
}: {
  videoImg: AppGatsbyImageFluid;
  data: VideoTestimonialConstantData;
  onClick: () => void;
}) => {
  return (
    <div key={data.companyName} css={{}}>
      <div css={{ marginBottom: 10, position: 'relative' }}>
        <CoverVideo path={videoImg} onClick={onClick} />
      </div>
      <div
        css={{
          textTransform: 'uppercase',
          fontFamily: ShFonts.secondary,
          marginBottom: 5,
        }}
      >
        {data.companyName}
      </div>
      <div>
        <strong>{data.clientName}</strong>
        <span> {data.clientRole}</span>
      </div>
    </div>
  );
};

const VideoTestimonials = ({
  withRevealEffect,
  title,
}: {
  withRevealEffect?: boolean;
  title?: ShIntlMessageDefinition;
}) => {
  const [videoId, setVideoId] = useState<VideoTestimonialName>();
  const videoTestimonials = useVideoTestimonials();

  return (
    <div>
      {title && (
        <H2>
          <ShText message={title} />
        </H2>
      )}
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 40,
          marginTop: 40,
          [ShMediaQueries.ShMobileOnly]: {
            flexDirection: 'column',
            gap: 20,
          },
        }}
      >
        {Object.keys(videoTestimonials).map((client: string, i) => {
          const data = videoTestimonials[client as VideoTestimonialName];
          return withRevealEffect ? (
            <ApplyMountEffect key={i} index={i} effect={cardRevealEffect}>
              {(ref) => (
                <div
                  key={i}
                  ref={ref}
                  css={{
                    width: '100%',
                    flex: '1 1',
                  }}
                >
                  <VideoTestimonial
                    videoImg={data.videoImg}
                    data={data}
                    onClick={() => setVideoId(client as VideoTestimonialName)}
                  />
                </div>
              )}
            </ApplyMountEffect>
          ) : (
            <div
              key={i}
              css={{
                width: '100%',
                flex: '1 1',
              }}
            >
              <VideoTestimonial
                videoImg={data.videoImg}
                data={data}
                onClick={() => setVideoId(client as VideoTestimonialName)}
              />
            </div>
          );
        })}

        {videoId && (
          <ShModal
            style={'transparent'}
            title={VideoTestimonialData[videoId].companyName}
            size={'l'}
            onClose={() => setVideoId(undefined)}
            content={
              <div>
                <VideoTestimonialModalContent
                  videoData={VideoTestimonialData[videoId]}
                />
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export const TheyTrustUsSection = ({
  withRevealEffect = true,
}: {
  withRevealEffect?: boolean;
}) => {
  const locale = useShIntlLocale();

  const logosToDisplay =
    locale === 'en_US' ? usLogosForAboutUsPage : otherLogosForAboutUsPage;

  const logoImages = useClientLogoImages(logosToDisplay);

  return (
    <>
      <AppSection
        header={
          <H2>
            <ShText message={FrontAboutMessages.pageSectionTrustTitle} />
          </H2>
        }
      >
        <LogosSection
          logoImages={logoImages}
          withRevealEffect={withRevealEffect}
        />
      </AppSection>
      {locale === 'en_US' && (
        <AppSection
          header={
            <H2>
              <ShText message={FrontAboutMessages.pageSectionClientsTitle} />
            </H2>
          }
        >
          <VideoTestimonials withRevealEffect={withRevealEffect} />
        </AppSection>
      )}
    </>
  );
};

export const TheyTrustUsHomePageSection = ({
  withRevealEffect = true,
}: {
  withRevealEffect?: boolean;
}) => {
  const locale = useShIntlLocale();

  const logosToDisplay =
    locale === 'en_US' ? usLogosForHomePage : otherLogosForHomePage;

  const logoImages = useClientLogoImages(logosToDisplay);

  return (
    <AppSection
      header={
        <H2>
          <ShText message={FrontHomeMessages.trustTitle} />
        </H2>
      }
    >
      <LogosSection
        logoImages={logoImages}
        withRevealEffect={withRevealEffect}
      />
      {locale === 'en_US' && (
        <VideoTestimonials withRevealEffect={withRevealEffect} />
      )}
    </AppSection>
  );
};
