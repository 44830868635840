import React from 'react';
import { ShColors } from './ShColors';

// It's hard to change map libraries at runtime on a per-page basis,
// so we load them all upfront!
// see https://github.com/JustFly1984/react-google-maps-api/issues/186
export const ShMapLibraries = ['places', 'geometry'];
//export const PhotographerMapsLibraries = ['places', 'geometry'];
//export const OrderMapsLibraries = ['places'];

export const ShDefaultPolygonOptions = {
  strokeColor: ShColors.base,
  strokeOpacity: 0.3,
  strokeWeight: 1,
  fillColor: ShColors.base,
  fillOpacity: 0.5,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

export const ShDefaultMapsStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#A3AEFF',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

const path =
  'M27.188,10.188C27.188,4.562,22.628,0,17.001,0S6.812,4.561,6.812,10.188c0,4.856,7.59,17.833,9.668,21.292c0.11,0.186,0.308,0.295,0.521,0.295s0.412-0.111,0.52-0.295c2.077-3.461,9.666-16.437,9.666-21.293V10.188z M17,18.071c-4.345,0-7.885-3.535-7.885-7.884c0-4.349,3.537-7.884,7.885-7.884s7.882,3.537,7.882,7.884C24.882,14.535,21.347,18.071,17,18.071z';

export const ShDefaultMarkerIcon: google.maps.Icon | google.maps.Symbol = {
  path: path,
  // @ts-ignore
  color: ShColors.base,
  fillColor: ShColors.base,
  opacity: 0.5,
  fillOpacity: 1,
  scale: 1,
  strokeColor: ShColors.base,
  strokeWeight: 3,
};

export const ShDefaultMarkerIconSm = (
  color: string,
): google.maps.Icon | google.maps.Symbol => {
  return {
    path: path,
    // @ts-ignore
    color: color,
    fillColor: color,
    opacity: 0.5,
    fillOpacity: 1,
    scale: 0.5,
    strokeColor: color,
    strokeWeight: 1,
    anchor: new google.maps.Point(16, 33),
  };
};

export const ShDefaultMarkerIconS = (
  color: string,
): google.maps.Icon | google.maps.Symbol => {
  return {
    path: path,
    // @ts-ignore
    color: color,
    fillColor: color,
    opacity: 0.5,
    fillOpacity: 1,
    scale: 0.8,
    strokeColor: color,
    strokeWeight: 1,
    anchor: new google.maps.Point(16, 33),
  };
};

export const ShDefaultMarkerIconMd = (
  color: string,
): google.maps.Icon | google.maps.Symbol => {
  return {
    path: path,
    // @ts-ignore
    color: color,
    fillColor: color,
    opacity: 0.5,
    fillOpacity: 1,
    scale: 1,
    strokeColor: color,
    strokeWeight: 3,
    anchor: new google.maps.Point(16, 33),
  };
};

export const ShDefaultMapOptions: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  styles: ShDefaultMapsStyles,
  disableDoubleClickZoom: true,
  gestureHandling: 'none',
};
