import { isServer } from 'utils/gatsbyUtils';
import Recaptcha from 'react-recaptcha';
import React from 'react';
import useId from 'hooks/useId';

const AppRecaptcha = ({
  onVerify,
}: {
  onVerify: (response: string) => void;
}) => {
  const id = useId();
  if (isServer()) {
    return null; // Does not work well with SSR ?
  }
  return (
    <Recaptcha
      sitekey="6LfBN4wUAAAAALm8LjTG_gLFAEoT-OeGILLwEm6n"
      render="explicit"
      elementID={id}
      verifyCallback={onVerify}
    />
  );
};

export default AppRecaptcha;
