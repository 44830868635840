import { useLoadScript } from '@slorber/react-google-maps';
import React, { ReactNode, useContext, useEffect } from 'react';

import { ShGoogleMapsApiKey } from '@shoootin/config';
import { useShIntlLanguage } from '@shoootin/translations';
import { ShMapLibraries } from '@shoootin/design-tokens';

const useGoogleMapsLanguage = (): string => {
  // This might not work for all future cases but fine for now
  // See https://developers.google.com/maps/faq#languagesupport
  return useShIntlLanguage();
};

// /!\ ONLY LOAD THIS ONCE PER PAGE, AT THE TOP
// see https://github.com/JustFly1984/react-google-maps-api/issues/186
// It's not possible to select map/autocomplete language at runtime
// To change language we must remove/reinsert the google maps script
const useGoogleMapsAPILoader = () => {
  const language = useGoogleMapsLanguage();
  const scriptId = `GoogleMaps_lang=${language}`;
  useEffect(() => {
    console.debug('useGoogleMapsAPI mount scriptid=', scriptId);
    return () => {
      const script = document.getElementById(scriptId);
      if (script) {
        console.debug('useGoogleMapsAPI unmount scriptid=', scriptId);
        script.remove();

        // TODO how be sure to delete the google maps and not get the console error?
        // Even if removed, the script seems to continue to execute and load google.maps...
        // This happens when redirecting fast from one lang to another on startup etc...
        // @ts-ignore
        if (window.google && window.google.maps) {
          // @ts-ignore
          delete window.google.maps;
        }
      }
    };
  }, [language]);

  return useLoadScript({
    id: scriptId,
    language,
    libraries: ShMapLibraries,
    googleMapsApiKey: ShGoogleMapsApiKey,
    // preventGoogleFontsLoading: true, // this seems to break emotion :s see https://github.com/JustFly1984/react-google-maps-api/issues/160
  });
};

type GoogleMapsAPIContextValue = ReturnType<typeof useLoadScript>;

const GoogleMapsAPIContext = React.createContext<GoogleMapsAPIContextValue | null>(
  null,
);

// This is a provider to setup at most once per page that will ensure the loading of google maps scripts
// Touch this carefully, changing google maps lang at runtime is difficult (see useGoogleMapsAPILoader)
// This should rather be placed at the top of the page
// You can show a nested spinner using "useGoogleMapsLoaded" hooks until the script is loaded)
export const GoogleMapsAPIProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const value = useGoogleMapsAPILoader();
  return (
    <GoogleMapsAPIContext.Provider value={value}>
      {children}
    </GoogleMapsAPIContext.Provider>
  );
};

const useGoogleMapsAPI = () => {
  const value = useContext(GoogleMapsAPIContext);
  if (value === null) {
    throw new Error('useGoogleMapsAPI => no context provider has been setup');
  }
  return value;
};

export const useGoogleMapsLoaded = () => useGoogleMapsAPI().isLoaded;
