import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import useGetter from './useGetter';

type Params = {
  callback?: () => void;
};

declare global {
  interface Window {
    requestIdleCallback: (
      callback: (deadline: {
        didTimeout: boolean;
        timeRemaining: () => number;
      }) => void,
    ) => number;
    cancelIdleCallback: (id: number) => void;
  }
}
// Permit to lazy init some js code if the marker becomes visible
const useRequestIdleCallbackInitializer = ({ callback }: Params = {}) => {
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const getCallback = useGetter(callback);

  if (typeof window !== 'undefined') {
    window.requestIdleCallback =
      window.requestIdleCallback ||
      function (cb: Function) {
        const start = Date.now();
        return setTimeout(() => {
          cb({
            didTimeout: false,
            timeRemaining() {
              return Math.max(0, 50 - (Date.now() - start));
            },
          });
        }, 1);
      };

    window.cancelIdleCallback =
      window.cancelIdleCallback ||
      function (id: number) {
        clearTimeout(id);
      };
  }

  useEffect(() => {
    // TS: see https://github.com/Microsoft/TypeScript/issues/21309
    // @ts-ignore
    const id = window.requestIdleCallback(() => {
      const cb = getCallback();
      cb && cb();
      setInitialized(true);
    });
    // @ts-ignore
    return () => window.cancelIdleCallback(id);
  }, []);

  return { isInitialized };
};

export default useRequestIdleCallbackInitializer;
