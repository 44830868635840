import React, { ReactNode, useRef } from 'react';
import { Transition } from 'react-spring';

type OpacityRenderProp = (opacity: number) => ReactNode;

// This permit to ensure that during component leave animation,
// we do not update the children to avoid giving a weird UX
const useStableOpacityRender = (
  visible: boolean,
  children: OpacityRenderProp,
): OpacityRenderProp => {
  const lastVisibleNodeRef = useRef<ReactNode>(null);
  return (opacity: number) => {
    const node = children(opacity);
    if (visible) {
      lastVisibleNodeRef.current = node;
      return node;
    } else {
      return lastVisibleNodeRef.current || node;
    }
  };
};

export const SingleItemOpacityTransition = ({
  visible,
  children,
}: {
  visible: boolean;
  children: OpacityRenderProp;
}) => {
  const stableRender = useStableOpacityRender(visible, children);
  return (
    <Transition
      items={visible}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      native={true}
    >
      {(transitionStyle, item) => {
        return item && stableRender(transitionStyle.opacity);
      }}
    </Transition>
  );
};
