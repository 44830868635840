/* eslint-disable */
// copied from https://github.com/reach/reach-ui/blob/master/packages/auto-id/src/index.js

import { useState, useEffect } from 'react';
// Could use UUID but if we hit 9,007,199,254,740,991 unique components over
// the lifetime of the app before it gets reloaded, I mean ... come on.
// I don't even know what xillion that is.
// /me googles
// Oh duh, quadrillion. Nine quadrillion components. I think we're okay.
let id = 0;
const genId = () => ++id;

const useId = (): string | undefined => {
  const [id, setId] = useState<number | undefined>(undefined);
  useEffect(() => setId(genId()), []);
  return id ? `${id}` : undefined;
};

export default useId;
