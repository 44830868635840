import React, { ErrorInfo, ReactNode } from 'react';

import * as Sentry from '@sentry/browser';
import PageError from './page/pageError';
import { showUnsupportedBrowserAlertFallbackIfNeeded } from './appBrowsers';

type Props = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

const handleError = (error: Error, errorInfo: ErrorInfo) => {
  console.error('error boundary handleError', error, errorInfo);
  // You can also log the error to an error reporting service
  Sentry.configureScope(scope => {
    Object.keys(errorInfo).forEach(key => {
      // @ts-ignore
      const value = errorInfo[key];
      scope.setExtra(key, value);
    });
  });
  Sentry.captureException(error);
};

// This is a fallback error boundary, just in case
export class AppFallbackErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('AppFallbackErrorBoundary', error, errorInfo);
    handleError(error, errorInfo);
    showUnsupportedBrowserAlertFallbackIfNeeded();
  }

  render() {
    // This is a fallback error boundary, the UI must remain extremely simple and not use anything from context
    // This is almost never supposed to happen anyway
    if (this.state.hasError) {
      return <div>Technical error</div>;
    }

    return this.props.children;
  }
}

// This error boundary show the beautiful error page
// But this error page may fail to render due to using translations, layout, context...
// There's another AppFallbackErrorBoundary that is less polished but not likely to crash as a fallback
class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('AppErrorBoundary', error, errorInfo);
    handleError(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <AppFallbackErrorBoundary>
          <PageError />
        </AppFallbackErrorBoundary>
      );
    }

    return this.props.children;
  }
}

export default AppErrorBoundary;
