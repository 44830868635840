import React, { ComponentProps, ReactNode } from 'react';
import { SingleItemOpacityTransition } from 'utils/reactSpringUtils';
import { animated } from 'react-spring';
import { ShSpinner } from '@shoootin/components-web';

// An app overlay that cover its relative parent full-size
// and mount/unmount with an opacity animation
const AppOverlay = ({
  visible,
  className,
  children,
}: {
  visible: boolean;
  className?: string;
  children: () => ReactNode;
}) => (
  <SingleItemOpacityTransition visible={visible}>
    {(opacity) => (
      <animated.div
        css={{
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        style={{ opacity }}
        className={className}
      >
        {children()}
      </animated.div>
    )}
  </SingleItemOpacityTransition>
);

export default AppOverlay;

// A spinner overlay that will appear on top of a parent relative element
export const AppSpinnerOverlay = ({
  visible,
  className,
  ...rest
}: {
  visible: boolean;
  className?: string;
} & ComponentProps<typeof ShSpinner>) => (
  <AppOverlay visible={visible}>
    {() => (
      <div
        className={className}
        css={{
          // This extra div allows to pass a custom opacity as css prop,
          // without messing up with the overlay opacity anim
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ShSpinner {...rest} />
      </div>
    )}
  </AppOverlay>
);
