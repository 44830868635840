import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames';
import VideoPlaySvg from './svg/icon/videoPlaySvg';
import AppImage, {
  AppGatsbyImage,
  AppGatsbyImageFluid,
} from '../primitives/appImage';
import { isString } from 'lodash';

type CoverVideoBaseProps = {
  children: ReactNode;
  modifier?: 'square' | 'banner';
  onClick?: MouseEventHandler;
};
const CoverVideoBase = ({
  children,
  modifier,
  onClick,
}: CoverVideoBaseProps) => (
  <div className="cover-wrapper" onClick={onClick}>
    <div className={classnames('cover', 'video', modifier)}>
      <a className="cover__link">
        <VideoPlaySvg />
      </a>
      {children}
    </div>
  </div>
);

const CoverVideo = ({
  path,
  ...props
}: {
  path: AppGatsbyImageFluid | string;
} & Omit<CoverVideoBaseProps, 'children'>) => (
  <CoverVideoBase {...props}>
    {isString(path) ? (
      <AppImage className="cover__image" src={path} lazy />
    ) : (
      <AppGatsbyImage
        className="cover__image"
        fluid={path}
        style={{ position: 'absolute' }}
      />
    )}
  </CoverVideoBase>
);

export default CoverVideo;
