import { useShTranslate } from '@shoootin/translations';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { getCurrentUserIntercomData } from '../appAPI';
import { CurrentUser } from '../appTypes';
import { createForBrowser } from './gatsbyUtils';
import AppGlobalEvents from '../appGlobalEvents';
import { IntercomMessageNoAvailability } from '../page/order/steps/book/stepBook';

const debug = false;

const IntercomAppId: string = createForBrowser(() => {
  // set by the gatsby plugin
  // @ts-ignore
  return window.IntercomAppId;
});
debug && console.debug('IntercomAppId', IntercomAppId);

const prefix = `intercom_messages`;

const shouldHideLauncherForPathname = (pathname: string) => {
  // const forbiddenPathnamePrefixes = ['/scan/', '/scans/'];
  // TODO, we use includes because we need to disable localized scan pathnames too (ie /en-gb/scan/id)
  // This is not safe for long term as nother urls might contain /scan/ in the url...
  const forbiddenPathnameParts = ['/scan/', '/scans/'];
  return forbiddenPathnameParts.some((part) => pathname.includes(part));
};

export const IntercomMessages = defineMessages({
  noAvailabilityForSelectedDay: {
    id: `${prefix}_noAvailabilityForSelectedDay`,
    defaultMessage: `Bonjour. Il n'y a aucun créneau disponible pour mon shooting du {selectedDay}, pouvez-vous faire quelque chose?`,
  },
});

const showIntercomMessage = (initialMessage?: string) => {
  debug && console.debug('Intercom showIntercomMessage', initialMessage);
  if (Intercom) {
    Intercom('showNewMessage', initialMessage);
  }
};

const update = (param?: Intercom_.IntercomSettings) => {
  debug && console.debug('Intercom update', param);
  if (Intercom) {
    Intercom('update', param);
  }
};

export const restartIntercom = () => {
  debug && console.debug('Intercom restart');
  if (Intercom) {
    Intercom('shutdown');
    Intercom('boot', { app_id: IntercomAppId });
  }
};

export const updateIntercomUserSettings = (user: CurrentUser) => {
  const doUpdate = async () => {
    const { intercomSettings } = await getCurrentUserIntercomData();
    update(intercomSettings as Intercom_.IntercomSettings);
  };

  doUpdate().catch((e) =>
    console.error('updateIntercomUserSettings failed', e),
  );
};

const intercomHandlePathnameChange = (pathname: string) => {
  update({ hide_default_launcher: shouldHideLauncherForPathname(pathname) });
};

AppGlobalEvents.pathnameChange.subscribe(intercomHandlePathnameChange);

export const useIntercom = () => {
  const translate = useShTranslate();

  const api = useMemo(() => {
    const showNoAvailabilityForSelectedDay = (
      intercomNoAvailabilityValues: IntercomMessageNoAvailability,
    ) => {
      const text = translate(
        IntercomMessages.noAvailabilityForSelectedDay,
        intercomNoAvailabilityValues,
      );
      showIntercomMessage(text);
    };
    const openWithEmptyMessage = () => showIntercomMessage();

    return {
      openWithEmptyMessage,
      showNoAvailabilityForSelectedDay,
      handlePathnameChange: intercomHandlePathnameChange,
    };
  }, [translate]);

  return api;
};
