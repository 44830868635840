import { useEffect, useRef } from 'react';

export const useIsMountedFn = (): (() => boolean) => {
  const ref = useRef<boolean>(false);
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  return () => ref.current;
};

const useIsMounted = () => useIsMountedFn()();

export default useIsMounted;
