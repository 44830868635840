import React, { useCallback } from 'react';
import Helmet from 'react-helmet';
import { getFrontMessageUnsafe } from 'appMessageDefinitions';
import { isString } from 'lodash';
import useSiteMetadata from './hooks/useSiteMetadata';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
  useShTranslate,
} from '@shoootin/translations';
import { useAppPathnameLocalizer, useIsTranslateMode } from 'layout/appContext';
import { UsecaseName } from './appConstantsUsecase';
import { ShDefaultLocale, ShLocale, ShLocales } from '@shoootin/config';

type Meta = JSX.IntrinsicElements['meta'];

export type MetierPageName =
  | 'metier_hotel'
  | 'metier_commercial'
  | 'metier_residential'
  | 'metier_architect'
  | 'metier_construction'
  | 'metier_retail'
  | 'metier_event'
  | 'metier_restaurant';

export type PrestationPageName =
  | 'prestation_photo'
  | 'prestation_video'
  | 'prestation_matterport'
  | 'prestation_drone';

export type UsecasePageName =
  | 'usecase_kretz'
  | 'usecase_convini'
  | 'usecase_belleval'
  | 'usecase_deskeo'
  | 'usecase_breathe';

export const toUsecasePageName = (usecaseName: UsecaseName): UsecasePageName =>
  `usecase_${usecaseName}` as any; // TODO make it safer?

export type SEOPageName =
  | MetierPageName
  | PrestationPageName
  | UsecasePageName
  | 'notFound'
  | 'error'
  | 'home'
  | 'about'
  | 'cgu'
  | 'faq'
  | 'interview'
  | 'legal'
  | 'login'
  | 'newPassword'
  | 'recoverPassword'
  | 'order'
  | 'photographer'
  | 'photographerJoin'
  | 'photographerJoinEdit'
  | 'scan';

const getSEOPageMessages = (
  pageName: SEOPageName,
): {
  title: ShIntlMessageDefinition;
  description: ShIntlMessageDefinition;
} => {
  const titleKey = `front_seo_${pageName}_title`;
  const descriptionKey = `front_seo_${pageName}_description`;
  return {
    title: getFrontMessageUnsafe(titleKey, pageName),
    description: getFrontMessageUnsafe(
      descriptionKey,
      `${pageName} description`,
    ),
  };
};

type LinkProps = JSX.IntrinsicElements['link'];

const usePageUrlForLocale = (): ((appLocale: ShLocale) => string) => {
  const siteMetadata = useSiteMetadata();
  const pathnameLocalizer = useAppPathnameLocalizer();
  return useCallback(
    (appLocale) => {
      const pathname = pathnameLocalizer(appLocale);
      return siteMetadata.siteUrl + pathname;
    },
    [siteMetadata, pathnameLocalizer],
  );
};

// A little helper to help translators to translate SEO strings
export const AppSEOTranslateMode = ({
  pageName,
}: {
  pageName: SEOPageName;
}) => {
  const translateMode = useIsTranslateMode();
  const seoPageMessages = getSEOPageMessages(pageName);
  if (!translateMode) {
    return null;
  }
  return (
    <div
      css={{
        padding: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h5>SEO translations:</h5>
      <ul>
        {Object.keys(seoPageMessages).map((key) => {
          // @ts-ignore
          const message: ShIntlMessageDefinition = seoPageMessages[key] as any;
          return (
            <li key={message.id}>
              <ShText message={message} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const GenericMeta: Meta[] = [
  {
    property: `og:type`,
    content: `website`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
];

const titleMeta = (title: string): Meta[] => [
  {
    property: `og:title`,
    content: title,
  },
  {
    name: `twitter:title`,
    content: title,
  },
];

const descriptionMeta = (description: string): Meta[] => [
  {
    name: `description`,
    content: description,
  },
  {
    property: `og:description`,
    content: description,
  },
  {
    name: `twitter:description`,
    content: description,
  },
];

const authorMeta = (author: string): Meta[] => [
  {
    name: `twitter:creator`,
    content: author,
  },
];

const imageMeta = (image: string): Meta[] => [
  { name: 'og:image', content: image },
  { name: 'twitter:image', content: image },
  { itemProp: 'image', content: image },
];

const toBCP47Locale = (appLocale: ShLocale) => appLocale.replace('_', '-');

const AppSEO = React.memo(({ pageName }: { pageName: SEOPageName }) => {
  const translate = useShTranslate();
  const locale = useShIntlLocale();
  const pageMessages = getSEOPageMessages(pageName);
  const siteMetadata = useSiteMetadata();
  const pageUrlForLocale = usePageUrlForLocale();

  const title = translate(pageMessages.title);
  const description = translate(pageMessages.description);

  /*
  Generate language metadata for internationalization
  Like those found on Apple:
  <link rel="canonical" href="https://www.apple.com/fr/">
  <link rel="alternate" href="https://www.apple.com/ae-ar/" hreflang="ar-AE">
   */
  // @ts-ignore
  const links: LinkProps[] = [
    {
      rel: 'canonical',
      href: pageUrlForLocale(locale),
    },
    {
      rel: 'alternate',
      href: pageUrlForLocale(ShDefaultLocale),
      hrefLang: 'x-default',
    },
    ...ShLocales.map((alternateLocale) => {
      return {
        rel: 'alternate',
        href: pageUrlForLocale(alternateLocale),
        hrefLang: toBCP47Locale(alternateLocale),
      };
    }),
  ];

  const meta: Meta[] = [
    ...GenericMeta,
    ...titleMeta(title),
    ...descriptionMeta(description),
    ...authorMeta(siteMetadata.author),
    ...imageMeta(siteMetadata.image),
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang: toBCP47Locale(locale),
      }}
      link={links}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={meta}
    />
  );
});

export default AppSEO;

// Can be useful to setup dynamic titles or have "subtitles"
export const AppSEOTitle = ({
  title,
}: {
  title: string | ShIntlMessageDefinition;
}) => {
  const translate = useShTranslate();
  const siteMetadata = useSiteMetadata();
  const finalTitle = isString(title) ? title : translate(title);
  return (
    <Helmet title={finalTitle} titleTemplate={`%s | ${siteMetadata.title}`} />
  );
};

export const AppSEOImage = ({ image }: { image: string }) => {
  return <Helmet meta={imageMeta(image)} />;
};
