import { defineMessages } from 'react-intl';

const prefix = 'front_contact';

export const FrontContactMessages = defineMessages({
  sectionTitle: {
    id: `${prefix}_sectionTitle`,
    defaultMessage: 'Vous avez une question',
  },
  sectionSubtitle: {
    id: `${prefix}_sectionSubtitle`,
    defaultMessage:
      'Notre équipe se tient à votre disposition afin de répondre à vos questions. N’hésitez pas à nous joindre au 01 79 72 70 88',
  },
  sectionMessageSent: {
    id: `${prefix}_sectionMessageSent`,
    defaultMessage:
      'Votre message a bien été envoyé, notre équipe reviendra vers vous rapidement.',
  },
  contactEmail: {
    id: `${prefix}_contactEmail`,
    defaultMessage: 'Courrier',
  },
  contactAddress1: {
    id: `${prefix}_contactAddress1`,
    defaultMessage: '116 Avenue de Villiers',
  },
  contactAddress2: {
    id: `${prefix}_contactAddress2`,
    defaultMessage: '75017 Paris',
  },
  chat: {
    id: `${prefix}_chat`,
    defaultMessage: 'Chat',
  },
  chatDescription: {
    id: `${prefix}_chatDescription`,
    defaultMessage:
      'Contactez-nous aussi sur le chat où un conseiller vous répondra directement.',
  },
  chatNow: {
    id: `${prefix}_chatNow`,
    defaultMessage: 'Chatter maintenant',
  },
});
