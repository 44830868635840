/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react';

import '@emotion/core';
import './src/styles/index.less';

// TODO we should ensure code splitting works for all these locales data
// See https://github.com/gatsbyjs/gatsby/issues/13806

/*
// TODO should we replace these old imports with intl polyfills?
// See https://github.com/formatjs/react-intl/blob/master/docs/Upgrade-Guide.md#migrate-to-using-native-intl-apis

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import de from 'react-intl/locale-data/de';
import el from 'react-intl/locale-data/el';
import pt from 'react-intl/locale-data/pt';
import it from 'react-intl/locale-data/it';
addLocaleData([...en, ...fr, ...es, ...de, ...el, ...pt, ...it]);
 */

import 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/pt';
import 'moment/locale/it';

import { initAnalytics } from './src/appAnalytics';
import { AppFallbackErrorBoundary } from 'appErrorBoundary';
import { AppBrowserSupportModal } from 'appBrowsers';

import '@shoootin/components-web/src/grid/ShGrid.css';

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

initAnalytics();

// Shitty polyfill for IE11 issues related to getOwnPropertyNames / getOwnPropertySymbols
// Those issues happen on IE11 in order process. If you find another way to fix IE11 order process
// without requiring huge polyfills you are welcome... (we use polyfill.io currently)
// See https://github.com/immerjs/immer/issues/336#issuecomment-473884546
const { getOwnPropertyDescriptor, hasOwnProperty } = Object;
Object.getOwnPropertyDescriptor = function (obj, key) {
  return (
    getOwnPropertyDescriptor(obj, key) ||
    (hasOwnProperty.call(obj, key)
      ? {
          value: obj[key],
          enumerable: true,
          writable: true,
          configurable: true,
        }
      : void 0)
  );
};

// IE11 classlist polyfill for SVG elements not supported on IE11..
// svg.classList.add() is actually used in legacy JS (home hero slider)
// from https://gist.github.com/EvanAgee/350b5072e1ec3d82d34cc6a735914eff
if (!('classList' in SVGElement.prototype)) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: (className) => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1;
        },
        add: (className) => {
          return this.setAttribute(
            'class',
            this.getAttribute('class') + ' ' + className,
          );
        },
        remove: (className) => {
          var removedClass = this.getAttribute('class').replace(
            new RegExp('(\\s|^)' + className + '(\\s|$)', 'g'),
            '$2',
          );
          if (this.classList.contains(className)) {
            this.setAttribute('class', removedClass);
          }
        },
      };
    },
  });
}

export const wrapRootElement = ({ element }) => {
  return <AppFallbackErrorBoundary>{element}</AppFallbackErrorBoundary>;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      {element}
      <AppBrowserSupportModal />
    </>
  );
};
