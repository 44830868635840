import React, { ReactNode, useCallback, useMemo } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps, navigate } from 'gatsby';
import { Omit, Brand } from 'utility-types';
import { AppOrderLocationState } from '../page/order/context/orderPageContextState';
import { getLocalePrefix } from '../layout/appContext';
import { useShIntlLocale } from '@shoootin/translations';
import { ProfessionShootingType } from '../appConstants';
import { UsecaseName } from '../appConstantsUsecase';

const useLinkPrefixer = () => {
  const locale = useShIntlLocale();
  return useCallback(
    (path: AppInternalPath) => {
      const prefix = getLocalePrefix(locale);
      const prefixedPath = `${prefix}${path}`;
      return prefixedPath;
    },
    [locale],
  );
};

// create a "nominal type" for gatsby valid urls
// it's like a subtype of string which can't be assigned to a string type
// this permits to ensure you don't randomly use string literals in links but use the AppInternalPaths builders
export type AppInternalPath = Brand<string, 'AppInternalPath'>;

// const appendHash = (hash: string | undefined, path: string) =>
//   `${path}${hash ? '#' : ''}${hash}`;

const ProfessionShootingTypeToPathSuffix: {
  [key in ProfessionShootingType]: string;
} = {
  residentialRealEstate: 'residential',
  commercialRealEstate: 'commercial',
  architect: 'architect',
  hotel: 'hotel',
  restaurant: 'restaurant',
  retail: 'retail',
};

export const AppInternalPaths = {
  about: '/about' as AppInternalPath,
  legal: '/legal' as AppInternalPath,
  cgu: '/cgu' as AppInternalPath,
  home: '/' as AppInternalPath,
  profession: (profession: ProfessionShootingType) => {
    const suffix = ProfessionShootingTypeToPathSuffix[profession];
    return `/profession/${suffix}` as AppInternalPath;
  },
  service: (prestation: 'matterport' | 'photo' | 'video' | 'drone') =>
    `/service/${prestation}` as AppInternalPath,
  // faq: '/faq' as AppInternalPath,
  photographer: '/photographer' as AppInternalPath,
  join: '/join' as AppInternalPath,
  joinEditApplication: (applicationId: string) =>
    `/join/edit/${applicationId}` as AppInternalPath,
  interview: (photographerId: string) =>
    `/interview/${photographerId}` as AppInternalPath,
  usecase: (usecase: UsecaseName) => `/usecase/${usecase}` as AppInternalPath,
  order: '/order' as AppInternalPath,
  orderRestore: (shootingId: string) =>
    `/order/${shootingId}` as AppInternalPath,
  login: '/login' as AppInternalPath,
  lostPassword: '/lostpassword' as AppInternalPath,
  newPassword: (token: string) => `/newpassword/${token}` as AppInternalPath,
  scan: (matterportId: string) => `/scan/${matterportId}` as AppInternalPath,
  scans: (shootingId: string) => `/scans/${shootingId}` as AppInternalPath,

  // Client paths
  clientHome: '/client' as AppInternalPath,
  clientProfile: '/client/profile' as AppInternalPath,
  clientShootings: '/client/shootings' as AppInternalPath,
  clientShooting: (shootingId: string) =>
    `/client/shooting/${shootingId}` as AppInternalPath,
};

type AppInternalLinkProps<TState> = Omit<
  GatsbyLinkProps<TState>,
  'to' | 'ref'
> & {
  children?: ReactNode;
  to: AppInternalPath;
};
export function AppInternalLink<TState = never>({
  to,
  children,
  ...otherProps
}: AppInternalLinkProps<TState>) {
  const prefixer = useLinkPrefixer();
  const prefixedTo = prefixer(to);
  return (
    <GatsbyLink to={prefixedTo} {...otherProps}>
      {children}
    </GatsbyLink>
  );
}

// Typesafe imperative api
// This should be avoided in favor of links most of the time
export const useNavigate = () => {
  const prefixer = useLinkPrefixer();

  const api = useMemo(() => {
    const to = (path: AppInternalPath, state: any) => {
      const prefixedPath = prefixer(path);
      navigate(prefixedPath, { state });
    };

    return {
      to,
      toOrder: (state?: AppOrderLocationState) =>
        to(AppInternalPaths.order, state),
      toLogin: (redirect?: string) => to(AppInternalPaths.login, { redirect }),
    };
  }, [prefixer]);

  return api;
};
