import { memoize } from 'lodash';

const updateObservedItem: IntersectionObserverCallback = entries =>
  entries.forEach(setIntersectingState);

const setIntersectingState = (entry: IntersectionObserverEntry) => {
  // @ts-ignore
  entry.target.inBounds = entry.isIntersecting;
  if (entry.isIntersecting) {
    entry.target.setAttribute('data-intersect', 'true');
  } else {
    entry.target.removeAttribute('data-intersect');
  }
};

const createAppIntersectionObserver = () => {
  return new IntersectionObserver(updateObservedItem, {
    rootMargin: '0px',
    threshold: 0,
  });
};

// Create it lazily to avoid failing gatsby node build
export const getAppIntersectionObserver = memoize(() =>
  createAppIntersectionObserver(),
);
