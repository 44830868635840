// check readme
exports.SharedAppLocaleList = [
  'fr_FR',
  'fr_BE',
  'de_DE',
  'en_US',
  'en_GB',
  'es_ES',
  'it_IT',
  'pt_PT',
  'el_GR',
  'nl_NL',
  'fr_LU',
  'en_MT',
];
exports.SharedDefaultAppLocale = 'fr_FR';

// using lowercase because netlify redirects to lowercase urls.
// With uppercase we have a weird url change (es_ES -> es_es -> es_ES)
// see https://community.netlify.com/t/my-url-paths-are-forced-into-lowercase/1659
exports.SharedAppLocalePrefixes = {
  fr_FR: '',
  fr_BE: '/fr_be',
  de_DE: '/de_de',
  en_US: '/en_us',
  en_GB: '/en_gb',
  es_ES: '/es_es',
  it_IT: '/it_it',
  pt_PT: '/pt_pt',
  el_GR: '/el_gr',
  nl_NL: '/nl_nl',
  fr_LU: '/fr_lu',
  en_MT: '/en_mt',
};
