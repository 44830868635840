import React, { ReactNode, useEffect, useMemo } from 'react';
import useInterval from './hooks/useInterval';

// @ts-ignore
import useVisibilityChange from 'use-visibility-change';
import { isDev, isProd, translateUrl } from 'appEnv';
import { isBrowser } from './utils/gatsbyUtils';
import { useIsTranslateMode } from './layout/appContext';
import { getTranslationMessages } from './layout/allTranslations';
import { ShLocale } from '@shoootin/config';

import {
  ShIntlProvider,
  useShIntl,
  useShIntlLocale,
} from '@shoootin/translations';
import { reportError } from './appAnalytics';

import { ShTranslationModal } from '@shoootin/components-web';
import { initializeShMoment } from '@shoootin/utils';

const useFetchMessagesConfig = () => {
  const isTranslateMode = useIsTranslateMode();
  return {
    // Fetch messages on start
    // TODO temporary enabled in prod/all envs, but shouldn't be necessary imho...
    fetchInitial: true,
    // fetchInitial: !isProd || isTranslateMode,

    // Fetch messages periodically
    // Mostly useful for translators to see their own changes
    fetchPeriodic: isDev ? false : !isProd || isTranslateMode,
  };
};

const hasLocalStorageAccess = () => {
  try {
    window.localStorage.getItem('');
    return true;
  } catch {
    return false;
  }
};

// TODO should we generalize this behavior to all apps and make it shared?
// When working on translations, we want fresh translations
// By default the translations are static, but to make translators life easier
// we fetch a live version of translations on mount and periodically
const useMessagesRefetcher = () => {
  const { locale, setMessages, refreshMessages } = useShIntl();

  useEffect(() => {
    setMessages(getTranslationMessages(locale));
  }, [locale]);

  const fetchMessagesConfig = useFetchMessagesConfig();

  const triggerRefresh = () => {
    refreshMessages().catch(reportError);
  };

  // Fetch on mount and locale change
  useEffect(() => {
    if (fetchMessagesConfig.fetchInitial) {
      triggerRefresh();
    }
  }, [locale]);

  // Fetch periodically
  useInterval(() => {
    if (fetchMessagesConfig.fetchPeriodic) {
      triggerRefresh();
    }
  }, 30000);

  console.log('hasLocalStorageAccess', hasLocalStorageAccess());
  console.log(
    'isBrowser() && hasLocalStorageAccess',
    isBrowser() && hasLocalStorageAccess(),
  );
  if (isBrowser() && hasLocalStorageAccess()) {
    // Fetch when tab becomes active
    useVisibilityChange({
      onShow: () => {
        if (fetchMessagesConfig.fetchPeriodic) {
          triggerRefresh();
        }
      },
    });
  }
};

const useMomentInitializer = () => {
  const locale = useShIntlLocale();
  // useEffect() is not appropriate because the initialization is too late!
  // with useMemo it will be initialized earlier
  useMemo(() => initializeShMoment(locale), [locale]);
};

const AppIntlBehavior = () => {
  useMomentInitializer();
  useMessagesRefetcher();
  return <></>;
};

export const AppIntlProvider = ({
  locale,
  children,
}: {
  children?: ReactNode;
  locale: ShLocale;
}) => {
  const isTranslateMode = useIsTranslateMode();

  return (
    <ShIntlProvider
      locale={locale}
      initialMessages={getTranslationMessages(locale)}
      target="FRONT"
      onError={(error) => {
        // If you use the nvmrc node version => 13
        // you should not have this ICU missing data on build
        if (error.code === 'MISSING_DATA') {
          console.warn(error.message);
          return;
        }
        reportError(error);
      }}
      translateMode={isTranslateMode}
      translateUrl={translateUrl}
      translateInterface={({ id, updateMessage, close }) => {
        return (
          <ShTranslationModal
            id={id}
            updateMessage={updateMessage}
            close={close}
            locale={locale}
          />
        );
      }}
    >
      <>
        {children}
        <AppIntlBehavior />
      </>
    </ShIntlProvider>
  );
};
