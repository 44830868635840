import React from 'react';

const LetterSvg = (props: any) => (
  <svg
    className="letter"
    height="1em"
    width="1em"
    viewBox="0 0 60 60"
    {...props}
  >
    <defs>
      <linearGradient id="prefix__a" x1="-50%" y1="-50%" x2="150%" y2="150%">
        <stop offset={0.2} stopColor="#6363fc" />
        <stop offset={0.8} stopColor="#f569c2" />
      </linearGradient>
    </defs>
    <path
      stroke="url(#prefix__a)"
      d="M58.153 10.744c.212.184.347.455.347.758v36.996c0 .553-.449 1.002-1.002 1.002H2.502A1.002 1.002 0 0 1 1.5 48.498V11.502c0-.553.449-1.002 1.002-1.002h54.996c.251 0 .48.092.655.244L30 30.627 1.5 10.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LetterSvg;
