import moment from 'moment-timezone';
import momentWithoutTz, { Moment } from 'moment';
import { ShLocale, ShLocaleToMomentLocale } from '@shoootin/config';

const isBrowser = typeof window !== 'undefined';

// Note moment is required to use react-dates. I'm not really fan of moment.
// TODO find a way to remove moment for a ligher alternative!
// unfortunately we can't move easily from react-dates for now

// TODO there might be optimizations to do on each app to ensure that we don't ship too much of moment code...
// For now we don't have much time to work on this

// To avoid confusion between moment and moment-timezone, we create an alias
// We should only use moment-timezone everywhere
export const ShMoment = moment;

export type ShMomentType = Moment;

export const formatDate = (date: Date | number) => ShMoment(date).format('L');

export const formatTime = (date: Date) => ShMoment(date).format('LT');

// Std locales have a -, not a _
const toBCP47Locale = (locale: ShLocale): string => {
  return locale.replace('_', '-');
};

// See https://github.com/moment/moment/issues/3624#issuecomment-510413974
export const toMomentLocale = (shLocale: ShLocale): string =>
  ShLocaleToMomentLocale[shLocale];

export const initializeShMoment = (locale: ShLocale) => {
  const momentLocale = toMomentLocale(locale);

  __DEV__ &&
    console.debug('initializeShMoment', {
      locale,
      momentLocale,
    });

  ShMoment.locale(momentLocale);
  momentWithoutTz.locale(momentLocale); // Is this needed? for react-dates?
  ensureMomentLocale(momentLocale);
};

const ensureMomentLocale = (momentLocale: string) => {
  // TODO why it doesn't work in Gatsby/NodeJS ????
  // See https://github.com/moment/moment/issues/1875#issuecomment-631626809
  if (
    isBrowser &&
    (momentLocale !== ShMoment.locale() ||
      momentLocale !== momentWithoutTz.locale())
  ) {
    throw new Error(
      `Shoootin moment initialization: there was an error loading moment locale=${momentLocale}? ShMoment.locale()=${ShMoment.locale()} - momentWithoutTz.locale()=${momentWithoutTz.locale()} - Maybe check webpack configuration to optim moment bundle (ContextReplacementPlugin etc)`,
    );
  }
};

export const minuteToHours = (minutes: number) => {
  return ShMoment.utc(
    ShMoment.duration(minutes, 'minutes').asMilliseconds(),
  ).format('H[h]mm');
};
export const formatDateTimeForDB = (date: ShMomentType): string =>
  date.format('YYYY-MM-DDThh:mm:ss');
export const nextWorkingDay = () => {
  const today = ShMoment();
  if (today.day() === 5) {
    // friday, show monday
    return today.add(3, 'days');
  } else if (today.day() === 6) {
    // saturday, show monday
    return today.add(2, 'days');
  } else {
    // other days, show next day
    return today.add(1, 'days');
  }
};
export const nextDroneWorkingDayForEurope = () => {
  const today = ShMoment().add(8, 'days');

  if (today.day() === 5) {
    // friday, show monday
    return today.add(3, 'days');
  } else if (today.day() === 6) {
    // saturday, show monday
    return today.add(2, 'days');
  } else {
    // other days, show next day
    return today.add(1, 'days');
  }
};
export const nextDroneWorkingDayForUS = () => {
  const today = ShMoment().add(2, 'days');

  if (today.day() === 5) {
    // friday, show monday
    return today.add(3, 'days');
  } else if (today.day() === 6) {
    // saturday, show monday
    return today.add(2, 'days');
  } else {
    // other days, show next day
    return today.add(1, 'days');
  }
};
export const onlyWeekDays = (date: ShMomentType) => {
  const day = date.day();
  return day === 6 || day === 0;
};
