type Handler<T> = (handler: T) => void;
type OptionalHandler<T> = Handler<T> | undefined | null | false;

// Useful to merge react event handlers into a single one
// See https://github.com/sandiiarov/use-events/issues/113
export const mergeHandlers = <T extends object>(
  handlers: OptionalHandler<T>[],
) => {
  return (t: T) => {
    handlers.forEach(handler => {
      if (handler) {
        handler(t);
      }
    });
  };
};
