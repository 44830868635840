import { ShApiClient } from '../ShApiClient';
import {
  ShLocale,
  ShTranslationTarget,
  ShTranslatableLocale,
} from '@shoootin/config';

export type ShTranslationsDTO = Record<ShTranslatableLocale, string>;

export type ShTranslationKeyDTO = {
  id: string;
  key: string;
  description: string;
  defaultValue: string;
  update: Date;
  html: boolean;
  translations: Record<ShTranslatableLocale, string>;
};

export const ShTranslationApi = {
  getAllTranslations: () =>
    ShApiClient.call<ShTranslationKeyDTO[]>({
      url: `/admin/translations/list`,
    }),

  getTranslations: (appLocale: ShLocale, target: ShTranslationTarget) =>
    ShApiClient.call<ShTranslationsDTO>({
      url: `/translations/download/${appLocale}/${target}`,
    }),

  getTranslationKey: (key: string) =>
    ShApiClient.call<ShTranslationKeyDTO>({
      url: `/admin/translations/edit/${key}`,
      method: 'GET',
    }),

  delete: (key: string) =>
    ShApiClient.call({
      url: `/admin/translations/delete/${key}`,
      method: 'DELETE',
    }),

  postTranslations: (
    key: string,
    translations: Record<ShTranslatableLocale, string>,
  ) =>
    ShApiClient.call<void>({
      url: `/admin/translations/edit/${key}`,
      method: 'POST',
      data: {
        translations,
      },
    }),
};
