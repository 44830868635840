import { UsecaseNames } from './appConstantsUsecase';

export const VideoNames = UsecaseNames && ['newmark'];

export type VideoName = typeof VideoNames[number];
// Some static data about usecases, that we need to access in many places
export type VideoConstantData = {
  videoUrl: string;
};
export const VideoData: {
  [key in VideoName]: VideoConstantData;
} = {
  belleval: {
    videoUrl: 'https://www.youtube.com/watch?v=uixMcjQ-l3M',
  },
  breathe: {
    videoUrl: 'https://www.youtube.com/watch?v=N4s0o3GPhNk',
  },
  convini: {
    videoUrl: 'https://www.youtube.com/watch?v=ZfvEbvrtHEI',
  },
  deskeo: {
    videoUrl: 'https://www.youtube.com/watch?v=KcBuGOSAIE0',
  },
  kretz: {
    videoUrl: 'https://www.youtube.com/watch?v=xF_SwH2OkXg',
  },
  transitionid: {
    videoUrl: 'https://www.youtube.com/watch?v=JLu4mCG_SeU',
  },
  bycharlot: {
    videoUrl: 'https://www.youtube.com/watch?v=ebuhuo4uFtY',
  },
  mom: {
    videoUrl: 'https://www.youtube.com/watch?v=MXUZqzFjWeg',
  },
  ilcottage: {
    videoUrl: 'https://www.youtube.com/watch?v=mksmLQyR43I',
  },
  newmark: {
    videoUrl: 'https://www.youtube.com/watch?v=GzhEqDCQ7fo',
  },
  tollbrothers: {
    videoUrl: 'https://youtu.be/wU9GOea7aus',
  },
};

export const VideoTestimonials = ['ay', 'knighvest', 'lantower'] as const;
export type VideoTestimonialName = typeof VideoTestimonials[number];
export type VideoTestimonialConstantData = {
  videoUrl: string;
  companyName: string;
  clientName: string;
  clientRole: string;
  clientQuote: string;
};
export const VideoTestimonialData: {
  [key in VideoTestimonialName]: VideoTestimonialConstantData;
} = {
  ay: {
    videoUrl: 'https://www.youtube.com/watch?v=mbhwZM27eWg',
    companyName: 'Avison Young',
    clientName: 'Mike Lee',
    clientRole: 'Creative Director',
    clientQuote:
      'Working with Shoootin has allowed us to elevate our collateral, which results in more inquiries.',
  },
  knighvest: {
    videoUrl: 'https://www.youtube.com/watch?v=HPUu0D_meVA',
    companyName: 'Knightvest',
    clientName: 'Alanna Zaskoda',
    clientRole: 'Assistant Project Manager',
    clientQuote:
      'It was really clear to me quickly how much easier Shoootin was to use. And so I asked if we could go ahead and make the full switch to work exclusively with Shoootin.',
  },
  lantower: {
    videoUrl: 'https://www.youtube.com/watch?v=jRwt2dbUMxs',
    companyName: 'Lantower',
    clientName: 'Morgan Stolle',
    clientRole: 'Director of Marketing',
    clientQuote:
      "Our conversions have been higher, our viewership has been higher ( ... ), and we've seen a very consistent upward trend from last year to this year.",
  },
};
