import React, {
  useRef,
  useEffect,
  useState,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { throttle, noop } from 'lodash';
import { isInViewport, scrollToCenter } from '../../utils/domUtils';
import { useSubmitCount } from './formikUtils';
import useConstant from 'use-constant';
import { useIsMountedFn } from '../../hooks/useIsMounted';
import { ShText, useShIntl } from '@shoootin/translations';

type FormErrorSubmitCountContextValue = {
  submitCount: number;
  increment: () => void;
};
const FormErrorSubmitCountContext = React.createContext<
  FormErrorSubmitCountContextValue
>({
  submitCount: 0,
  increment: () => {
    console.warn(
      "FormErrorSubmitCountContext missing: can't increment submit count",
    );
  },
});

export const FormErrorSubmitCountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isMounted = useIsMountedFn();
  const [submitCount, setSubmitCount] = useState(0);
  const increment = useCallback(() => {
    isMounted() && setSubmitCount((count) => count + 1);
  }, []);
  const value: FormErrorSubmitCountContextValue = useMemo(
    () => ({
      submitCount,
      increment,
    }),
    [submitCount, increment],
  );
  return (
    <FormErrorSubmitCountContext.Provider value={value}>
      {children}
    </FormErrorSubmitCountContext.Provider>
  );
};
export const useFormErrorSubmitCount = () =>
  useContext(FormErrorSubmitCountContext);

// When an error becomes visible (mount), we scroll to that error automatically
// so that the user can not miss it
// If user tries to resubmit the form, the error will reveal again
// (need a Provider to hold a submitCount for that feature)
const useRevealError = (el: HTMLElement | null) => {
  // If multiple errors appear at the same time,
  // throttling will ensure we scroll to the first error only
  const revealError = useConstant(() =>
    throttle((error: HTMLElement) => {
      if (!isInViewport(error)) {
        scrollToCenter(error);
      }
    }, 100),
  );

  const contextSubmitCount = useFormErrorSubmitCount().submitCount; // Used for non-formik forms
  const formikSubmitCount = useSubmitCount(); // Used for formik forms
  const finalSubmitCount = contextSubmitCount || formikSubmitCount; // Offer both possibilities to provide the submit count

  useEffect(() => {
    el && revealError(el);
    // console.debug('revealError', el);
  }, [el, finalSubmitCount]); // on re-submit, we want the first error to re-reveal itself
};

type FormErrorProps = {
  error: string;
  value?: any;
};

export const FormError = ({ error, value }: FormErrorProps) => {
  const intl = useShIntl();

  // Not using ref, see https://twitter.com/sebastienlorber/status/1126901240636608513
  const [errorElement, setErrorElement] = useState<HTMLElement | null>(null);

  useRevealError(errorElement);

  // Frontend errors have a local message declaration
  // Backend errors are already translated
  const errorText = intl.isMessage(error) ? (
    <ShText message={{ id: error }} values={{ value }} />
  ) : (
    <>{error}</>
  );

  return (
    <span className="field-control__message" ref={setErrorElement}>
      {errorText}
    </span>
  );
};
