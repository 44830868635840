import React from 'react';
import { H2, H4 } from '../primitives/appTitle';
import {
  AppLocaleOptions,
  facebookUrl,
  instagramUrl,
  linkedInUrl,
  twitterUrl,
} from '../appConstants';
import InstagramSvg from '../components/svg/icon/social/instagramSvg';
import FacebookSvg from '../components/svg/icon/social/facebookSvg';
import TwitterSvg from '../components/svg/icon/social/twitterSvg';
import LinkedInSvg from '../components/svg/icon/social/linkedInSvg';
import {
  AppInternalLink,
  AppInternalPath,
  AppInternalPaths,
} from '../primitives/appLink';
import AppInputSelect from '../primitives/input/appInputSelect';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import { FrontMegaMenuMessages } from './megamenuTranslations';
import { isInternalLink, Menu } from './navigationStructure';
import { FrontNavigationMessages } from '../appCommonTranslations';
import { useUpdateCurrentUserLocale } from '../state/currentUserState';
import useRequestIdleCallbackInitializer from '../hooks/useRequestIdleCallbackInitializer';
import { usePathnameRedirectForLocale } from './appContext';
import { TheyTrustUsSection } from '../components/theyTrustUsSection';
import ReactPlayer from 'react-player';
import { ShColors, ShMediaQueries } from '@shoootin/design-tokens';
import classNames from 'classnames';

const MegamenuContent = () => {
  const initializer = useRequestIdleCallbackInitializer();
  const locale = useShIntlLocale();
  const pathnameRedirectForLocale = usePathnameRedirectForLocale();
  const updateCurrentUserLocale = useUpdateCurrentUserLocale();
  // optimize critical render path
  if (!initializer.isInitialized) {
    return null;
  }

  return (
    <div className="container">
      <nav className="megamenu-nav">
        <div className="megamenu-section l18n">
          <AppInputSelect
            value={locale}
            onOptionSelected={(option) => {
              pathnameRedirectForLocale(option.key);
              updateCurrentUserLocale(option.key);
            }}
            options={AppLocaleOptions}
            placeholder="Votre pays"
          />
        </div>

        {Menu(locale).map((menuSection, i) => (
          <div key={i} className="megamenu-section">
            <H4>
              <ShText message={menuSection.title} />
            </H4>
            <ul>
              {menuSection.links.map((link, j) => (
                <li key={j}>
                  {isInternalLink(link) ? (
                    <AppInternalLink
                      className="link"
                      to={link.href as AppInternalPath}
                    >
                      <ShText message={link.label} />
                    </AppInternalLink>
                  ) : (
                    <a className="link" href={link.href} target={'_blank'}>
                      <ShText message={link.label as ShIntlMessageDefinition} />
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}

        <div className="megamenu-section">
          <H4>
            <ShText message={FrontNavigationMessages.followUs} />
          </H4>

          <ul className="megamenu__socials">
            <li>
              <a target="_blank" rel="noopener" href={instagramUrl}>
                <InstagramSvg />
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener" href={facebookUrl}>
                <FacebookSvg />
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener" href={twitterUrl}>
                <TwitterSvg />
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener" href={linkedInUrl}>
                <LinkedInSvg />
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <div className="megamenu-cta">
        <ShText message={FrontMegaMenuMessages.cta} />
        {'  '}
        <AppInternalLink to={AppInternalPaths.join}>
          <ShText message={FrontMegaMenuMessages.joinUs} />
        </AppInternalLink>
      </div>

      <TheyTrustUsSection withRevealEffect={false} />

      <div className="megamenu-about">
        <H2>
          <ShText message={FrontMegaMenuMessages.title} />
        </H2>

        <div className="megamenu-about__content">
          <div className="megamenu-about__text">
            <p>
              <ShText message={FrontMegaMenuMessages.pLeft1} />
            </p>
            <p>
              <ShText message={FrontMegaMenuMessages.pLeft2} />
            </p>
          </div>

          <div className="megamenu-about__text">
            <p>
              <ShText message={FrontMegaMenuMessages.pRight1} />
            </p>
            <p>
              <ShText message={FrontMegaMenuMessages.pRight2} />
            </p>
          </div>

          <p className="megamenu-about__link">
            <AppInternalLink to={AppInternalPaths.about}>
              <ShText message={FrontMegaMenuMessages.more} />
            </AppInternalLink>
          </p>

          <div
            css={{
              marginTop: '6rem',
              position: 'relative',
              width: '100%',
              height: 'calc((9 / 16) * 90vw)',
              maxHeight: 'calc(100vh - 169px)',
              minHeight: 'calc((9 / 16) * 90vw)',
              background: '#000',
              [ShMediaQueries.ShMobileOnly]: {
                marginTop: '1rem',
              },
            }}
          >
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=UewrRBg64D0`}
              width="100%"
              height="100%"
              config={{
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    controls: 0,
                    modestbranding: 1,
                    rel: 0,
                    color: ShColors.base,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Megamenu = ({ megaMenuOpened }: { megaMenuOpened: boolean }) => {
  return (
    <div
      className={classNames('megamenu', megaMenuOpened ? 'opened' : '')}
      id="megamenu"
    >
      {megaMenuOpened && <MegamenuContent />}
    </div>
  );
};

export default Megamenu;
