import { parse, stringify } from 'qs';
import { pickBy, isNil } from 'lodash';
import * as yup from 'yup';
import { DeepPartial } from 'utility-types';

export type SimpleQueryStringObject = Record<string, string>;

// Use this for simple querystring parsing
// You'd rather use the parseQueryStringAs instead, as providing a schema is safer
export const parseQueryString = (str: string) =>
  parse(
    str,
    // to obtain a simple key->value mapping
    { parseArrays: false, depth: 0 },
  ) as SimpleQueryStringObject;

// Use this method if your querystring includes nested objects/arrays
// You need to provide a yup schema
// Note: this method tries to avoid throwing (fail safe) as much as possible, but still can throw
export const parseQueryStringAs = <T extends object>(
  str: string,
  schema: yup.ObjectSchema<T>,
): T => {
  const queryStringParsed = parse(str);
  const queryStringCasted: T = schema.cast(queryStringParsed, {
    strict: false,
    abortEarly: false,
    stripUnknown: true,
  });
  __DEV__ && console.debug('queryStringCasted', queryStringCasted);
  return queryStringCasted;
};

export const stringifyQueryString = (obj: any): string => stringify(obj);

// For now we always remove null/undefined/"" from query strings
export const cleanQueryString = <T extends object>(object: T): Partial<T> =>
  pickBy(object, (v) => {
    // @ts-ignore
    return !isNil(v) && v !== '';
  });
