// Order matters for next/previous nav on usecasePage
import { PrestationName } from './appConstants';
import { ShLocale } from '@shoootin/config';

export const UsecaseNames = [
  'kretz',
  'convini',
  'belleval',
  'deskeo',
  'breathe',
  'transitionid',
  'bycharlot',
  'mom',
  'ilcottage',
  'tollbrothers',
] as const;

export const usecaseNamesOfLocale = (locale: ShLocale): UsecaseName[] => {
  return UsecaseNames.filter(
    (name) =>
      !UsecaseData[name].locales || UsecaseData[name].locales!.includes(locale),
  );
};

export type UsecaseName = typeof UsecaseNames[number];
// Some static data about usecases, that we need to access in many places
export type UsecaseConstantData = {
  label: string;
  services: PrestationName[];
  introUserName: string;
  locales?: ShLocale[]; // empty means all
};
export const UsecaseData: { [key in UsecaseName]: UsecaseConstantData } = {
  belleval: {
    label: 'Belleval',
    introUserName: 'Flore Jalenques',
    services: ['video', 'photo', 'matterport'],
  },
  breathe: {
    label: 'Breathe',
    introUserName: 'Richard Desanglois',
    services: ['video', 'photo'],
  },
  convini: {
    label: 'CONVINI',
    introUserName: 'Kyong-Soo',
    services: ['video', 'drone'],
  },
  deskeo: {
    label: 'Deskeo',
    introUserName: 'Nina Moritz',
    services: ['video', 'photo'],
  },
  kretz: {
    label: 'Kretz & Partners',
    introUserName: 'Olivier Kretz',
    services: ['video', 'photo', 'drone', 'matterport'],
    // locales: ['fr_FR', 'fr_BE'],
  },
  transitionid: {
    label: 'Transition Interior Design',
    introUserName: 'Carla Lopez',
    services: ['video', 'photo', 'matterport'],
    locales: ['fr_FR', 'fr_BE'],
  },
  bycharlot: {
    label: 'By Charlot',
    introUserName: 'Charles Senaux',
    services: ['video', 'photo'],
    locales: ['fr_FR', 'fr_BE'],
  },
  mom: {
    label: 'MÔM',
    introUserName: 'Augustin Erard',
    services: ['video', 'photo', 'matterport'],
    locales: ['fr_FR', 'fr_BE'],
  },
  ilcottage: {
    label: 'Il Cottage',
    introUserName: 'Johan Derderian',
    services: ['video', 'photo', 'matterport'],
    locales: ['fr_FR', 'fr_BE'],
  },
  tollbrothers: {
    label: 'Toll Brothers',
    introUserName: 'Michael Duff',
    services: ['video', 'photo', 'matterport'],
    locales: ['en_US'],
  },
};

export const ShoootinProjectManagers = ['Laura', 'Marie', 'Daniel', 'Claire'];

export type ShoootinProjectManager = typeof ShoootinProjectManagers[number];
