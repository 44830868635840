import React from 'react';
import { FrontContextProvider } from './frontContext';
import { AppContextProvider } from './appContext';

const RootLayout = (props: any) => (
  <AppContextProvider {...props}>
    <FrontContextProvider {...props} />
  </AppContextProvider>
);

export default RootLayout;
